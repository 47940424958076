import Loader from '../Loader'
import { PageCenter, VerticalPageCenter } from '../PageCenter'
import { PageLoadingProps } from './types'

const PageLoading = ({ height, loaderProps, isVertical }: PageLoadingProps) => {
  const Component = isVertical ? VerticalPageCenter : PageCenter
  return (
    <Component height={height}>
      <Loader {...loaderProps} />
    </Component>
  )
}

export default PageLoading
