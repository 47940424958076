import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ListContext } from '../../../../../context/ListContext'
import Loader from '../../../common/Loader'
import TableBodyCenter from '../../../common/TableBodyCenter'
import TableRowData from '../TableRowData'

const TableBody = () => {
  const {
    state: { loading, error, tableData, tableHeadList },
    handleAction
  } = useContext(ListContext)

  const { push: pushHistory } = useHistory()

  const handleRowClick = (path: string) => {
    pushHistory(path)
  }

  // * Show loading in the center of table
  if (loading) {
    return (
      <TableBodyCenter headList={tableHeadList}>
        <Loader />
      </TableBodyCenter>
    )
  }

  // * Show error in the center of table
  if (error) {
    return <TableBodyCenter headList={tableHeadList}>{error}</TableBodyCenter>
  }

  // * Show table body according to corresponding table head
  if (tableData?.length) {
    return (
      <>
        {tableData.map(item => (
          <tr key={item._id} onClick={() => handleRowClick(item.OnRowClick)}>
            {tableHeadList.map(tableHead => (
              <TableRowData
                key={tableHead}
                data={item[tableHead]}
                access={item.Access}
                handleClick={handleAction}
                tHead={tableHead}
              />
            ))}
          </tr>
        ))}
      </>
    )
  }

  return (
    <TableBodyCenter headList={tableHeadList}>
      No Data Available!
    </TableBodyCenter>
  )
}

export default TableBody
