import { createSelector } from 'reselect'
import { AnyObject } from '../../types/common'
import { AppState } from '../types'

export const selectFormModalUrls = createSelector(
  ({ formModal: { urls } }: AppState) => urls,
  (urls: AnyObject) => urls
)
export const selectFormModalSuccess = createSelector(
  ({ formModal: { isSuccess } }: AppState) => isSuccess,
  (isSuccess: AnyObject) => isSuccess
)
