import { MouseEvent } from 'react'
import { dateFormatter } from '../../../../../utils/format'
import TableIcon from '../TableIcons'
import { TableRowProps } from './types'

const TableRowData = ({ tHead, data, access, handleClick }: TableRowProps) => {
  if (Array.isArray(data)) {
    // * If Column has array of strings, render then one by one
    if (typeof data[0] === 'string') {
      return (
        <td>
          {data.map(item => (
            <div key={item}>{item}</div>
          ))}
        </td>
      )
    } else if (tHead === 'Action') {
      // * If Column head is Action, render icons with click functionality
      return (
        <td>
          {data.map(item => {
            if (item.Access?.includes(access)) {
              return (
                <span
                  key={item.Button}
                  onClick={(event: MouseEvent<HTMLSpanElement>) =>
                    handleClick(item, event)
                  }
                  className="cursor-pointer icon-button"
                >
                  <TableIcon name={item.Button} />
                </span>
              )
            }
            return null
          })}
        </td>
      )
    }
    return <td>{String(data)}</td>
  }
  if (tHead === 'Created') {
    // * Format date if it is a date field
    return <td>{dateFormatter(data)}</td>
  }
  if (tHead === 'InvoiceDate') {
    return <td>{dateFormatter(data, 'DD-MM-YYYY')}</td>
  }

  return <td>{data}</td>
}
export default TableRowData
