import moment from 'moment'

// * Eg: 2021-11-29T05:59:04+00:00 => 29-11-2021 11:29 AM
export const dateFormatter = (
  dateInString: string,
  outputFormat = 'DD-MM-YYYY HH:mm A'
) => {
  if (dateInString) {
    const date = new Date(dateInString)
    return moment(date).format(outputFormat)
  }
  return ''
}

export const base64Formatter = (format: string, data: string) =>
  `data:${format};base64,${data}`

export const dateInputFormatter = (defaultDate?: string) => {
  const newDate = new Date()
  let day = newDate.getDate()
  const month = newDate.getMonth()
  const year = newDate.getFullYear()
  if (defaultDate && defaultDate !== 'Today') {
    const [prefix, time] = defaultDate.split(' ')
    const [operator, ...numbers] = prefix.split('')
    const number = +numbers.join('')
    if (time === 'Days') {
      if (operator === '+') {
        day += number
      } else if (operator === '-') {
        day -= number
      }
    }
  }
  return new Date(year, month, day)
}

export const numberFormatter = (
  data: string | number,
  fractions: number = 2,
  format: string = 'en-IN'
) =>
  Number(data || '0').toLocaleString(format, {
    minimumFractionDigits: fractions,
    maximumFractionDigits: fractions
  })
