import Loader from '../../../common/Loader'
import DOMPurifier from '../../components/DOMPurifier'

import { useGetData } from '../../../../../hooks/reactQuery'

import { SmallElementProps } from './types'

const SmallElement = ({ content }: SmallElementProps) => {
  const path = content.Source
  const uniqueKey = path
  const apiParams = { path, body: {} }
  const {
    isLoading,
    isError,
    data: response
  } = useGetData(uniqueKey, apiParams)

  if (isLoading) {
    return (
      <div>
        <Loader size="sm" />
      </div>
    )
  }

  if (isError) {
    return <div>{path}</div>
  }

  if (response) {
    return (
      <DOMPurifier
        html={response.data?.[content.Key] || ''}
        as="p"
        className="text-end"
      />
    )
  }

  return null
}

export default SmallElement
