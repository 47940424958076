import { Col, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
import { useGetData } from '../../../../../hooks/reactQuery'
import { AnyObject } from '../../../../../types/common'
import { PageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'
import { PluginProps } from './types'

// * Plugin Components in dashboard page
const Plugin = ({ content }: PluginProps) => {
  const path = content.Source
  const {
    data: response,
    error,
    isLoading
  } = useGetData(path, { path, body: {} })

  if (isLoading) {
    return <PageLoading height="85vh" />
  }

  if (error) {
    return (
      <Card>
        <Card.Body>Unexpected error occured</Card.Body>
      </Card>
    )
  }

  if (response) {
    const data = response.data
    if (data) {
      return (
        <>
          {data.SubTitle && <h3 className="text-center">{data.SubTitle}</h3>}
          {data.Year && (
            <p className="text-center">Financial Year: {data.Year}</p>
          )}
          <Row className="mt-3 h-100">
            {data.Cards.map((card: AnyObject) => (
              <Col
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={card.Title}
                className="mb-3"
              >
                <Link to={card.Button}>
                  <Card className="h-100">
                    <Card.Body>
                      <h5>{card.Title}</h5>
                      <div>{card.Status}</div>
                      <div className="mt-2 text-end">
                        <h3>{card.Value}</h3>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </>
      )
    }

    return null
  }

  return (
    <Card>
      <Card.Body>
        <PageCenter height="20vh">Work In Progress</PageCenter>
      </Card.Body>
    </Card>
  )
}

export default Plugin
