import { useState } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import { Eye, EyeOff } from 'react-feather'

import { InputPasswordToggleProps } from './types'

const PasswordToggle = ({
  label,
  htmlFor,
  className,
  inputClassName,
  placeholder = '············',
  iconSize = 14,
  visible = false,
  ...restProps
}: InputPasswordToggleProps) => {
  const [isVisible, setIsVisible] = useState(visible)

  // * Render Label for Password
  const Label = () => {
    if (label) {
      return <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
    } else {
      return null
    }
  }

  // * Conditoionally render Icon for Password
  const RenderIcon = () => {
    const Icon = isVisible ? Eye : EyeOff
    return <Icon size={iconSize} />
  }

  if (label && htmlFor) {
    restProps.id = htmlFor
  }

  return (
    <>
      <Label />
      <InputGroup className={className}>
        {/* // * Update input type conditionally */}
        <FormControl
          type={isVisible ? 'text' : 'password'}
          placeholder={placeholder}
          className={inputClassName}
          autoComplete="current-password"
          {...restProps}
        />
        {/* // * Render icon Section */}
        <InputGroup.Text
          className="cursor-pointer"
          onClick={() => setIsVisible(!isVisible)}
        >
          <RenderIcon />
        </InputGroup.Text>
      </InputGroup>
    </>
  )
}

export default PasswordToggle
