import Checkbox from '../../forms/Checkbox'
import Input from '../../forms/Input'
import Multiple from '../../forms/Multiple'
import Select from '../../forms/Select'
import AutoComplete from '../../forms/Select/Autocomplete'
import TextArea from '../../forms/Textarea'
import AsyncSelect from '../../forms/Select/AsyncSelect'

import { FormItemProps } from './types'
import DatePicker from '../../forms/DatePicker'
import Editor from '../../forms/Editor'
import Percentage from '../../forms/Percentage'
import Tax from '../../forms/Tax'

const FormItem = (props: FormItemProps) => {
  switch (props.element.Type) {
    case 'async-select':
      return <AsyncSelect {...props} />
    case 'autocomplete':
      return <AutoComplete {...props} />
    case 'checkbox':
      return <Checkbox {...props} />
    case 'date':
      return <DatePicker {...props} />
    case 'editor':
      return <Editor {...props} />
    case 'hidden':
      return <Input {...props} style={{ display: 'none' }} />
    case 'measurements':
      props.element.Disabled = true
      return <Input {...props} />
    case 'multiple':
      return <Multiple {...props} />
    case 'password':
      return <Input type="password" {...props} />
    case 'percentage':
      return <Percentage {...props} />
    case 'roundOff':
      props.element.Disabled = true
      return <Input {...props} />
    case 'select':
      return <Select {...props} />
    case 'Tax':
      props.element.Disabled = true
      return <Tax {...props} />
    case 'text':
      return <Input {...props} />
    case 'textarea':
      return <TextArea {...props} />
    case 'total':
      props.element.Disabled = true
      return <Input {...props} />
    default:
      props.element.Disabled = true
      return <Input {...props} value="Work in progress" />
  }
}

export default FormItem
