// src/hooks/useForm.ts
import { SyntheticEvent, useCallback, useEffect, useReducer } from 'react'

import { useParams } from 'react-router-dom'

import {
  clearRowItemData,
  setButtonLoading,
  setFillRowItemData,
  setFormData,
  setFormDatum,
  setFormElementItemData,
  setSuccess
} from '../store/dynamic/actions'

import { useGetData, usePostData } from './reactQuery'
import { errorToast, saveToast } from '../utils/toast'

import { AnyObject, StringObject } from '../types/common'
import { FormElement, OnChangeValueType } from '../types/formElement'
import { IdParams } from '../types/params'
import { postData } from '../api'
import { dynamicReducer, initialState } from '../store/dynamic/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormModalUrls } from '../store/formModal/selector'
import { getParameterPercentFieldName } from '../utils/stringUtils'

const useForm = (
  path?: string,
  source?: string,
  sourceRequestBody?: AnyObject,
  requestBodyMap?: string[][],
  SourceResponseRemove?: string[],
  SourceResponseFill?: [string, string][],
  pathRequestDefualt?: AnyObject,
  sourceResponseChange?: AnyObject,
  bodyFill?: AnyObject
) => {
  const { id } = useParams<IdParams>()
  const [{ form }, dispatch] = useReducer(dynamicReducer, initialState)
  const formUrls = useSelector(selectFormModalUrls) || {}
  const reduxDispatch = useDispatch()
  const requestPath = path || formUrls?.path || ''
  const requestSource = source || formUrls?.source || ''
  const uniqueKey = `${source}-${id || ''}`

  const saveSuccess = useCallback((data: any) => {
    if (
      data.data?.Response === 'Duplicate Value' ||
      data.data?.Response === 'Error'
    ) {
      errorToast(
        { message: data.data?.Msg },
        `error-${requestPath}-${id || ''}`
      )
    } else {
      saveToast('', `save-${requestPath}-${id}`)
      dispatch(setSuccess(true))
      reduxDispatch(setSuccess(true))
    }
  }, [])

  useEffect(() => {
    if (bodyFill) {
      dispatch(setFormDatum({ Id: id }))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(setSuccess(false))
      reduxDispatch(setSuccess(false))
    },
    []
  )

  let body
  if (sourceRequestBody) {
    body = sourceRequestBody
  } else if (form.url?.requestBody) {
    body = form.url.requestBody
  } else if (id) {
    body = { Id: id }
  } else {
    body = {}
  }

  // * Get data for edit content
  const {
    data: sourceResponse,
    error,
    isLoading,
    refetch: fetchSource
  } = useGetData(
    uniqueKey,
    { path: requestSource || '', body },
    false,
    false,
    0,
    0
  )

  // * Save data
  const {
    data: mutatedData,
    isLoading: buttonLoading,
    error: saveError,
    mutate: saveData
  } = usePostData(saveSuccess)

  useEffect(() => {
    // * Call content for edit
    source && fetchSource()
    return () => {
      dispatch(setFormData({}))
    }
  }, [source])

  useEffect(() => {
    const onChangeSourceRes = async (
      path: string,
      body: AnyObject,
      fillData: AnyObject
    ) => {
      const res = await postData({ path, body })
      const sourceFillSelectOptions =
        sourceResponseChange?.ResponseFillSelectOption
      if (sourceFillSelectOptions) {
        const selectName: string = Object.keys(sourceFillSelectOptions)[0]
        const values: any = Object.values(sourceFillSelectOptions)[0]
        dispatch(
          setFormElementItemData({
            name: selectName,
            data: {
              Options: res?.data[values[0]]?.[values[1]],
              Disabled: false,
              UpdateOptions: true
            }
          })
        )
      }
    }
    if (sourceResponse) {
      const fillData: AnyObject = { ...sourceResponse?.data } || {}
      SourceResponseRemove?.forEach(key => {
        delete fillData[key]
      })
      SourceResponseFill?.forEach(([key, value]) => {
        fillData[key] = fillData[value]
      })
      if (sourceResponseChange?.Source) {
        const reqBody: AnyObject = {}
        sourceResponseChange?.SourceRequestBody?.forEach(
          ([key, value]: any[]) => {
            if (typeof key === 'string') {
              reqBody[value] = fillData[key]
            } else if (Array.isArray(key)) {
              let data: any = { ...fillData }
              key.forEach((keyItem: string | number) => {
                data = data[keyItem]
              })
              reqBody[value] = data
            }
          }
        )
        onChangeSourceRes(sourceResponseChange?.Source, reqBody, fillData)
      }

      const formatDataMap = Object.entries(fillData).map(([key, value]) => {
        if (value && form.elements[key]?.Class === 'json') {
          return [key, JSON.stringify(value, null, 2)]
        }
        return [key, value]
      })
      const formatData = Object.fromEntries(formatDataMap)
      // * Add edit data to store and fill the data
      dispatch(setFormData({ ...formatData }))
    }
  }, [sourceResponse])

  useEffect(() => {
    saveError && errorToast(saveError, `error-${requestPath}-${id}`)
  }, [saveError])

  useEffect(() => {
    dispatch(setButtonLoading(buttonLoading))
    reduxDispatch(setButtonLoading(buttonLoading))
  }, [buttonLoading])

  if (error) {
    errorToast(error, uniqueKey)
  }

  const handleOnChange = async (
    value: OnChangeValueType,
    element: FormElement,
    data?: any,
    formDataFromComponent?: any
  ) => {
    const formData: AnyObject = {}
    const formElementData: AnyObject = {}
    let formValue = value
    if (element.IsUpperCase && typeof value === 'string') {
      formValue = value.toUpperCase()
    }
    if (element.MaxLength !== undefined && typeof value === 'string') {
      if (value.length <= element.MaxLength) {
        formData[element.Name] = formValue
      }
    } else if (Array.isArray(value) && data?.isMultiselect) {
      if (value[0]?.[0]?.length) {
        formData[element.Name] = value[0][0]
      } else if (element.OnClear) {
        element.OnClear.data.forEach((datum: string) => {
          formData[datum] = ''
        })
        element.OnClear.rowData &&
          dispatch(clearRowItemData({ rowData: element.OnClear.rowData }))
      }
      if (data?.selectedData?.onChangeFill) {
        const { values, fillItems } = data.selectedData.onChangeFill
        fillItems?.forEach((fillItem: string) => {
          formData[fillItem] = `${values[0][fillItem]}`
        })
      }
      if (data?.selectedData?.onChangeSum) {
        const { values, fillItems } = data.selectedData.onChangeSum
        fillItems.forEach(([key, value]: any) => {
          const sum = values.reduce(
            (acc: number, val: any) => (acc += val[value] ? +val[value] : 0),
            0
          )
          formData[key] = sum
        })
      }
    } else {
      formData[element.Name] = value
    }

    if (data?.type === 'percentage') {
      formData[getParameterPercentFieldName(element.Name)] =
        data.percentage ||
        formData[getParameterPercentFieldName(element.Name)] ||
        '0'
    }

    // * If onChange is present in element
    if (element.OnChange) {
      const {
        Source: onChangeSource,
        Name: onChangeField,
        Response: onChangeResponseType,
        SourceRequestBody: onChangeSourceRequestBody,
        ResponseFill: onChangeResponseFill,
        ResponseFillMultipleForm: onChangeResponseRowFill,
        ResponseFillSelectOption: fillSelectOptions,
        Calculate: calculate
      } = element.OnChange
      // * If API call needed to get data on change
      if (onChangeSource && (!element.IsMulti || formData[element.Name])) {
        try {
          const sourceBody: AnyObject = {}
          // * If requestbody for source is present
          if (onChangeSourceRequestBody) {
            onChangeSourceRequestBody?.forEach(
              ([key, sourceRequestBodyValue, from]: string[]) => {
                if (key === 'this') {
                  sourceBody[sourceRequestBodyValue] = value
                } else {
                  sourceBody[sourceRequestBodyValue] =
                    from === 'formData'
                      ? formDataFromComponent?.data?.[key]
                      : key
                }
              }
            )
          } else {
            sourceBody[element.Name.toLowerCase()] = value
          }
          const onChangeResponse = await postData({
            path: onChangeSource,
            body: sourceBody
          })
          if (fillSelectOptions) {
            const selectName = Object.keys(fillSelectOptions)?.[0]
            const values = Object.values(fillSelectOptions)?.[0]
            dispatch(
              setFormElementItemData({
                name: selectName,
                data: {
                  Options: onChangeResponse?.data[values[0]]?.[values[1]],
                  Disabled: false,
                  UpdateOptions: true
                }
              })
            )
          }
          // * If multiple values to be filled in formData
          if (onChangeResponseFill?.length) {
            const onChangeResponseObject: AnyObject = onChangeResponse?.data
            onChangeResponseFill.forEach(item => {
              Object.entries(item).forEach(
                ([fillKey, [fillValueOne, fillValueTwo]]) => {
                  if (fillValueTwo) {
                    formData[fillKey] =
                      onChangeResponseObject?.[fillValueOne]?.[fillValueTwo]
                  } else if (fillValueOne) {
                    formData[fillKey] = onChangeResponseObject?.[fillValueOne]
                  }
                }
              )
            })
          }
          if (onChangeResponseRowFill?.length) {
            const onChangeResponseObject: AnyObject = onChangeResponse?.data
            dispatch(
              setFillRowItemData({
                response: onChangeResponseObject,
                onChangeResponseRowFill
              })
            )
          }
          if (onChangeResponseType === 'value') {
            // * If onChange response type is value, then set value directly
            const restFormData: AnyObject = onChangeResponse.data
            const resValue = restFormData[onChangeField]
            formData[onChangeField] = resValue
          } else if (
            onChangeResponseType === 'Options' &&
            onChangeResponse?.data?.length
          ) {
            // * If onChange response type is options, then set options for that element
            const onChangeOptions = onChangeResponse.data.map(
              (item: AnyObject) => ({
                label: item.Name,
                value: item._id
              })
            )
            formElementData.name = [onChangeField]
            // * False if object, true if array
            formElementData.type = [false]
            formElementData.data = {
              Options: onChangeOptions,
              Disabled: false
            }
          }
        } catch (err) {
          errorToast(
            { message: 'Error fetching data' },
            `error-onChange-${onChangeSource}-${onChangeField}`
          )
        }
      }
      if (onChangeResponseType === 'Enable') {
        formElementData.name = [onChangeField]
        formElementData.type = [false]
        formElementData.data = {
          Disabled: !value
        }
        if (!value) {
          formData[onChangeField] = ''
        }
      }
      if (calculate) {
        let calculatedAmount = 0
        calculate.forEach((calculateItem: any) => {
          calculatedAmount = calculateItem.BaseValues?.reduce(
            (acc: number, ElValue: string, index: number) => {
              let formValue = form.data[ElValue] || 0
              if (ElValue === 'this') {
                formValue = value || 0
              }
              if (calculateItem.Operators?.[index] === 'add') {
                acc += +formValue
              } else if (calculateItem.Operators?.[index] === 'subtract') {
                acc -= +formValue
              }
              return acc
            },
            0
          )
          if (calculateItem.ResultFill) {
            if (form.elements[calculateItem.ResultFill]?.OnChange) {
              handleOnChange(
                calculatedAmount.toFixed(2),
                form.elements[calculateItem.ResultFill]
              )
            } else {
              formData[calculateItem.ResultFill] = calculatedAmount.toFixed(2)
            }
          }
        })
      }
    }
    if (data?.fill) {
      const fillObject: StringObject = data.fill
      Object.entries(fillObject).forEach(([key, fillValue]) => {
        if (fillValue) {
          formData[key] = fillValue
        }
      })
    }
    if (data?.onChangeFill) {
      const { values, fillItems } = data?.onChangeFill
      fillItems?.forEach((fillItem: string) => {
        formData[fillItem] = values[fillItem] || values.value
      })
    }
    dispatch(setFormDatum(formData))
    dispatch(setFormElementItemData(formElementData))
  }

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    let pathBody: AnyObject = {}
    if (requestPath && pathRequestDefualt) {
      saveData({ path: requestPath, body: pathRequestDefualt })
    } else if (requestPath && form.data) {
      if (requestBodyMap?.length) {
        requestBodyMap.forEach(([key, value]) => {
          if (key === 'Id' && !form.data?.id) {
            pathBody[key] = id
          } else {
            pathBody[key] = form.data[value]
          }
        })
      } else {
        pathBody = form.data
      }
      saveData({ path: requestPath, body: pathBody })
    }
  }

  return {
    buttonLoading,
    error,
    isLoading,
    handleOnChange,
    handleSubmit,
    sourceResponse,
    saveSuccess,
    form,
    dispatch,
    mutatedData
  }
}

export default useForm
