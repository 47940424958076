import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'

import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import PasswordToggle from '../../../../components/common/PasswordToggle'
import RippleButton from '../../../../components/common/RippleButton'

import { setRoutes } from '../../../../../store/global/actions'

import {
  ForgotPasswordWrapper,
  FormFooter,
  FormText
} from '../../../../styles/common'

import { usePostData } from '../../../../../hooks/reactQuery'

import { emailValidator } from '../../../../../utils/validators'
import { errorToast } from '../../../../../utils/toast'
import { setData, setStorageType } from '../../../../../utils/storage'

import { setRoutePaths } from '../../../../../Router/routes'

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidPassword, setIsValidPassword] = useState(true)
  const [rememberMe, setRememberMe] = useState(false)

  const {
    data: loginResponse,
    error,
    isLoading,
    isError,
    mutate
  } = usePostData()

  const dispatch = useDispatch()

  useEffect(() => {
    /*
     * Update web storage and redux store
     * Written inside useEffect to prevent update multiple component at same time error
     */
    if (loginResponse) {
      const data = loginResponse.data
      const { Response: response, Msg: message } = data
      if (response === 'Error') {
        errorToast({ message }, 'loginError')
      } else {
        const {
          AccessToken: accessToken,
          RefreshToken: refreshToken,
          Data: {
            BusinessData: {
              Legal_Name: legalName = 'Brand Name',
              Package: subscription = 'Trial'
            },
            User_data: {
              Name: userName = 'User',
              DashboardPath: dashboardPath
            },
            sidebar_data: { data: sidebarData }
          }
        } = data

        const headerData = { legalName, subscription, userName }
        // * Set response to storage
        setStorageType(rememberMe ? 'localStorage' : 'sessionStorage')
        setData('refreshToken', refreshToken)
        setData('accessToken', accessToken)
        setData('headerData', JSON.stringify(headerData))
        setData('sidebarData', JSON.stringify(sidebarData))
        setData('dashboardPath', JSON.stringify({ dashboardPath }))
        // * Set response to redux store
        dispatch(setRoutes(setRoutePaths(sidebarData)))
      }
    }
  }, [loginResponse])

  useEffect(() => {
    if (isError) {
      errorToast(error, 'loginError')
    }
  }, [isError])

  const validateEmail = () => {
    const isEmailValid = emailValidator(email)
    setIsValidEmail(isEmailValid)
    return isEmailValid
  }

  const validatePassword = () => {
    const isPasswordValid = !!password
    setIsValidPassword(isPasswordValid)
    return isPasswordValid
  }

  const handleEmailChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value)
  }

  const handlePasswordChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPassword(target.value)
  }

  const handleLogin = async (event: SyntheticEvent) => {
    event.preventDefault()
    if (validateEmail() && validatePassword()) {
      const body = { email, password, remember: rememberMe }
      const path = 'login/'
      mutate({ path, body })
    } else {
      errorToast(
        { message: 'Please fill the required fields correctly' },
        'required'
      )
    }
  }

  return (
    <Form onSubmit={handleLogin}>
      {/* // * Email Section */}
      <Form.Group controlId="login-email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email"
          autoFocus
          autoComplete="email"
          value={email}
          onChange={handleEmailChange}
          onBlur={validateEmail}
        />
        <FormText isInvalid={!isValidEmail}>
          Please enter a valid email!
        </FormText>
      </Form.Group>

      {/* // * Password Section */}
      <Form.Group>
        <PasswordToggle
          label="Password"
          htmlFor="login-password"
          value={password}
          onChange={handlePasswordChange}
          onBlur={validatePassword}
        />
        <FormText isInvalid={!isValidPassword}>
          Password should not be blank!
        </FormText>
      </Form.Group>

      {/* // * Forgot Password Section */}
      <ForgotPasswordWrapper>
        <Link to="/forgot-password">
          <small>Forgot Password?</small>
        </Link>
      </ForgotPasswordWrapper>

      {/* // * Remember Me Section */}
      <Form.Group controlId="login-remember-me" className="mb-3">
        <Form.Check
          className="custom-control custom-checkbox"
          type="checkbox"
          label="Remember Me"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
        />
      </Form.Group>

      {/* // * Submit Button Section */}
      <div className="d-grid mb-3">
        <RippleButton
          type="submit"
          variant="primary"
          className="mb-3"
          isLoading={isLoading}
        >
          Sign In
        </RippleButton>
      </div>
      <FormFooter>
        <small>
          Made by&nbsp;
          <a href="https://klivolks.com/" target="_blank" rel="noreferrer">
            Klivolks® Pvt Ltd
          </a>
        </small>
      </FormFooter>
    </Form>
  )
}

export default LoginForm
