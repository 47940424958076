import { useMutation, useQuery } from 'react-query'
import { postData } from '../api'
import { APIParams } from '../types/api'
import { AnyObject } from '../types/common'

// * Custom Hook over React Query for listing/displaying data from BE
export const useGetData = (
  id: string,
  requestData: APIParams,
  enabled = true,
  refetchOnWindowFocus = false,
  staleTime = 0,
  cacheTime = 5 * 60 * 1000, // (milliseconds)
  refetchInterval = 0
) =>
  useQuery(id, () => postData(requestData), {
    refetchOnWindowFocus,
    enabled,
    retry: false,
    staleTime,
    cacheTime,
    refetchInterval
  })

// * Custom Hook over React Query for creating/editing/deleting data to BE
export const usePostData = (
  onSuccess?: (data: any) => void,
  onError?: () => void
) => {
  const callbacks: AnyObject = {}
  if (onSuccess) {
    callbacks.onSuccess = onSuccess
  }
  if (onError) {
    callbacks.onError = onError
  }
  return useMutation(postData, callbacks)
}
