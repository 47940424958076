// src/store/rootReducer.ts
import { combineReducers } from 'redux'

import { AppState, RootAction } from './types'

import { globalReducer } from './global/reducer'
import { LOGOUT_USER } from './actionTypes'
import { formModalReducer } from './formModal/reducer'
import { viewDetailsReducer } from './viewDetails/reducer'
import { printReducer } from './print/reducer'

export const appReducer: any = combineReducers({
  global: globalReducer,
  formModal: formModalReducer,
  viewDetails: viewDetailsReducer,
  print: printReducer
})

const rootReducer = (state: AppState, action: RootAction) => {
  // * Clear redux store during logout
  if (action.type === LOGOUT_USER) {
    return appReducer({}, action)
  }
  // * Go to other reducers
  return appReducer(state, action)
}

export default rootReducer
