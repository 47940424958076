import { PDFDownloadLink } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPrint } from '../../store/print/selector'
import { AnyObject } from '../../types/common'
import { errorToast } from '../../utils/toast'
import RippleButton from '../components/common/RippleButton'
import { ArrowDownCircle } from 'react-feather'

// import PageLoading from '../shared/components/PageLoading'
// import { PDFViewerProps } from './model'
import NewPDF from './NewPDF'
// import PDFDocument from './PDFDocument'

// Font.register({
//   family: 'Weisch',
//   fonts: [
//     { src: `${baseFontPath}weisch/webswfte.ttf` },
//     { src: `${baseFontPath}weisch/Weissach.ttf`, fontWeight: 700 }
//   ]
// })

// Create Document Component
const PDFLink = ({
  buttonText,
  className,
  otherData,
  fileNameSuffix,
  isDownloadButton = false
}: any) => {
  const print = useSelector(selectPrint)
  const [layout, setLayout] = useState<AnyObject>(print?.layout || {})
  const [description, setDescription] = useState<AnyObject | null>(null)
  const [particulars, setParticulars] = useState<AnyObject | null>(null)
  const code = print?.fileName?.code || ''
  // const prefix = print?.fileName?.prefix || ''
  const data = print?.data

  useEffect(() => {
    const particularElements = print.layout?.Layout?.Particulars?.Elements
    const currentParticulars = JSON.parse(
      JSON.stringify(particularElements || [])
    )
    if (
      (print.layout?.Layout?.Particulars?.showDescriptionRow ||
        (!layout?.Layout?.NoCategoryCheck && data.Category === 'Media')) &&
      particularElements
    ) {
      particularElements.forEach((element: any, index: number) => {
        if (element.Label === 'Description') {
          setDescription(currentParticulars.splice(index, 1)[0])
        }
      })
    } else {
      setDescription(null)
    }
    setParticulars(currentParticulars)
    // if (print.particulars) {
    //   print.particulars = print.particulars.map((particular: any) => [
    //     particular
    //   ])
    // }
    print.layout && setLayout(print.layout)
  }, [print])

  const saveDocument = (() => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    return (blob: any, fileName: string) => {
      a.href = blob
      a.download = fileName
      isDownloadButton && a.click()
      window.URL.revokeObjectURL(blob)
    }
  })()

  const setPrintLayout = (layoutData: AnyObject) => {
    setLayout({ ...layoutData })
  }

  const handleClick = (
    event: any,
    url: string,
    fileName: string,
    blob: any
  ) => {
    event.preventDefault()
    const blobUrl = URL.createObjectURL(blob)
    saveDocument(blobUrl, fileName)
    !isDownloadButton && window.open(url, '_blank')
  }
  if (print?.layout?.Layout?.Orientation && data[code]) {
    const fileName = `${data[code] || data.Title || ''}${
      fileNameSuffix ? `-${fileNameSuffix}` : ''
    }.pdf`
    return (
      <PDFDownloadLink
        document={
          <NewPDF
            layout={layout}
            logo={print.logo}
            bwLogo={print.bwLogo}
            businessInfo={print.businessInfo}
            images={print.printImages}
            data={data}
            particulars={print.particulars}
            particularElements={particulars}
            description={description}
            buttonText={buttonText}
            subTotal={print.subTotal}
            otherData={otherData}
            setPrintLayout={setPrintLayout}
            rowTitles={print.rowTitles}
          />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) => {
          if (loading) {
            return (
              <RippleButton
                isLoading={true}
                className={className}
                variant={isDownloadButton ? 'outline-primary' : 'primary'}
              >
                {isDownloadButton ? <ArrowDownCircle /> : buttonText}
              </RippleButton>
            )
          }
          if (error) {
            errorToast('Error loading PDF', `error-${print}`)
            return null
          }
          if (url) {
            return (
              <RippleButton
                className={className}
                variant={isDownloadButton ? 'outline-primary' : 'primary'}
                onClick={(event: any) =>
                  handleClick(event, url, fileName, blob)
                }
              >
                {isDownloadButton ? <ArrowDownCircle /> : buttonText}
              </RippleButton>
            )
          }
        }}
      </PDFDownloadLink>
    )
  }

  return (
    <RippleButton isLoading={true} className={className}>
      {isDownloadButton ? <ArrowDownCircle /> : buttonText}
    </RippleButton>
  )
}

export default PDFLink
