import { useEffect } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
import { Power, User } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import Toggle from './Toggle'

import { defaultFallbackPath } from '../../../../../constants/routes'
import { logout } from '../../../../../api'
import { errorToast } from '../../../../../utils/toast'

import { selectRoutes } from '../../../../../store/global/selector'
import { setAppLoading } from '../../../../../store/global/actions'

const UserDropdown = () => {
  const { push: pushHistory } = useHistory()
  const dispatch = useDispatch()
  const routes = useSelector(selectRoutes)

  useEffect(() => {
    if (!routes.dynamicRoutes.length) {
      pushHistory(defaultFallbackPath)
    }
  }, [routes])

  const logoutFromApp = async () => {
    try {
      dispatch(setAppLoading(true))
      await logout()
    } catch (err: any) {
      errorToast(err, 'logoutError')
    } finally {
      dispatch(setAppLoading(false))
    }
  }

  return (
    <Dropdown>
      {/* // * Dropdown toggle section (Dropdown button) */}
      <Dropdown.Toggle as={Toggle} />

      {/* // * Dropdown Box with items */}
      <Dropdown.Menu>
        <Dropdown.Item eventKey="profile" className="d-flex">
          <User size={14} className="me-2" />
          <Link to="/reset-password">Reset Password</Link>
        </Dropdown.Item>
        <Dropdown.Item onClick={logoutFromApp} className="d-flex">
          <Power size={14} className="me-2" />
          <span>Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserDropdown
