import Col from 'react-bootstrap/Col'

import RichTextEditor from '../../components/Editor'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'

import { responsiveCol } from '../../../../../utils/conversions'

import { FormItemProps } from '../../layout/FormItem/types'

const Editor = ({
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const handleInputChange = (html: string) => {
    onChange && onChange(html)
  }

  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  return (
    <FormWrapper>
      {element.Label && <Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        <RichTextEditor
          key={element.Key}
          value={value || ''}
          onChange={handleInputChange}
          placeholder={element.Placeholder || ''}
        />
      </Col>
    </FormWrapper>
  )
}

export default Editor
