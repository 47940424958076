import { LogoImage, LogoName } from './styles'

import logo from '../../../../assets/images/logo/klivolks.png'

const Logo = () => (
  <div className="d-flex align-items-center">
    {/* // * Klivolks Logo */}
    <LogoImage src={logo} alt="klivolks" />
    {/* // * Application Name */}
    <LogoName>Icorp</LogoName>
  </div>
)

export default Logo
