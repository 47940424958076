// src/views/components/dynamic/items/Timeline/index.tsx
import { useCallback, useEffect, useState } from 'react'

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { PageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'
import RippleButton from '../../../common/RippleButton'
import CardTitle from '../../../common/Title/CardTitle'
import TimelineModal from './Modal'

import { useGetData } from '../../../../../hooks/reactQuery'
import { dateFormatter } from '../../../../../utils/format'
import { errorToast, saveToast } from '../../../../../utils/toast'

import { postData } from '../../../../../api'

import {
  SelectedItem,
  TimelineAction,
  TimelineButton,
  TimelineListItem,
  TimelineProps
} from './types'
import { IdParams } from '../../../../../types/params'

import { TimelineItem, TimelinePoint } from './styles'
import DOMPurifier from '../../components/DOMPurifier'
import { selectMainData } from '../../../../../store/viewDetails/selector'
import { setRefetch } from '../../../../../store/viewDetails/actions'
import { useThrottle } from '../../../../../hooks/useThrottle'

const Timeline = ({ content }: TimelineProps) => {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(null)
  const [isButtonLoading, seIsButtonLoading] = useState(false)
  const [buttonValue, setButtonValue] = useState<TimelineButton>()
  const throttledClick = useThrottle(buttonValue?.Action, 1000)
  const { id } = useParams<IdParams>()
  const mainData = useSelector(selectMainData)
  // const refetchMainData = useSelector(selectRefetchMethod)
  const dispatch = useDispatch()
  const { push: pushHistory } = useHistory()
  const path = content.Path
  const uniqueKey = `${content.Path}-${id}`
  const body = { Id: id }
  const { isLoading, data, error, refetch } = useGetData(
    uniqueKey,
    { path, body },
    true,
    true
  )
  const handleAction = useCallback(
    async (url: string, action: TimelineAction) => {
      try {
        seIsButtonLoading(true)
        const actionBody = {
          Id: id,
          Action: action
        }
        await postData({ path: url, body: actionBody })
        saveToast('Timeline Action', `timeline-${id}-${url}`)
        // * Refetch Job data if save is successful
        refetch()
        dispatch(setRefetch(true))
      } catch (err: any) {
        errorToast(err, `timeline-${id}-${url}`)
      } finally {
        seIsButtonLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    if (
      buttonValue?.Action === 'SendForApproval' ||
      buttonValue?.Action === 'ChangeDepartment' ||
      buttonValue?.Source
    ) {
      // * show modal with selectedItem
      setSelectedItem({ ...buttonValue, Data: mainData?.data })
    } else if (
      buttonValue?.Action === 'Approve' ||
      buttonValue?.Action === 'Reject' ||
      buttonValue?.Action === 'ArchiveJob'
    ) {
      // * Save data
      // if (buttonValue.Source) {
      //   const path = buttonValue.Source.replace(
      //     '{submodule}',
      //     mainData?.data?.Product || ''
      //   )
      //   // const sourceResponse = await postData({ path, body: { Id: id } })
      //   // setSelectedItem({ Name: '', options: sourceResponse.data })
      // } else {
      handleAction(buttonValue?.Path || '', buttonValue?.Action)
      // }
    } else if (buttonValue?.Action.includes('Create')) {
      // * Redirect
      const url =
        buttonValue?.Path?.replace(
          '{submodule}',
          mainData?.data?.Product || ''
        ) || ''

      pushHistory(`${url}?jobCode=${mainData?.data?.JobCode || ''}`)
    }
  }, [throttledClick])

  const timelineData: TimelineListItem[] = data?.data || []

  // * Closes modal and remove selectedItem from state
  const unSelectItem = (isSuccess = false) => {
    setSelectedItem(null)
    // * Refetch Job data if save from modal is successful
    isSuccess && refetch()
  }

  if (isLoading) {
    return (
      <Card>
        <CardTitle title={content.Title} />
        <PageLoading height="20vh" />
      </Card>
    )
  }

  if (error || !timelineData?.length) {
    return (
      <Card>
        <CardTitle title={content.Title} />
        <PageCenter height="10vh">No data found!</PageCenter>
      </Card>
    )
  }
  return (
    <Card>
      <CardTitle title={content.Title} />
      <ul className="list-style-type-none">
        {timelineData.map((item, index) => (
          <TimelineItem key={`${item.Type}${index}`}>
            <TimelinePoint
              variant={
                item.Type === 'History'
                  ? 'success'
                  : item.Type === 'Closed'
                  ? 'danger'
                  : 'warning'
              }
            />
            <Row>
              <Col xs={12} md={9}>
                {/* // * Timline Title */}
                <h6 style={{ color: '#555' }}>
                  <strong>{item.Text}</strong>
                </h6>
                {/* // * Timline Description */}
                {item.Content && <DOMPurifier html={item.Content} />}
                {/* // * Timline Buttons */}
                {item.Buttons?.map(button => (
                  <RippleButton
                    key={button.Text}
                    onClick={() => setButtonValue(button)}
                    className="me-3 mb-1"
                    isLoading={isButtonLoading || !!selectedItem}
                    // *TODO update variant
                    variant={button.Class?.split('btn-')?.[1]}
                    disabled={isButtonLoading || !!selectedItem}
                  >
                    {button.Text}
                  </RippleButton>
                ))}
              </Col>
              <Col xs={12} md={3}>
                {/* // * Timline date */}
                {item.Created && (
                  <div className="text-end">{dateFormatter(item.Created)}</div>
                )}
              </Col>
            </Row>
          </TimelineItem>
        ))}
      </ul>
      <Modal
        centered
        show={selectedItem}
        onHide={unSelectItem}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem?.Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* // * Modal Body Section */}
          <TimelineModal selectedItem={selectedItem} hideModal={unSelectItem} />
        </Modal.Body>
      </Modal>
    </Card>
  )
}

export default Timeline
