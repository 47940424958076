// src/views/components/dynamic/items/Data/index.tsx
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useLocation, useParams } from 'react-router-dom'

import CardTitle from '../../../common/Title/CardTitle'
import { PageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'

import { useGetData } from '../../../../../hooks/reactQuery'

import { DataProps } from './types'
import { IdParams } from '../../../../../types/params'
import { AnyObject } from '../../../../../types/common'
import { errorMessageFromError } from '../../../../../utils/dynamicData'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  setMainData,
  setRefetch
} from '../../../../../store/viewDetails/actions'
import { selectRefetch } from '../../../../../store/viewDetails/selector'

// * View Page - Main Data
const Data = ({ content }: DataProps) => {
  const { id } = useParams<IdParams>()
  const { pathname } = useLocation()
  const uniqueKey = `${content.Path}-${id}`
  const body = { path: content.Path, body: { Id: id } }
  const {
    data: responseData,
    isLoading,
    error,
    refetch: refetchViewDetails
  } = useGetData(uniqueKey, body, true, true)
  const refetchData = useSelector(selectRefetch)
  const dispatch = useDispatch()

  useEffect(() => {
    refetchData && refetchViewDetails() && dispatch(setRefetch(false))
  }, [refetchData])

  useEffect(() => {
    responseData && dispatch(setMainData({ pathname, data: responseData.data }))
  }, [responseData])

  if (isLoading) {
    return (
      <Card>
        <PageLoading height="30vh" />
      </Card>
    )
  }

  if (error) {
    const errorMessage = errorMessageFromError(error as AnyObject)
    return (
      <Card>
        <PageCenter height="10vh">{errorMessage}</PageCenter>
      </Card>
    )
  }

  if (responseData) {
    const data = responseData.data
    return (
      <Card>
        <CardTitle title={content.Title} />
        {/* // * Show each data in row */}
        {content.Elements.map(
          element =>
            element.Type === 'p' && (
              <Row key={element.Key}>
                <Col xs={12} md={content.Split?.[0] || 12}>
                  <p>{element.Label}:</p>
                </Col>
                <Col xs={12} md={content.Split?.[1] || 12}>
                  <p>{(data as AnyObject)?.[element.Key]}</p>
                </Col>
              </Row>
            )
        )}
      </Card>
    )
  }

  return null
}

export default Data
