import SidebarItem from '../SidebarItem'
import { SidebarItemsProp } from './types'

const SidebarNav = ({ items }: SidebarItemsProp) => {
  const { Main: main, Sub: sub } = items
  return (
    <>
      {main?.map(menuItem => {
        const key = sub?.[menuItem] ? `${menuItem}-${sub[menuItem]}` : menuItem
        // * Render main items
        return (
          <SidebarItem
            key={key}
            text={menuItem}
            items={sub?.[menuItem]}
            link={sub?.[menuItem]?.length ? '#' : `/${menuItem}`}
          />
        )
      })}
    </>
  )
}

export default SidebarNav
