import { Reducer } from 'react'
import {
  COUNT,
  CURRENT_PAGE,
  ERROR,
  LOADING,
  SEARCH,
  TABLE_DATA,
  TABLE_HEAD_LIST
} from './actionTypes'
import { ListAction, ListState } from './types'

const queryParams = new URLSearchParams(window.location.search)
const page = queryParams.get('page')
const search = queryParams.get('search') || ''
export const initialState: ListState = {
  count: 0,
  currentPage: page ? +page : 1,
  error: '',
  loading: false,
  search,
  tableData: [],
  tableHeadList: [],
  tableRowItemProps: null
}

export const listReducer: Reducer<ListState, ListAction> = (
  state: ListState,
  action: ListAction
) => {
  switch (action.type) {
    case COUNT:
      return { ...state, count: action.payload }
    case CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case ERROR:
      return { ...state, error: action.payload }
    case LOADING:
      return { ...state, loading: action.payload }
    case SEARCH:
      return { ...state, search: action.payload }
    case TABLE_DATA:
      return { ...state, tableData: action.payload }
    case TABLE_HEAD_LIST:
      return { ...state, tableHeadList: action.payload }
    default:
      return state
  }
}
