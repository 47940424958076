import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { DOMPurifierProps } from './types'

const DOMPurifier = ({
  html,
  as: Component = 'div',
  className = ''
}: DOMPurifierProps) => (
  <Component
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    className={classNames(className, 'dom-purifier')}
  />
)

export default DOMPurifier
