import styled from 'styled-components'
import { device } from '../../../styles/styled'
import { zIndexMap } from '../../../styles/styled/zIndexMap'
import Sidebar from '../../components/layout/Sidebar'

export const Page = styled.main<{ showSidebar: boolean }>`
  padding: 7.75rem 2rem 0;
  height: 100vh;
  @media ${device.tablet} {
    margin-left: ${({ showSidebar }) => (showSidebar ? '18.5714rem;' : '0')};
  }
`
export const StyledSidebar = styled(Sidebar)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`

export const NavbarShadow = styled.div`
  z-index: ${zIndexMap.mainHeaderShadow};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );
  padding-top: 2.2rem;
`
