import classNames from 'classnames'
import Title from '.'
import { CardTitleProps } from './types'

// * Component to render Titles in Cards
const CardTitle = ({ title, className }: CardTitleProps) => (
  <Title className={classNames(className, 'fs-5', 'mt-2')}>{title}</Title>
)

export default CardTitle
