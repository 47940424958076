export const size = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

export const device = {
  mobileLg: `(min-width: ${size.sm}px)`,
  tablet: `(min-width: ${size.md}px)`,
  laptop: `(min-width: ${size.lg}px)`,
  desktopSm: `(min-width: ${size.xl}px)`,
  desktopLg: `(min-width: ${size.xxl}px)`
}
