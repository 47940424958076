import Offcanvas from 'react-bootstrap/Offcanvas'
import { Menu } from 'react-feather'
import styled from 'styled-components'
import { device } from '../../../../styles/styled'
import { zIndexMap } from '../../../../styles/styled/zIndexMap'

export const NavbarContainer = styled.nav<{ showSidebar: boolean }>`
  position: fixed;
  width: calc(100% - 4rem);
  z-index: ${zIndexMap.mainHeader};
  margin: 1.3rem 2rem 0;
  border-radius: 0.428rem;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  right: 0;
  @media ${device.tablet} {
    width: ${({ showSidebar }) =>
      showSidebar ? 'calc(100% - 4rem - 260px);' : 'calc(100% - 4rem);'};
  }
`
export const NavbarContent = styled.div`
  min-height: calc(100% - 3.35rem);
  transition: all 0.3s ease;
  padding: 0.8rem 1rem;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  align-items: center;
  flex-wrap: wrap;
`
export const NavbarItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
`
export const NavMenu = styled(Menu)`
  margin-right: 1rem;
  cursor: pointer;
  &:hover {
    color: var(--body-color-dark);
  }
  @media ${device.tablet} {
    display: none;
  }
`

// * Exceptional case to include a tag => Page reload should happen when clicked on brand name in main header
export const BrandLink = styled.a`
  color: var(--body-color);
  &:hover {
    color: var(--body-color-dark);
  }
`
export const StyledOffcanvas = styled(Offcanvas)`
  @media ${device.tablet} {
    display: none;
  }
`
