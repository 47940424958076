import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import ImageItem from '../Image'
import SmallElement from '../SmallElement'

import { NavbarStretched } from './styles'

import { HeaderProps } from './types'

const Header = ({ content }: HeaderProps) => {
  const [leftNav, rightNav] = content
  return (
    <NavbarStretched expand="lg" className="mb-3">
      <Container fluid>
        {/* // * Left side of Navbar */}
        {leftNav.Content_Type === 'brand' && (
          <Navbar.Brand href="#">
            <ImageItem content={leftNav.Content} />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="invoice-header-nav" />
        {/* // * Right side of Navbar */}
        {rightNav.Content_Type === 'small-elements' && (
          <Navbar.Collapse>
            <Nav className={rightNav.Content.Class}>
              {/* // * Render all elements in right side of navbar */}
              {rightNav.Content?.Elements?.map(element => (
                <SmallElement key={element.Key} content={element} />
              ))}
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </NavbarStretched>
  )
}

export default Header
