import { AnyObject } from '../types/common'
import { MapObject, PageData } from '../types/dynamic'
import { getJSONDataFromWebStorage } from './storage'

// * Find url and page type from pathname
export const pageDataFromPathName = (
  pathName: string,
  id?: string
): PageData => {
  if (id) {
    const url = pathName.split(id)[0]
    if (pathName.includes('Edit')) {
      return { url, type: 'edit' }
    } else if (pathName.includes('Print')) {
      return { url: `${url}/Print/`, type: 'print' }
    } else {
      return { url: `${url}/View/`, type: 'view' }
    }
  }
  if (pathName.includes('Create')) {
    return { url: `${pathName}/`, type: 'create' }
  }
  if (pathName.includes('Dashboard')) {
    return {
      url:
        getJSONDataFromWebStorage('dashboardPath')?.dashboardPath ||
        `${pathName}/Page/`,
      type: 'list'
    }
  }
  return { url: `${pathName}/Page/`, type: 'list' }
}

export const errorMessageFromError = (error: AnyObject) =>
  error.response?.data?.Msg || 'Oops! Unexpected Error Occured'

export const createRequestBody = (
  variables: AnyObject,
  requestBody?: MapObject
) => {
  const body: AnyObject = {}
  requestBody?.forEach(([key, value]) => {
    if (value in variables) {
      body[key] = variables[value]
    }
  })
  return body
}
