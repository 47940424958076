import styled from 'styled-components'
import { zIndexMap } from '../../../../../styles/styled/zIndexMap'
import { TimelinePointProps, TimelinePointVariant } from './types'

const getVariant = (variant: TimelinePointVariant) => `var(--bs-${variant})`

export const TimelineItem = styled.li`
  position: relative;
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
  &:not(:last-child) {
    padding-bottom: 1.8rem;
  }
  &:last-of-type::after {
    content: '';
    position: absolute;
    bottom: -5px;
    width: 1px;
    height: 100%;
    background: linear-gradient(#fff0, #fff);
    left: -1px;
  }
`
export const TimelinePoint = styled.span<TimelinePointProps>`
  position: absolute;
  top: 0;
  z-index: ${zIndexMap.timelinePoint};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;

  border: 0;
  background-color: ${props => getVariant(props.variant)};
  top: 0.233rem;
  height: 12px;
  width: 12px;
  left: -0.39rem;
  &::before {
    content: '';
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    top: -33.333%;
    background: rgba(115, 103, 240, 0.12);
    border-radius: 50%;
    left: -33.333%;
  }
`
