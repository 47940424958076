export const MAIN_DATA = 'MAIN_DATA'
export const FORM = 'FORM'
export const SET_ACCESS = 'SET_ACCESS'
export const SET_DATA = 'SET_DATA'
export const SET_DATA_ITEM = 'SET_DATA_ITEM'
export const SET_DATUM = 'SET_ELEMENT_DATUM'
export const SET_ELEMENTS = 'SET_ELEMENTS'
export const SET_ELEMENT_ITEM = 'SET_ELEMENT_ITEM'
export const SET_ELEMENT_ITEM_DATA = 'SET_ELEMENT_ITEM_DATA'
export const SET_FILL_ROW_ITEM_DATA = 'SET_FILL_ROW_ITEM_DATA'
export const SET_FILL_ROWS_ITEM_DATA = 'SET_FILL_ROW_ITEM_DATA'
export const CLEAR_ROW_ITEM_DATA = 'CLEAR_ROW_ITEM_DATA'
export const SET_ROW_ITEM_ELEMENT = 'SET_ROW_ITEM_ELEMENT'
export const SET_PARAMETER_ELEMENT = 'SET_PARAMETER_ELEMENT'
export const SET_ROW_ITEM_DATA = 'SET_ROW_ITEM_DATA'
export const SET_URLS = 'SET_URLS'
export const SET_SUCCESS = 'SET_SUCCESS'
export const BUTTON_LOADING = 'BUTTON_LOADING'
export const RESET_FORM = 'RESET_FORM'
