import { AnyObject } from '../../types/common'
import {
  SET_PRINT_BUSINESS_INFO,
  SET_PRINT_BW_LOGO,
  SET_PRINT_DATA,
  SET_PRINT_FILE_NAME,
  SET_PRINT_FONT,
  SET_PRINT_IMAGES,
  SET_PRINT_LAYOUT,
  SET_PRINT_LOGO,
  SET_PRINT_PARTICULARS,
  SET_PRINT_START,
  SET_PRINT_SUBTOTAL,
  SET_ROW_TITLES
} from './actionTypes'

export const setPrintLayout = (payload: AnyObject) => ({
  type: SET_PRINT_LAYOUT,
  payload
})

export const setPrintdata = (payload: AnyObject) => ({
  type: SET_PRINT_DATA,
  payload
})

export const setPrintLogo = (payload: AnyObject) => ({
  type: SET_PRINT_LOGO,
  payload
})

export const setPrintFileName = (payload: AnyObject) => ({
  type: SET_PRINT_FILE_NAME,
  payload
})

export const setPrintBWLogo = (payload: AnyObject) => ({
  type: SET_PRINT_BW_LOGO,
  payload
})

export const setPrintFont = (payload: AnyObject) => ({
  type: SET_PRINT_FONT,
  payload
})

export const setPrintBusinessInfo = (payload: AnyObject) => ({
  type: SET_PRINT_BUSINESS_INFO,
  payload
})

export const setPrintParticulars = (payload: AnyObject[]) => ({
  type: SET_PRINT_PARTICULARS,
  payload
})

export const setPrintSubtotal = (payload: AnyObject[]) => ({
  type: SET_PRINT_SUBTOTAL,
  payload
})

export const setRowTitles = (payload: AnyObject[]) => ({
  type: SET_ROW_TITLES,
  payload
})
export const setPrintStart = (payload: boolean) => ({
  type: SET_PRINT_START,
  payload
})

export const setPrintImages = (payload: AnyObject[]) => ({
  type: SET_PRINT_IMAGES,
  payload
})
