import DOMPurifier from '../../components/DOMPurifier'

import { dateFormatter } from '../../../../../utils/format'

import { InvoiceElementContent, InvoiceElementProps } from './types'

const InvoiceElement = ({ data, source }: InvoiceElementProps) => {
  const getContent = (element: InvoiceElementContent) => {
    switch (element.Type) {
      case 'date': {
        return dateFormatter(source[element.Key], 'DD-MM-YYYY')
      }
      case 'remarks':
        // * Remarks will be HTML data (From Editor)
        return <DOMPurifier html={source[element.Key]} as="span" />
      default:
        return source[element.Key]
    }
  }

  return (
    <>
      {/* //*TODO Not sure the below line is required */}
      {/* {data.Title && <h3>{data.Title}</h3>} */}
      {data?.Elements.map(element => (
        <p key={element.Key} style={element.Style || {}}>
          {element.Label && `${element.Label}: `}
          {getContent(element)}
        </p>
      ))}
    </>
  )
}

export default InvoiceElement
