import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { ChildrenProps } from '../../../../../types/common'

const FormWrapper = ({ children }: ChildrenProps) => (
  <Form.Group className="mb-3 form-item">
    <Row>{children}</Row>
  </Form.Group>
)

export default FormWrapper
