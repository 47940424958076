import moment from 'moment'
import { Body } from '../types/api'

const formDataFromArray = (
  value: any[],
  bodyFormData: any,
  key: string,
  prevIndex?: number
) => {
  value.forEach((item, index: number) => {
    if (key.toLowerCase().includes('ignore')) {
      if (typeof item === 'object') {
        if (Array.isArray(item)) {
          formDataFromArray(item, bodyFormData, key)
        } else {
          Object.entries(item).forEach(([itemKey, itemValue]: any) => {
            if (Array.isArray(itemValue)) {
              formDataFromArray(
                itemValue,
                bodyFormData,
                itemKey,
                key === 'multipleForm-ignore' ? index : prevIndex
              )
            } else {
              if (itemValue instanceof Date) {
                bodyFormData.append(
                  itemKey,
                  moment(itemValue).format('YYYY-MM-DD')
                )
              } else {
                bodyFormData.append(itemKey, itemValue)
              }
            }
          })
        }
      }
    } else {
      let keyVal = key
      if (key === 'Supplier[]' ?? prevIndex) {
        keyVal = `Supplier${prevIndex}[]`
      }
      if (item instanceof Date) {
        bodyFormData.append(key, moment(item).format('YYYY-MM-DD'))
      } else {
        bodyFormData.append(keyVal, item)
      }
    }
  })
}

export const jsonToFormDataConverter = (body: Body) => {
  const bodyFormData = new FormData()
  Object.entries(body).forEach(([key, value]) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        formDataFromArray(value, bodyFormData, key)
      } else {
        if (value instanceof Date) {
          bodyFormData.append(key, moment(value).format('YYYY-MM-DD'))
        } else {
          const JSONValue = JSON.stringify(value)
          bodyFormData.append(key, JSONValue)
        }
      }
    } else {
      bodyFormData.append(key, value)
    }
  })
  return bodyFormData
}

export const responsiveCol = (col: number) => col || 12
