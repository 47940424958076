import { Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import Button from '../../items/Button'
import Header from '../../items/Header'
import InvoiceElement from '../../items/InvoiceElement'
import InvoiceRow from '../../items/InvoiceRow'
import SubTotal from '../../items/SubTotal'

import { PageCenter, VerticalPageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'

import { useGetData } from '../../../../../hooks/reactQuery'
import { errorMessageFromError } from '../../../../../utils/dynamicData'

import { InvoiceViewContentElement, InvoiceViewProps } from './types'
import { AnyObject } from '../../../../../types/common'
import { IdParams } from '../../../../../types/params'
import { useCallback, useEffect } from 'react'
import { postData } from '../../../../../api'
import {
  setPrintBusinessInfo,
  setPrintBWLogo,
  setPrintdata,
  setPrintFileName,
  setPrintFont,
  setPrintImages,
  setPrintLayout,
  setPrintLogo,
  setPrintParticulars,
  setPrintSubtotal
} from '../../../../../store/print/actions'
import { useDispatch } from 'react-redux'
import { errorToast } from '../../../../../utils/toast'

const InvoiceView = ({ content }: InvoiceViewProps) => {
  const {
    Elements: elements,
    Source: source,
    Print: print,
    PrintFilePrefix: prefix,
    PrintFileCode: code
  } = content
  const { id } = useParams<IdParams>()
  const url = content.Source || ''
  const uniqueKey = `${url}-${id}`
  const body = { Id: id }
  const hasUrl = !!url
  const dispatch = useDispatch()
  const { isLoading, error, data } = useGetData(
    uniqueKey,
    { path: url, body },
    hasUrl,
    false,
    0,
    0
  )

  const getPrintData = useCallback(async (url, layout) => {
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintdata(response.data))
      const category = response.data?.Category
      const categoryCheck = layout.Layout.NoCategoryCheck
      layout?.Layout?.ColourLogo &&
        getPrintLogo(layout.Layout.ColourLogo, category, categoryCheck)
      layout?.Layout?.BWLogo &&
        getPrintBWLogo(layout.Layout.BWLogo, category, categoryCheck)
    } catch (err: any) {
      errorToast(err, `error-print-data-${id}`)
    }
  }, [])

  const getPrintParticulars = useCallback(async url => {
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintParticulars(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-particulars-${id}`)
    }
  }, [])

  const getPrintSubTotal = useCallback(async url => {
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintSubtotal(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-subTotal-${id}`)
    }
  }, [])

  const getPrintBusinessInfo = useCallback(async url => {
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintBusinessInfo(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-businessInfo-${id}`)
    }
  }, [])

  const getPrintFont = useCallback(async url => {
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      response.data && dispatch(setPrintFont(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-font-${id}`)
    }
  }, [])

  const getPrintLogo = useCallback(async (url, category, noCategoryCheck) => {
    if (!noCategoryCheck && category === 'Media') {
      url = url.replace('Portrait', 'Landscape')
    }
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintLogo(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-logo-${id}`)
    }
  }, [])

  const getPrintBWLogo = useCallback(async (url, category, noCategoryCheck) => {
    if (!noCategoryCheck && category === 'Media') {
      url = url.replace('Portrait', 'Landscape')
    }
    try {
      const response = await postData({
        path: url,
        body: {
          Id: id
        }
      })
      dispatch(setPrintBWLogo(response.data))
    } catch (err: any) {
      errorToast(err, `error-print-bw-logo-${id}`)
    }
  }, [])

  const getPrintImages = useCallback((urls: string[]) => {
    const responses: AnyObject[] = []
    Array.isArray(urls) &&
      urls?.map(async (url: string) => {
        try {
          const response = await postData({
            path: url,
            body: {}
          })
          responses.push(response.data)
        } catch (err: any) {
          errorToast(err, `error-print-bw-logo-${id}`)
        }
      })
    dispatch(setPrintImages(responses))
  }, [])

  const getPrintLayout = useCallback(async () => {
    try {
      dispatch(setPrintParticulars([]))
      const response = await postData({ path: print || '', body: { Id: id } })
      const layout: AnyObject = response.data
      dispatch(setPrintLayout(layout))
      layout?.Layout?.Font && getPrintFont(layout.Layout.Font)
      layout?.Layout?.Images && getPrintImages(layout.Layout.Images)
      layout?.Layout?.BusinessInfo &&
        getPrintBusinessInfo(layout?.Layout.BusinessInfo)
      layout?.Layout?.Data && getPrintData(layout?.Layout.Data, layout)
      layout?.Layout?.Particulars?.Source &&
        getPrintParticulars(layout?.Layout.Particulars.Source)
      layout?.Layout?.SubTotal?.Source &&
        getPrintSubTotal(layout?.Layout.SubTotal.Source)
    } catch (err) {
      errorToast(err, `error-print-layout-${id}`)
    }
  }, [])

  useEffect(() => {
    id && print && getPrintLayout()
    id && code && prefix && dispatch(setPrintFileName({ prefix, code }))
  }, [])

  // * Render each type of invoice view component
  const renderComponent = (element: InvoiceViewContentElement) => {
    switch (element.Content_Type) {
      case 'header':
        return <Header content={element.Content} />
      case 'invoice-elements':
        return (
          <InvoiceElement
            data={element.Content}
            source={data?.data as AnyObject}
          />
        )
      case 'invoice-rows':
        return <InvoiceRow content={element.Content} />
      case 'invoice-subtotal':
        return (
          <SubTotal
            source={element.Content.Source}
            requestBody={element.Content.RequestBody}
          />
        )
      case 'button-elements':
        return (
          <>
            {element.Content.Elements.map(button => (
              <>
                {((button.Access &&
                  button.Access.includes(data?.data?.Access)) ||
                  !button.Access) && (
                  <Button
                    key={button.Key}
                    element={button}
                    data={data}
                    className="me-3 mb-3"
                  />
                )}
              </>
            ))}
          </>
        )
      default:
        return null
    }
  }

  if (isLoading) {
    return (
      <Card>
        <PageLoading height="60vh" />
      </Card>
    )
  }

  if (error) {
    const errorMessage = errorMessageFromError(error as AnyObject)
    return (
      <Card>
        <VerticalPageCenter>{errorMessage}</VerticalPageCenter>
      </Card>
    )
  }

  if (!elements.length && source) {
    return (
      <Card>
        <PageCenter>No Data Found</PageCenter>
      </Card>
    )
  }

  return (
    <Card>
      <Row className="mb-3">
        {elements.map(element => (
          <Col key={element.Key} sm={12} md={element.Size || 12}>
            {renderComponent(element)}
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default InvoiceView
