import classNames from 'classnames'
import { MouseEvent, useEffect, useState } from 'react'
import Loader from '../Loader'
import { RippleButtonProps } from './types'

import { RippleWave, WaveButton, SpinnerWrapper } from './styles'

const RippleButton = ({
  className,
  children,
  isLoading,
  onClick,
  ...rest
}: RippleButtonProps) => {
  const [mounted, setMounted] = useState(false)
  const [isRippling, setIsRippling] = useState(false)
  const [coords, setCoords] = useState({ x: -1, y: -1 })

  // ** Toggle mounted on mount & unmount
  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  // ** Check for coords and set ripple
  useEffect(() => {
    if (mounted) {
      if (coords.x !== -1 && coords.y !== -1) {
        setIsRippling(true)
        setTimeout(() => setIsRippling(false), 500)
      } else {
        setIsRippling(false)
      }
    }
  }, [coords])

  // ** Reset Coords on ripple end
  useEffect(() => {
    mounted && !isRippling && setCoords({ x: -1, y: -1 })
  }, [isRippling])

  const handleWaveButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    const rect = event.currentTarget.getBoundingClientRect()
    setCoords({ x: event.clientX - rect.left, y: event.clientY - rect.top })
    onClick && onClick(event)
  }

  const Ripples = () => {
    if (isRippling) {
      return <RippleWave coords={coords} />
    } else return null
  }

  return (
    <WaveButton
      className={classNames(className, 'position-relative')}
      onClick={handleWaveButtonClick}
      disabled={isLoading || rest.disabled}
      {...rest}
    >
      {/* // * Show Loader in button */}
      {isLoading && (
        <SpinnerWrapper className="position-absolute">
          <Loader size="sm" />
        </SpinnerWrapper>
      )}
      {/* // * Show text if not loading   */}
      <div className={isLoading ? 'invisible' : ''}>{children}</div>
      {/* // * Show ripple effect */}
      <Ripples />
    </WaveButton>
  )
}

export default RippleButton
