import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { OnChangeValueType } from '../../../../../types/formElement'
import FormItem from '../../layout/FormItem'

import ImageItem from '../Image'

import { NavbarStretched } from './styles'

import { HeaderFormProps } from './types'

const HeaderForm = ({ content, form, handleOnChange }: HeaderFormProps) => {
  const [leftNav, rightNav] = content
  return (
    <NavbarStretched expand="lg" className="mb-3">
      <Container fluid>
        {/* // * Left side of Navbar */}
        {leftNav.Content_Type === 'brand' && (
          <Navbar.Brand href="#">
            <ImageItem content={leftNav.Content} />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="invoice-header-nav" />
        {/* // * Right side of Navbar */}
        {rightNav.Content_Type === 'small-form-elements' && (
          <Navbar.Collapse>
            <Nav className={rightNav.Content.Class}>
              {/* // * Render all elements in right side of navbar */}
              {rightNav.Content?.Elements?.map(element => (
                <FormItem
                  key={element.Key}
                  element={element}
                  value={form.data?.[element.Name] || ''}
                  onChange={(value: OnChangeValueType, data: any) =>
                    handleOnChange(value, element, data)
                  }
                />
              ))}
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </NavbarStretched>
  )
}

export default HeaderForm
