import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { MultiValue, SingleValue } from 'react-select'
import ReactSelect from 'react-select/async'
import { postData } from '../../../../../api'
import { AnyObject } from '../../../../../types/common'
import { Option } from '../../../../../types/formElement'
import { responsiveCol } from '../../../../../utils/conversions'
import { errorToast } from '../../../../../utils/toast'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import { FormItemProps } from '../../layout/FormItem/types'
import CustomOptions from './CustomOptions'

const AsyncSelect = ({
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const [options, setOptions] = useState<any>(element.Options || [])
  const [selectedOption, setSelectedOption] = useState<
    SingleValue<Option> | MultiValue<Option>
  >(value)

  useEffect(() => {
    element.Options?.length && setOptions(element.Options)
  }, [element.Options])

  useEffect(() => {
    if (value) {
      const fillSelectedOption = options.find(
        (option: Option) => option.label === value || option.value === value
      )
      if (fillSelectedOption) {
        setSelectedOption(fillSelectedOption)
      }
    }
  }, [value])

  useEffect(() => {
    element.Disabled && setSelectedOption(null)
  }, [element.Disabled])

  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  const handleAutoCompleteChange = (
    changeValue: SingleValue<Option> | MultiValue<Option>
  ) => {
    if (onChange && changeValue) {
      if ('value' in changeValue) {
        const selectedValue = element.FillValue
          ? changeValue[element.FillValue]
          : changeValue.value
        const { label, value, ...optionData } = changeValue
        const selectedData: AnyObject = {}
        if (element.PassOption) {
          selectedData.fill = optionData
        } else if (element.OnChangeFill) {
          selectedData.onChangeFill = {
            values: changeValue,
            fillItems: element.OnChangeFill
          }
        }
        onChange(selectedValue, selectedData)
      } else {
        let isDisabled = false
        if (changeValue.length >= (element.SelectLimit || 1)) {
          isDisabled = true
        }
        setOptions((currentOptions: Option[]) =>
          currentOptions.map((optionItem: Option) => ({
            ...optionItem,
            isDisabled
          }))
        )
        const selectedData: AnyObject = {}
        if (element.OnChangeFill) {
          selectedData.onChangeFill = {
            values: changeValue,
            fillItems: element.OnChangeFill
          }
        }
        if (element.OnChangeSum) {
          selectedData.onChangeSum = {
            values: changeValue,
            fillItems: element.OnChangeSum
          }
        }
        onChange([[...changeValue]], { isMultiselect: true, selectedData })
      }
      setSelectedOption(changeValue)
    }
  }

  const loadOptions = async (term: string = '') => {
    if ((term.length > 2 || element.hasDefaultOptions) && element.Source) {
      const body: AnyObject = {}
      element.SourceRequestBody?.forEach((keys: string[]) => {
        body[keys[0]] = term
      })
      try {
        const response = await postData({
          path: element.Source,
          body
        })
        const data: AnyObject[] = response.data
        const newOptions = data.map(item => ({
          value: item.Id || item._id,
          label: item.Name,
          ...item
        }))
        setOptions(newOptions)
        return newOptions
      } catch (error) {
        errorToast(error, `error-options-${element.Source}-${term}`)
        return []
      }
    }
  }

  return (
    <FormWrapper>
      <Label {...labelProps} />
      <Col xs={12} md={responsiveCol(split[1])}>
        {element.Disabled ? (
          <Form.Control
            key={element.Key}
            name={element.Name}
            placeholder={element.Placeholder || 'Select...'}
            value=""
            disabled={element.Disabled}
          />
        ) : (
          <ReactSelect
            className="react-select"
            key={element.Key}
            aria-label="Dynamic Select"
            value={selectedOption}
            onChange={handleAutoCompleteChange}
            cacheOptions
            loadOptions={loadOptions}
            isSearchable
            placeholder={value || 'Select...'}
            isMulti={element.IsMulti}
            components={{ Option: CustomOptions }}
            isOptionDisabled={option => !!option?.isDisabled}
          ></ReactSelect>
        )}
      </Col>
    </FormWrapper>
  )
}

export default AsyncSelect
