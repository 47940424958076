import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation
} from 'react-router-dom'
import { logout } from '../../api'
import { defaultFallbackPath, defaultPaths } from '../../constants/routes'

const ProtectedRoute = ({
  component: Component,
  showRoute,
  fallbackPath,
  path,
  redirectPath,
  ...restOfProps
}: any) => {
  const { search } = useLocation()
  // * Logout (Clear data from store and web storage)
  if (!showRoute && fallbackPath === defaultFallbackPath) {
    logout()
  }

  // * Render the component if route can be shown. Else redirect
  const renderProps = (props: RouteComponentProps) => {
    let updatedFallbackPath = fallbackPath
    const originalPath = props.location.pathname

    // * Add redirect path to query string to redirect after login
    if (
      fallbackPath === defaultFallbackPath &&
      !defaultPaths.includes(originalPath)
    ) {
      updatedFallbackPath += `?redirect=${originalPath}`
    }

    // * Redirect if the route is not present after building
    if (redirectPath) {
      return <Redirect to={showRoute ? redirectPath : updatedFallbackPath} />
    }

    // * Show Component
    if (showRoute) {
      return <Component {...props} />
    }

    // * Update fallback path to redirect path to redirect after login
    if (search) {
      const qsRedirectPath = new URLSearchParams(search).get('redirect')
      if (qsRedirectPath) {
        updatedFallbackPath = qsRedirectPath
      }
    }

    // * Normal redirect
    return <Redirect to={updatedFallbackPath} />
  }

  // * Create Protected Route
  return <Route {...restOfProps} render={props => renderProps(props)} />
}

export default ProtectedRoute
