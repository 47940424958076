import styled from 'styled-components'

export const PageCenter = styled.div<{ height?: string }>`
  display: grid;
  place-items: center;
  height: ${props => props.height || '100%'};
`
export const VerticalPageCenter = styled(PageCenter)`
  height: calc(100vh - 10rem);
`
