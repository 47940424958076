import { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  FormElement,
  OnChangeValueType
} from '../../../../../types/formElement'
import { responsiveCol } from '../../../../../utils/conversions'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import FormItem from '../../layout/FormItem'
import { FormItemProps } from '../../layout/FormItem/types'

const Percentage = ({
  element,
  onChange,
  split = [12, 12],
  perValue,
  baseValue = 0,
  type,
  rate,
  defaultPercent,
  IsValueBaseOnEdit
}: FormItemProps) => {
  const initialRender = useRef(true)
  const [percent, setPercent] = useState(rate || defaultPercent || '0.00')
  const [percentValue, setPercentValue] = useState(
    (rate && !isNaN(+rate) ? +rate * 0.01 * (baseValue || 0) : 0).toFixed(2)
  )

  const percentToValue = useCallback(
    (newPercent = (percent && +percent) || (rate && +rate)) => {
      let tempValue = 0
      if (newPercent !== undefined) {
        const percentInNum = +newPercent
        if (!isNaN(percentInNum)) {
          tempValue = +percentInNum * 0.01 * (baseValue || 0)
        }
      }
      const newVal = tempValue.toFixed(2)
      setPercentValue(newVal)
      return newVal
    },
    [baseValue]
  )

  const valueToPercent = useCallback(
    (newPercentValue = percentValue) => {
      let tempValue = 0
      if (newPercentValue !== undefined) {
        const percentValueInNum = +newPercentValue
        if (!isNaN(percentValueInNum)) {
          tempValue = baseValue ? (+percentValueInNum * 100) / baseValue : 0
        }
      }
      setPercent(tempValue.toFixed(2))
      return tempValue.toFixed(2)
    },
    [baseValue]
  )

  useEffect(() => {
    let defaultRate
    if (initialRender.current) {
      defaultRate = defaultPercent
      initialRender.current = false
    }
    if (percent && +percent && IsValueBaseOnEdit) {
      const newPercentValue = percentValue || 0
      setPercentValue(String(newPercentValue))
      const newPerValue = valueToPercent(newPercentValue)
      onChange &&
        typeof onChange === 'function' &&
        onChange(String(newPercentValue), {
          percentage: newPerValue,
          type: 'percentage'
        })
    } else {
      const newPerValue = perValue || rate || defaultRate || 0
      const percentageValue = +baseValue
        ? percentToValue(newPerValue && Number(newPerValue))
        : 0
      setPercent(String(newPerValue))
      onChange &&
        onChange(String(percentageValue), {
          percentage: newPerValue,
          type: 'percentage'
        })
    }
  }, [baseValue])

  const handlePercentValueChange = (
    val: OnChangeValueType,
    parentChange?: any
  ) => {
    const percentage = valueToPercent(Number(val))
    setPercentValue(String(val))
    parentChange &&
      typeof parentChange === 'function' &&
      parentChange(String(val), { percentage, type: 'percentage' })
  }

  const handlePercentChange = (val: OnChangeValueType, parentChange?: any) => {
    const percentageValue = percentToValue(Number(val))
    setPercent(String(val))
    parentChange &&
      typeof parentChange === 'function' &&
      parentChange(String(percentageValue), {
        percentage: val,
        type: 'percentage'
      })
  }

  const valueElement: FormElement = {
    Label: 'Value',
    Type: type || 'text',
    Name: element.Name || 'Value',
    Key: `${element.Name}Value`,
    Disabled: element.Disabled || false,
    Required: element.Required || 0
  }

  const percentElement: FormElement = {
    Label: 'Percentage',
    Type: 'text',
    Name: 'Percent',
    Key: `${element.Name}Percent`,
    Disabled: element.Disabled,
    Required: element.Required || 0
  }

  const labelProps: LabelProps = {
    text: element.Label || element.Type,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  return (
    <FormWrapper>
      <Label {...labelProps} />
      <Col xs={12} md={responsiveCol(split[1])}>
        <Row>
          <Col xs={12} md={split[2] || 6}>
            <FormItem
              element={percentElement}
              value={percent}
              onChange={(val: OnChangeValueType) =>
                handlePercentChange(val, onChange)
              }
            />
          </Col>
          <Col xs={12} md={split[3] || 6}>
            <FormItem
              element={valueElement}
              value={percentValue}
              onChange={(val: OnChangeValueType) =>
                handlePercentValueChange(val, onChange)
              }
            />
          </Col>
        </Row>
      </Col>
    </FormWrapper>
  )
}

export default Percentage
