import { useContext, useEffect, useState } from 'react'

import Col from 'react-bootstrap/Col'

import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import RippleButton from '../../../common/RippleButton'
import FormItem from '../../layout/FormItem'

import { FormItemProps } from '../../layout/FormItem/types'
import { responsiveCol } from '../../../../../utils/conversions'
import { FormContext } from '../../../../../context/FormContext'
import {
  setFormData,
  setFormElementItemData,
  setFormDatum,
  setFormElementItem,
  setParameterElement,
  setRowItemData,
  setRowItemElement
} from '../../../../../store/dynamic/actions'
import {
  FormElement,
  OnChangeValueType
} from '../../../../../types/formElement'
import { AnyObject } from '../../../../../types/common'
import { ArrowUp, ArrowDown, Trash } from 'react-feather'
import { Form } from 'react-bootstrap'
import { postData } from '../../../../../api'
import { getParameterPercentFieldName } from '../../../../../utils/stringUtils'

const Multiple = ({
  split = [12, 12],
  element,
  onChange,
  data: formData
}: FormItemProps) => {
  const { state, dispatch } = useContext(FormContext)
  const [formState, setFormState] = useState<AnyObject>(state)
  const [rowValue, setRowValue] = useState<AnyObject>({})
  const [rowIndex, setRowIndex] = useState(element?.MultipleRows?.length || 1)
  const [fillEdit, setFillEdit] = useState(false)
  const [category, setCategory] = useState<any>()

  useEffect(() => {
    setFormState({ ...state })
  }, [state])

  // const updateRowIndex = useCallback(() => {
  //   setRowIndex(tempRowIndex => tempRowIndex + 1)
  // }, [])

  useEffect(() => {
    setRowValue(formState.data)
    const rowLength = formState.data?.['multipleForm-ignore']?.length
    if (!fillEdit && rowLength && rowLength > rowIndex) {
      setFillEdit(true)
      const rowsToAdd = rowLength - rowIndex
      addRow(rowsToAdd)
      formState.data['multipleForm-ignore'].pop()
      setFormState(state)
    }
  }, [formState.data])

  useEffect(() => {
    setCategory({ Category: formState.data.Category })
  }, [formState.data?.Category])

  const addRow = (length = 1) => {
    const tempArr: AnyObject[] = Array(length).fill({})
    let index = rowIndex
    for (let i = 0; i < length; i++) {
      tempArr[i] = { elements: element.Elements, index }
      ++index
    }
    const tempRows = [...(element?.MultipleRows || []), ...tempArr]
    const elementItem = {
      ...(formState?.elements?.[element.Name] || {}),
      MultipleRows: tempRows
    }
    const formData = { ...formState.data }
    const defaultElementData: AnyObject = {}
    element?.Elements?.forEach((multipleElem: any) => {
      if (multipleElem.Type === 'parameters') {
        let multipleElemData = {}
        multipleElem.Elements.forEach((el: any) => {
          const multipleInnerElements: AnyObject = {
            [el.Name]: el.Default || (el.Type === 'percentage' ? '0' : '')
          }
          if (el.Type === 'percentage') {
            multipleInnerElements[getParameterPercentFieldName(el.Name)] =
              el.Default || '0'
          } else if (el.Type === 'text') {
            multipleInnerElements[el.Name] = el.Default || ''
          }
          if (el.copyInitialValues) {
            let data = formState.data
            el.copyInitialValues.forEach((copyEl: string | number) => {
              data = data[copyEl]
            })
            multipleInnerElements[`${el.Name}`] = data
          }
          multipleElemData = { ...multipleElemData, ...multipleInnerElements }
        })
        defaultElementData[multipleElem.Name] = [multipleElemData]
      } else if (multipleElem.Type !== 'measurements') {
        defaultElementData[multipleElem.Name] = multipleElem.Default || ''
      }
    })
    formData[element.Name].push(defaultElementData)
    dispatch(setFormData({ ...formData }))

    dispatch(setFormElementItem({ [element.Name]: elementItem }))
    setRowIndex(index)
  }

  const deleteRowWithData = (deleteRowIndex: number) => {
    const formData = { ...formState.data }
    if ((element?.MultipleRows?.length || 0) > 1) {
      // *Delete row layout and data from state
      const tempRows = [...(element?.MultipleRows || [])]
      tempRows.splice(deleteRowIndex, 1)
      const elementItem = {
        ...(formState?.elements?.[element.Name] || {}),
        MultipleRows: tempRows
      }
      dispatch(setFormElementItem({ [element.Name]: elementItem }))
      formData[element.Name].splice(deleteRowIndex, 1)
      onChange && onChange(formData[element.Name])
    } else {
      const defaultElementData: AnyObject = {}
      element?.Elements?.forEach((multipleElem: any) => {
        defaultElementData[multipleElem.Name] = multipleElem.Default
      })
      formData[element.Name].splice(deleteRowIndex, 1)
      formData[element.Name] = [defaultElementData]
      onChange && onChange(formData[element.Name])
    }
    const resultFillElement = formState.elements[element.Name]?.Elements?.find(
      (el: any) => el.Name === 'Net[]'
    )
    const calculationResult = formData[element.Name]?.[0]?.['Net[]'] || 0
    calculateRows(
      resultFillElement.OnChange.CalculateRows,
      0,
      calculationResult
    )
  }

  const calculateRows = function (
    calculationData: AnyObject,
    currentIndex: number,
    currentValue: number = 0
  ) {
    const formElementData = [...state.data[element.Name]]
    if (calculationData.Operator) {
      const rowAddValue = formElementData.reduce(
        (acc: number, item: string, itemIndex: number) => {
          if (itemIndex === currentIndex) {
            if (calculationData.Operator === 'multiple') {
              acc *= currentValue ?? 1
            } else {
              acc += currentValue || 0
            }
          } else {
            if (calculationData.Operator === 'multiple') {
              acc *= +item[calculationData.data] ?? 1
            }
            acc += +item[calculationData.data] || 0
          }
          return acc
        },
        0
      )
      dispatch(
        setFormDatum({
          [calculationData.resultFill]: (+rowAddValue).toFixed(2)
        })
      )
      if (state.elements[calculationData.resultFill]?.OnChange?.Calculate) {
        let calculatedAmount = 0
        const formData = { ...state.data }
        state.elements[calculationData.resultFill]?.OnChange?.Calculate.forEach(
          (calculateItem: any) => {
            calculatedAmount = calculateItem.BaseValues?.reduce(
              (acc: number, ElValue: string, index: number) => {
                let formValue = formData[ElValue] ? +formData[ElValue] : 0
                if (ElValue === 'this') {
                  formValue = rowAddValue ? +rowAddValue : 0
                }
                if (calculateItem.Operators?.[index] === 'add') {
                  acc += formValue
                } else if (calculateItem.Operators?.[index] === 'subtract') {
                  acc -= +formValue
                }
                return acc
              },
              0
            )
            if (calculateItem.ResultFill) {
              formData[calculateItem.ResultFill] = calculatedAmount.toFixed(2)
              dispatch(
                setFormDatum({
                  [calculateItem.ResultFill]: calculatedAmount.toFixed(2)
                })
              )
            }
          }
        )
        return calculatedAmount.toFixed(2)
      }
    }
    return '0'
  }

  const handleRowChange = (
    value: OnChangeValueType,
    data: any,
    index: number,
    rowElement: FormElement
  ) => {
    const tempElement = [...(formState.data[element.Name] || [])]

    tempElement[index] = {
      ...tempElement[index],
      [rowElement.Name]: value
    }
    setRowValue((tempRowValue: any) => ({
      ...tempRowValue,
      [element.Name]: tempElement
    }))
    const formData = { ...formState.data }
    // TODO: Remove the below condition if unnecessary
    if (!formData[element.Name]?.length) {
      formData[element.Name] = []
    }
    formData[element.Name][index] = {
      ...formData[element.Name][index],
      [rowElement.Name]: value
    }
    onChange && onChange(formData[element.Name])
    if (rowElement?.OnChange?.Calculate) {
      const formData = { ...state.data }
      const calculateData: string[] = rowElement?.OnChange?.Calculate?.data
      const calculateOperators: string[] =
        rowElement?.OnChange?.Calculate?.Operators
      const calculationResult = calculateData.reduce(
        (acc, formRowItem, formRowItemIndex) => {
          let accValue
          if (formRowItem === 'this') {
            accValue = +value
          } else if (formRowItem === 'Qty') {
            const qtyFields = state.elements[element.Name].MultipleRows[
              index
            ].elements
              .find((parEl: AnyObject) => parEl.Name === 'Measurements-ignore')
              ?.Elements?.filter((mesEl: AnyObject) =>
                mesEl.Class?.includes('multiply')
              )
              .map((fields: AnyObject) => fields.Name)
            const measurementFields =
              formData[element.Name][index]?.['Measurements-ignore']
            let qtyValue =
              calculateOperators[formRowItemIndex] === 'multiply' ? 1 : 0
            measurementFields?.forEach((field: AnyObject) => {
              qtyFields?.forEach((qtyField: string) => {
                if (field[qtyField] && !isNaN(+field[qtyField])) {
                  if (calculateOperators[formRowItemIndex] === 'multiply') {
                    qtyValue *= +field[qtyField]
                  } else {
                    qtyValue += +field[qtyField]
                  }
                }
              })
            })
            accValue = qtyValue
          } else {
            accValue = +formData[element.Name][index][formRowItem]
          }
          if (calculateOperators[formRowItemIndex] === 'add') {
            return (acc || 0) + (accValue || 0)
          }
          if (calculateOperators[formRowItemIndex] === 'multiply') {
            return (acc ?? 1) * (accValue ?? 1)
          }
          return acc
        },
        0
      )
      const resultFillName = rowElement?.OnChange?.Calculate?.resultFill
      formData[element.Name][index][resultFillName] = String(
        calculationResult ? (+calculationResult).toFixed(2) : 0
      )
      const resultFillElement: FormElement = state.elements[
        element.Name
      ].Elements.find((el: FormElement) => el.Name === resultFillName)
      resultFillElement?.OnChange?.CalculateRows &&
        calculateRows(
          resultFillElement.OnChange.CalculateRows,
          index,
          calculationResult
        )
    }
  }

  const expandParameterComponent = async (
    value: OnChangeValueType,
    index: number,
    parameterElement: FormElement,
    rowElementIndex: number,
    rowElement: FormElement
  ) => {
    if (parameterElement?.OnChange?.Source) {
      const body: AnyObject = {}
      parameterElement?.OnChange?.SourceRequestBody?.forEach(reqBody => {
        if (reqBody[1] === parameterElement.Name) {
          body[reqBody[0]] = value
        }
      })
      const response = await postData({
        path: parameterElement.OnChange?.Source,
        body
      })
      const responseData = response.data
      const elementItem = { ...state.elements[element.Name] }
      if (parameterElement.OnChange?.Response === 'Options') {
        const parameterOptionElement = elementItem.MultipleRows[index].elements[
          rowElementIndex
        ].Elements.find(
          (element: any) => element.Name === parameterElement.OnChange?.Name
        )
        if (parameterOptionElement) {
          parameterOptionElement.Options = response.data.map((item: any) => ({
            label: item.Name,
            value: item.id || item._id
          }))
          parameterOptionElement.Source = null

          dispatch(
            setParameterElement({
              elementName: element.Name,
              rowIndex: index,
              rowElementIndex,
              parameterName: parameterElement.OnChange?.Name,
              element: parameterOptionElement
            })
          )
        }
      } else {
        if (responseData?.Parameters?.length) {
          elementItem.MultipleRows[index].elements[rowElementIndex].Elements = [
            elementItem.MultipleRows[index].elements[rowElementIndex]
              .Elements[0],
            ...responseData?.Parameters
          ]
        } else {
          elementItem.MultipleRows[index].elements[rowElementIndex].Elements = [
            elementItem.MultipleRows[index].elements[rowElementIndex]
              .Elements[0]
          ]
        }
        if (responseData?.Measurement) {
          let measurementData: AnyObject = {}
          let measurementObject = {}
          if (
            Array.isArray(responseData.Measurement) &&
            responseData.Measurement?.length
          ) {
            measurementData = {
              Type: 'parameters',
              Name: 'Measurements-ignore',
              Elements: responseData.Measurement
            }
          } else {
            measurementObject = {
              Default: 0,
              Label: 'Qty',
              Name: responseData.Measurement || 'Qty',
              Type: 'text',
              IsMeasurement: true
            }
            measurementData = {
              Type: 'parameters',
              Name: 'Measurements-ignore',
              Elements: [measurementObject]
            }
          }
          const measurementIndex = elementItem.MultipleRows[
            index
          ].elements.findIndex(
            (elem: FormElement) =>
              elem.Type === 'measurements' ||
              elem.Name === 'Measurements-ignore'
          )
          elementItem.MultipleRows[index].elements[measurementIndex] =
            measurementData
          measurementData.Elements.forEach(
            (rowElement: AnyObject, parameterIndex: number) => {
              setRowItemData({
                elementName: element.Name,
                rowIndex: index,
                rowElementName: rowElement.Name,
                parameterIndex,
                parameterElementName: parameterElement.Name,
                value: ''
              })
            }
          )
        }
        dispatch(
          setRowItemElement({ responseData, element, index, rowElementIndex })
        )
      }
    }
    const formData = { ...state.data }
    if (parameterElement?.OnChange?.Calculate) {
      const calculateData: string[] =
        parameterElement?.OnChange?.Calculate?.data
      const calculateOperators: string[] =
        parameterElement?.OnChange?.Calculate?.Operators
      const calculationResult = calculateData.reduce(
        (acc, formRowItem, formRowItemIndex) => {
          let accValue
          if (formRowItem === 'this') {
            accValue = +value
          } else if (formRowItem === 'Qty') {
            const qtyFields = state.elements[element.Name].MultipleRows[
              index
            ].elements
              .find((parEl: AnyObject) => parEl.Name === 'Measurements-ignore')
              ?.Elements?.filter((mesEl: AnyObject) =>
                mesEl.Class?.includes('multiply')
              )
              .map((fields: AnyObject) => fields.Name)
            const measurementFields =
              formData[element.Name][index]?.['Measurements-ignore']
            let qtyValue: number =
              calculateOperators[formRowItemIndex] === 'multiply' ? 1 : 0
            measurementFields.forEach((field: AnyObject) => {
              qtyFields.forEach((qtyField: string) => {
                if (field[qtyField] && !isNaN(+field[qtyField])) {
                  if (calculateOperators[formRowItemIndex] === 'multiply') {
                    qtyValue *= +field[qtyField]
                  } else {
                    qtyValue += +field[qtyField]
                  }
                }
              })
            })
            accValue = qtyValue
          } else {
            accValue = +formData[element.Name][index][formRowItem] || 0
          }
          if (calculateOperators[formRowItemIndex] === 'add') {
            return acc + (accValue || 0)
          } else if (calculateOperators[formRowItemIndex] === 'multiply') {
            return acc * (accValue ?? 1)
          }
          return acc
        },
        0
      )
      const resultFillName = parameterElement?.OnChange?.Calculate?.resultFill
      formData[element.Name][index][resultFillName] = String(
        calculationResult || 0
      )
      onChange && onChange(formData[element.Name] || 0)
      const resultFillElement: FormElement = state.elements[
        element.Name
      ].Elements.find((el: FormElement) => el.Name === resultFillName)
      resultFillElement?.OnChange?.CalculateRows &&
        calculateRows(
          resultFillElement.OnChange.CalculateRows,
          index,
          calculationResult
        )
    }
    if (parameterElement?.Class?.includes('Add')) {
      const measurementsElement = state.elements[element.Name].MultipleRows[
        index
      ].elements.find(
        (parEl: AnyObject) => parEl.Name === 'Measurements-ignore'
      )
      const qtyFields = measurementsElement?.Elements?.filter(
        (mesEl: AnyObject) => mesEl.Class?.includes('Add')
      ).map((fields: AnyObject) => fields.Name)
      const measurementFields =
        formData[element.Name][index]?.['Measurements-ignore']
      let qtyValue = 0
      measurementFields.forEach((field: AnyObject) => {
        qtyFields.forEach((qtyField: string) => {
          qtyValue += field[qtyField] ? +field[qtyField] : 0
        })
      })
      onChange && onChange(formData[element.Name] || 0)
      if (parameterElement?.OverrideLayout?.Name) {
        const resultElement = state.elements[element.Name].MultipleRows[
          index
        ].elements.find(
          (rowEl: AnyObject) =>
            rowEl.Name === parameterElement?.OverrideLayout?.Name
        )
        resultElement &&
          handleRowChange(String(qtyValue || 0), null, index, resultElement)
      }
    }
  }

  const handleParameterChange = (
    value: OnChangeValueType,
    data: any,
    index: number,
    rowElement: FormElement,
    parameterElement: FormElement,
    parameterIndex: number,
    rowElementIndex: number
  ) => {
    let newValue: any = value
    if (data?.type === 'percentage' && data.percentage !== undefined) {
      const fieldName = getParameterPercentFieldName(parameterElement.Name)
      dispatch(
        setRowItemData({
          elementName: element.Name,
          rowIndex: index,
          rowElementName: rowElement.Name,
          parameterIndex: 0,
          parameterElementName: fieldName,
          value: data.percentage
        })
      )
    }
    if (Array.isArray(value) && value[0]?.[0]) {
      newValue = value[0][0]
    }
    dispatch(
      setRowItemData({
        elementName: element.Name,
        rowIndex: index,
        rowElementName: rowElement.Name,
        parameterIndex: 0,
        parameterElementName: parameterElement.Name,
        value: newValue
      })
    )
    expandParameterComponent(
      value,
      index,
      parameterElement,
      rowElementIndex,
      rowElement
    )
  }

  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  const sortRow = (index: number, direction: 'up' | 'down') => {
    const map = {
      up: -1,
      down: 1
    }
    const sortableRows = state.data[element.Name]
    const adjacentIndex = index + map[direction]
    ;[sortableRows[index], sortableRows[adjacentIndex]] = [
      sortableRows[adjacentIndex],
      sortableRows[index]
    ]
    dispatch(
      setFormElementItemData({
        name: element.Name,
        data: JSON.parse(JSON.stringify(sortableRows))
      })
    )
  }

  return (
    <FormWrapper>
      {element.Label && <Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        {state.elements[element.Name]?.MultipleRows?.map(
          (row: AnyObject, index: number) => (
            <div
              key={`${row.index}-${index}`}
              className={`multiple-${element.Display}`}
            >
              {row.elements
                .filter(
                  (rowElement: any) =>
                    (rowElement.renderConditionally &&
                      (('MediaOnly' in rowElement &&
                        formState.data.Category === 'Media') ||
                        ('NonMediaOnly' in rowElement &&
                          formState.data.Category !== 'Media'))) ||
                    !rowElement.renderConditionally
                )
                .map((rowElement: any, rowElementIndex: number) => (
                  <div
                    key={`${rowElement.Key}-${row.index}`}
                    style={{
                      width: rowElement.Width ? `${rowElement.Width}px` : 'auto'
                    }}
                  >
                    {rowElement.Type === 'parameters' ? (
                      rowElement.Elements.map(
                        (parameterElement: any, parameterIndex: number) => (
                          <div
                            key={`${parameterElement.Key}-${parameterIndex}-${row.index}`}
                          >
                            <FormItem
                              key={`${parameterElement.Key}-${parameterIndex}-${row.index}`}
                              element={{
                                ...parameterElement,
                                SelectDisabled:
                                  parameterElement.copyInitialValues,
                                Default: parameterElement.Default
                              }}
                              data={
                                parameterElement.Label === 'Product'
                                  ? category
                                  : undefined
                              }
                              value={
                                rowValue?.[element.Name]?.[index]?.[
                                  rowElement.Name
                                ]?.[0]?.[parameterElement.Name]
                              }
                              rate={
                                rowValue?.[element.Name]?.[index]?.[
                                  rowElement.Name
                                ]?.[0]?.[
                                  getParameterPercentFieldName(
                                    parameterElement.Name
                                  )
                                ]
                              }
                              baseValue={
                                rowValue?.[element.Name]?.[index]?.[
                                  parameterElement.BaseValue
                                ]
                                  ? +rowValue?.[element.Name]?.[index]?.[
                                      parameterElement.BaseValue
                                    ]
                                  : 0
                              }
                              onChange={(value: OnChangeValueType, data: any) =>
                                handleParameterChange(
                                  value,
                                  data,
                                  index,
                                  rowElement,
                                  parameterElement,
                                  parameterIndex,
                                  rowElementIndex
                                )
                              }
                              split={parameterElement.Split}
                              IsValueBaseOnEdit={true}
                            />
                          </div>
                        )
                      )
                    ) : (
                      <FormItem
                        key={`${rowElement.Key}-${row.index}`}
                        data={
                          element.Label === 'Product' ? category : undefined
                        }
                        element={rowElement}
                        value={
                          rowValue?.[element.Name]?.[index]?.[
                            rowElement.Name
                          ] || ''
                        }
                        onChange={(value: OnChangeValueType, data: any) =>
                          handleRowChange(value, data, index, rowElement)
                        }
                      />
                    )}
                  </div>
                ))}

              <div>
                {element.DeleteLabel && (
                  <Form.Label>{element.DeleteLabel}</Form.Label>
                )}
                {element.DeleteIcon ? (
                  <div className="d-flex">
                    <div className="me-3">
                      {element.sortLabel && (
                        <Form.Label>{element.sortLabel}</Form.Label>
                      )}
                      <div className="d-flex">
                        {(index || null) && (
                          <div className="h-100 cursor-pointer text-info">
                            <ArrowUp
                              name="SortUp"
                              size={16}
                              onClick={() => sortRow(index, 'up')}
                            />
                          </div>
                        )}
                        {index + 1 !==
                          state.elements[element.Name]?.MultipleRows
                            ?.length && (
                          <div className="h-100 cursor-pointer text-info">
                            <ArrowDown
                              name="SortDown"
                              size={16}
                              onClick={() => sortRow(index, 'down')}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="h-100 cursor-pointer text-danger">
                      <Trash
                        name="Delete"
                        size={16}
                        onClick={() => deleteRowWithData(index)}
                      />
                    </div>
                  </div>
                ) : (
                  <RippleButton
                    className="mb-3"
                    variant={element.DeleteVariant}
                    onClick={() => deleteRowWithData(index)}
                  >
                    {element.Delete}
                  </RippleButton>
                )}
              </div>
            </div>
          )
        )}
        <RippleButton onClick={() => addRow()} variant="success">
          Add
        </RippleButton>
      </Col>
    </FormWrapper>
  )
}

export default Multiple
