import { useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useParams, useLocation } from 'react-router-dom'
import ReactSelect, { MultiValue, SingleValue } from 'react-select'
import { postData } from '../../../../../api'
import { AnyObject } from '../../../../../types/common'
import { Option } from '../../../../../types/formElement'
import { IdParams } from '../../../../../types/params'
import { responsiveCol } from '../../../../../utils/conversions'
import { errorToast } from '../../../../../utils/toast'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import { FormItemProps } from '../../layout/FormItem/types'
import CustomOptions from './CustomOptions'

const Select = ({
  data,
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const [options, setOptions] = useState<any>(
    element.Source && element.Source !== 'Inline'
      ? element.Options || []
      : element.DefaultOptions || []
  )
  const [selectedOption, setSelectedOption] = useState<
    SingleValue<Option> | MultiValue<Option>
  >(value)
  const [hasOptions, setHasOptions] = useState(false)
  // const [prevValue, setPrevValue] = useState(value)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const [optionsResponse, setOptionsResponse] = useState<any>()
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const { id } = useParams<IdParams>()

  useEffect(() => {
    const getOptionData = async () => {
      setIsLoading(true)
      let getData = false
      const path =
        element?.Source && element.Source !== 'Inline' ? element.Source : ''
      const body: AnyObject = {}
      if (element?.SourceRequestBody?.length) {
        element.SourceRequestBody.forEach(([reqBodyKey, reqBodyValue]) => {
          if (
            reqBodyValue !== 'this' &&
            reqBodyValue !== 'routeId' &&
            !Array.isArray(reqBodyValue)
          ) {
            body[reqBodyKey] = reqBodyValue || ''
            getData = true
          } else if (Array.isArray(reqBodyValue)) {
            if (
              data?.[reqBodyValue[0]] &&
              !optionsResponse &&
              !options.length
            ) {
              body[reqBodyKey] = data?.[reqBodyValue[0]] || 'Media'
              getData = true
            } else {
              getData = false
            }
          } else if (reqBodyValue === 'routeId') {
            body[reqBodyKey] = id
            getData = !options?.length
          }
        })
      } else if (!optionsResponse) {
        getData = true
      }
      if (getData) {
        try {
          const response = await postData({ path, body })
          setOptionsResponse(response)
        } catch (err) {
          setError(err)
          setSelectedOption(null)
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }
    if (element.Source) {
      if (element.Source !== 'Inline') {
        getOptionData()
      } else {
        const elementOptions: Option[] = []
        element.Options?.forEach((option: string, index: number) => {
          elementOptions.push({
            label: option,
            value: element?.Values?.[index] || option
          })
        })
        setOptions(elementOptions)
      }
    }
  }, [element.Name, data])

  useEffect(() => {
    ;((element.Source && element.Source !== 'Inline') || !element.Source) &&
      element.Options?.length &&
      setOptions(element.Options)
  }, [element.Options])

  useEffect(() => {
    const getValueSourceData = async () => {
      const response = await postData({
        path: element.ValueSource || '',
        body: { Id: id }
      })
      const data = options.filter((option: Option) =>
        response.data.find((res: AnyObject) => res.Id === option.value)
      )
      onChange &&
        onChange([[data.map((val: Option) => val.value)]], 'multiselect')
    }
    if (hasOptions && element.ValueSource) {
      getValueSourceData()
      setHasOptions(false)
    }
    !options.length && !hasOptions && setHasOptions(true)
  }, [options])

  useEffect(() => {
    element.Disabled && setSelectedOption(null)
  }, [element.Disabled])

  useEffect(() => {
    error && errorToast('Error loading options', `error-${element.Source}`)
  }, [error])

  const handleSelectChange = useCallback(
    (changeValue: SingleValue<Option> | MultiValue<Option>) => {
      if (onChange && changeValue) {
        if ('value' in changeValue) {
          const selectedValue = element.FillValue
            ? changeValue[element.FillValue]
            : changeValue.value
          const selectedData = element.PassOption
            ? changeValue
            : element.OnChangeFill
            ? {
                onChangeFill: {
                  values: changeValue,
                  fillItems: element.OnChangeFill
                }
              }
            : null
          onChange(selectedValue, selectedData)
        } else {
          const isDisabled = changeValue.length >= (element.SelectLimit || 1)
          setOptions((currentOptions: Option[]) =>
            currentOptions.map((optionItem: Option) => ({
              ...optionItem,
              isDisabled
            }))
          )
          onChange([[changeValue.map(val => val.value)]], {
            isMultiselect: true
          })
        }
        setSelectedOption(changeValue)
      }
    },
    []
  )

  useEffect(() => {
    if (value && options.length) {
      let fillSelectedOption = []
      if (element.IsMulti) {
        fillSelectedOption = options.filter(
          (option: Option) =>
            value.includes(option.value) || value.includes(option.label)
          // value?.[0]?.[0]?.includes(option.value) ||
          // value?.[0]?.[0]?.includes(option.label)
        )
      } else {
        fillSelectedOption = options.find(
          (option: Option) => option.label === value || option.value === value
        )
      }
      if (typeof value === 'string') {
        handleSelectChange(fillSelectedOption)
      }
      fillSelectedOption && setSelectedOption(fillSelectedOption)
    }
  }, [value, options])

  useEffect(() => {
    const optionData = optionsResponse?.data
    const jobCode = searchParams.get('jobCode')
    Array.isArray(optionData) &&
      setOptions(
        optionData.map(opt => {
          const label = opt[element.OptionLabel || 'Name'] || opt.label
          const optObject = {
            value: opt._id || opt.Id,
            label
          }
          if (label === jobCode) {
            jobCode && handleSelectChange([optObject])
          }
          return optObject
        })
      )
  }, [optionsResponse])

  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  return (
    <FormWrapper>
      <Label {...labelProps} />
      <Col xs={12} md={responsiveCol(split[1])}>
        {element.Disabled ? (
          <Form.Control
            key={element.Key}
            name={element.Name}
            placeholder={element.Placeholder || 'Select...'}
            value=""
            disabled={element.Disabled}
          />
        ) : (
          <ReactSelect
            className="react-select"
            key={element.Key}
            aria-label="Dynamic Select"
            value={selectedOption}
            onChange={handleSelectChange}
            options={options}
            isLoading={isLoading}
            isDisabled={element.SelectDisabled}
            isSearchable={options.length}
            isMulti={element.IsMulti}
            components={{ Option: CustomOptions }}
            isOptionDisabled={option =>
              (!!element.IsMulti &&
                Array.isArray(selectedOption) &&
                selectedOption?.length >= (element.SelectLimit || 1)) ||
              ('isDisabled' in option && !!option.isDisabled)
            }
          ></ReactSelect>
        )}
      </Col>
    </FormWrapper>
  )
}

export default Select
