import classNames from 'classnames'
import { Edit, Key, Trash } from 'react-feather'
import { IconProps } from './types'

const TableIcon = ({ name }: IconProps) => {
  const tableIconClass = (variant: string) =>
    classNames('me-2', 'table-row-item__icon', `text-${variant}`)
  if (name === 'Edit') {
    return <Edit className={tableIconClass('success')} />
  } else if (name === 'Delete') {
    return <Trash className={tableIconClass('danger')} />
  } else if (name === 'Access') {
    return <Key className={tableIconClass('pointer')} />
  }
  return null
}

export default TableIcon
