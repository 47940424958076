import { SyntheticEvent, useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router-dom'

import DOMPurifier from '../../components/DOMPurifier'
import Editor from '../../components/Editor'
import CardTitle from '../../../common/Title/CardTitle'
import { PageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'
import RippleButton from '../../../common/RippleButton'

import { useGetData, usePostData } from '../../../../../hooks/reactQuery'
import { errorMessageFromError } from '../../../../../utils/dynamicData'
import { errorToast } from '../../../../../utils/toast'

import { ChatProps } from './types'
import { IdParams } from '../../../../../types/params'
import { AnyObject } from '../../../../../types/common'

const Chat = ({ content }: ChatProps) => {
  const [chat, setChat] = useState('')
  const { id } = useParams<IdParams>()

  const uniqueKey = `${content.Path}-${id}`
  const apiParams = { path: content.Path, body: { Id: id } }
  const {
    data: response,
    error,
    isLoading,
    refetch: refetchChat
  } = useGetData(uniqueKey, apiParams, true, true, 0, 5 * 60 * 1000, 5 * 1000)

  const {
    error: saveChatError,
    isLoading: saveChatLoading,
    isSuccess: saveChatSuccess,
    isError: isSaveChatError,
    mutate: saveChat
  } = usePostData()

  useEffect(() => {
    saveChatError && errorToast(saveChatError, 'save-chat-error')
  }, [isSaveChatError])

  useEffect(() => {
    // * Refetch chats when saved
    saveChatSuccess && refetchChat()
  }, [saveChatSuccess])

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    if (chat) {
      const body = { Id: id, Message: chat }
      const path = content.Action
      saveChat({ path, body })
    }
  }

  if (isLoading) {
    return (
      <Card>
        <CardTitle title={content.Title} />
        <PageLoading height="20vh" />
      </Card>
    )
  }

  if (error) {
    const errorMessage = errorMessageFromError(error as AnyObject)
    return (
      <Card>
        <CardTitle title={content.Title} />
        <PageCenter height="18vh">{errorMessage}</PageCenter>
      </Card>
    )
  }

  if (response) {
    const chatData: AnyObject[] = response.data
    return (
      <Card>
        <CardTitle title={content.Title} />
        {/* // * Previous conversations */}
        {chatData?.map(chat => (
          <div key={chat._id} className="my-3">
            <h3 className="fs-6">
              <strong>{chat.Name}</strong>
            </h3>
            {/* // * Sanitize html coming from API */}
            <DOMPurifier html={chat.Message} />
          </div>
        ))}
        {/* // * Chat Editor */}
        <Form onSubmit={handleSubmit}>
          <Editor value={chat} onChange={(html: string) => setChat(html)} />
          <RippleButton
            className="my-3 position-relative"
            type="submit"
            disabled={!chat}
            isLoading={saveChatLoading}
          >
            Send
          </RippleButton>
        </Form>
      </Card>
    )
  }

  return null
}

export default Chat
