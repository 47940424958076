import ResetPassword from '../../views/pages/Auth/ForgotPassword'
import Login from '../../views/pages/Auth/Login'
import DynamicPage from '../../views/pages/Dynamic'

import { defaultFallbackPath, defaultRoute } from '../../constants/routes'

import { ProtectedRouteType } from '../types'
import { SidebarItems } from '../../types/navbar'

// * Build Routes => Static, Dynamic, Other
export const setRoutePaths = (items?: SidebarItems) => {
  const isAuthenticated = !!items

  // * Props for authentication pages
  const authPageProps = {
    showRoute: !isAuthenticated,
    fallbackPath: defaultRoute
  }

  // * Props for guarded pages
  const guardProps = {
    showRoute: isAuthenticated,
    fallbackPath: defaultFallbackPath
  }

  // * Static Routes
  const staticRoutes: ProtectedRouteType[] = [
    {
      path: '/login',
      component: Login,
      ...authPageProps
    },
    {
      path: '/',
      component: DynamicPage,
      redirectPath: defaultRoute,
      ...guardProps
    }
  ]

  let dynamicRoutes: ProtectedRouteType[] = []

  // * Individual dynamic route creator function
  const createDynamicRoute = (path: string) => ({
    path,
    component: DynamicPage,
    ...guardProps
  })

  // * Multiple dynamic route creator for a module-subModule path
  const addToDynamicRoute = (path: string) => {
    const newPath = path.split(' ').join('')
    dynamicRoutes = [
      ...dynamicRoutes,
      createDynamicRoute(newPath),
      createDynamicRoute(`${newPath}/Create`),
      createDynamicRoute(`${newPath}/Edit/:id`),
      createDynamicRoute(`${newPath}/:id/Print`),
      createDynamicRoute(`${newPath}/:id`)
    ]
  }

  // * Dynamic Form Builder - Main Function
  if (items?.Main?.length) {
    items.Main.forEach(mainPath => {
      if (items.Sub?.[mainPath]?.length) {
        items.Sub[mainPath].forEach(subPath =>
          addToDynamicRoute(`/${mainPath}/${subPath}`)
        )
      } else {
        addToDynamicRoute(`/${mainPath}`)
      }
    })
  }

  // * Redirect to default Route for other routes
  const otherRoutes = [
    {
      path: '/reset-password',
      component: ResetPassword,
      ...guardProps
    },
    {
      path: '*',
      component: DynamicPage,
      redirectPath: defaultRoute,
      ...guardProps
    }
  ]

  return { staticRoutes, dynamicRoutes, otherRoutes }
}

// * Get the path of URL from routes
export const getPathsFromRoute = (routes: ProtectedRouteType[]) =>
  routes.map(({ path }) => path) as readonly string[]
