import Card from 'react-bootstrap/Card'

import { PageCenter } from '../../../common/PageCenter'
import RippleButton from '../../../common/RippleButton'
import CardTitle from '../../../common/Title/CardTitle'

import { FolderProps } from './types'

// *TODO File Uploading
const Folder = ({ content }: FolderProps) => (
  <Card>
    <CardTitle title={content.Title} />
    <PageCenter height="12vh">No uploads found</PageCenter>
    <div className="my-3">
      <RippleButton disabled>Upload Files</RippleButton>
    </div>
  </Card>
)

export default Folder
