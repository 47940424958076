import { AnyObject } from '../../../../../../types/common'
import {
  COUNT,
  CURRENT_PAGE,
  ERROR,
  LOADING,
  SEARCH,
  TABLE_DATA,
  TABLE_HEAD_LIST
} from './actionTypes'
import {
  CountAction,
  CurrentPageAction,
  ErrorAction,
  LoadingAction,
  SearchAction,
  TableDataAction,
  TableHeadAction
} from './types'

export const setCount = (payload: number): CountAction => ({
  type: COUNT,
  payload
})

export const setCurrentPage = (payload: number): CurrentPageAction => ({
  type: CURRENT_PAGE,
  payload
})

export const setError = (payload: string): ErrorAction => ({
  type: ERROR,
  payload
})

export const setLoading = (payload: boolean): LoadingAction => ({
  type: LOADING,
  payload
})

export const setSearch = (payload: string): SearchAction => ({
  type: SEARCH,
  payload
})

export const setTableData = (payload: AnyObject[]): TableDataAction => ({
  type: TABLE_DATA,
  payload
})

export const setTableHead = (payload: string[]): TableHeadAction => ({
  type: TABLE_HEAD_LIST,
  payload
})
