import { MAIN_DATA, REFETCH_DATA } from './actionTypes'
import { ViewDetailsReducer } from './types'

export const initialState: ViewDetailsReducer = {
  mainData: {},
  refetch: false
}

// *TODO : Add type to action parameter
export const viewDetailsReducer = (
  state: ViewDetailsReducer = initialState,
  action: any
) => {
  switch (action.type) {
    case MAIN_DATA:
      return { ...state, mainData: action.payload }
    case REFETCH_DATA:
      return { ...state, refetch: action.payload }
    default:
      return state
  }
}
