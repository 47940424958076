import { useContext } from 'react'
import AccessContext from '../../../../../context/AccessContext'
import { AccessType } from '../../../../../types/button'
import RippleButton from '../../../common/RippleButton'
import { ActionButtonProps } from './types'

const ActionButton = ({ buttons, handleClick }: ActionButtonProps) => {
  const access = useContext(AccessContext)

  // * Filter action buttons in list/forms according to user authorization
  const filteredButtons = buttons.filter(({ Access }) =>
    // * Get Access of layout from Context
    Access.includes(access as AccessType)
  )
  return (
    <>
      {filteredButtons.map(({ Button, ...rest }) => (
        <RippleButton
          key={Button}
          className="my-3"
          onClick={event => handleClick(rest, event)}
        >
          {Button}
        </RippleButton>
      ))}
    </>
  )
}

export default ActionButton
