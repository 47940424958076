import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { SidebarLinkProps } from './types'

export const SidebarListItem = styled.li`
  white-space: no-wrap;
`

export const SidebarLink = styled(NavLink)<SidebarLinkProps>`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 1.0714rem;
  padding: 0.714rem 1.0714rem;
  line-height: 1.45;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--body-color);

  &::after {
    content: '';
    height: 1.1rem;
    width: 1.1rem;
    display: ${props => (props.$showIcon ? 'inline-block' : 'none')};
    position: absolute;
    top: 1rem;
    right: 0;
    transition: all 0.2s ease-out;
    transform: rotate(${props => (props.$isOpen ? 90 : 0)}deg);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.1rem;
  }

  & > * {
    transition: transform 0.25s ease;
  }
`
