import { toast } from 'react-toastify'
import { defaultErrorMessage } from '../constants/api'

export const errorToast = (err: any, toastId: string) => {
  if (err?.message !== 'Route Change') {
    let message
    if (err?.response?.status === 500) {
      message = defaultErrorMessage
    } else {
      message = err?.response?.data || err?.message || defaultErrorMessage
    }
    toast.error(message, { toastId })
  }
}

export const saveToast = (name: string, toastId: string) => {
  toast.success(`${name} Saved Successfully`, { toastId })
}
