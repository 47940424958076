import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import ForgotPsswordForm from './components/ForgotPasswordForm'

// import Logo from '../../../components/common/Logo'
import Image from '../../../components/common/Image'

import { AuthCard, AuthInner } from '../../../styles/v2'
import { CardText, CardTitle } from '../../../styles/common'

import ForgotPasswordImage from '../../../../assets/images/auth/forgot-password.svg'

const ForgotPassword = () => (
  <Container fluid>
    {/* // * Logo Section */}
    <AuthInner>
      {/* <AuthLogo>
        <Logo />
      </AuthLogo> */}
      {/* // * Image Section */}
      <Col lg={8} className="d-none d-lg-flex align-items-center p-5">
        <div className="w-100 d-lg-flex justify-content-center align-items-center px-5">
          <Image src={ForgotPasswordImage} alt="Reset Password" />
        </div>
      </Col>
      {/* // * Form Card Section */}
      <Col
        lg={4}
        className="d-flex justify-content-center align-items-center px-2 p-lg-5"
      >
        <AuthCard>
          <CardTitle>Reset Password</CardTitle>
          <CardText>Enter your new password</CardText>
          {/* // * Form Section */}
          <ForgotPsswordForm />
        </AuthCard>
      </Col>
    </AuthInner>
  </Container>
)

export default ForgotPassword
