import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import ProtectedRoute from './routes/ProtectedRoutes'

import { selectLoading, selectRoutes } from '../store/global/selector'

import { getPathsFromRoute } from './routes'
import VerticalMenu from '../views/layout/VerticalMenu'
import PageLoading from '../views/components/common/PageLoading'

const Router = () => {
  const routes = useSelector(selectRoutes)
  const appLoading = useSelector(selectLoading)

  const { staticRoutes = [], dynamicRoutes = [], otherRoutes = [] } = routes
  const nonStaticRoutes = [...dynamicRoutes, ...otherRoutes]

  return (
    <Switch>
      {/* // * Static Routes */}
      <Route exact path={getPathsFromRoute(staticRoutes)}>
        <Switch>
          {staticRoutes.map(routeProps => (
            <ProtectedRoute key={routeProps.path} {...routeProps} exact />
          ))}
        </Switch>
      </Route>

      {/* // * Dynamic and Other Routes */}
      <Route exact path={getPathsFromRoute(nonStaticRoutes)}>
        {appLoading ? (
          <PageLoading height="100vh" />
        ) : (
          <VerticalMenu>
            <Switch>
              {nonStaticRoutes.map(routeProps => (
                <ProtectedRoute key={routeProps.path} {...routeProps} exact />
              ))}
            </Switch>
          </VerticalMenu>
        )}
      </Route>
    </Switch>
  )
}

export default Router
