import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { zIndexMap } from '../../../../styles/styled/zIndexMap'

export const MainMenu = styled.aside`
  width: 18.5714rem;
  transform: translate3d(0, 0, 0);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
  backface-visibility: hidden;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  background: #fff;
  position: fixed;
  color: #6e6b7b;
  z-index: ${zIndexMap.mainSidebar};
  height: 100%;
  overflow: hidden;
`
export const SidebarHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 4.45rem;
  padding: 0.35rem 1rem 0.3rem 1.64rem;
  transition: 300ms ease all, background 0s;

  &::after {
  }
`
export const SidebarHeaderList = styled.ul`
  margin: 0;
  padding: 0;
  border-radius: 0.25rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`
export const SidebarHeaderShadow = styled.div`
  display: none;
  position: absolute;
  z-index: ${zIndexMap.mainSidebarShadow};
  height: 50px;
  width: 100%;
  pointer-events: none;
  filter: blur(5px);
  margin-top: -0.7rem;
  background: linear-gradient(
    #ffffff 41%,
    rgba(255, 255, 255, 0.11) 95%,
    rgba(255, 255, 255, 0)
  );
`
export const Scrollbar = styled(PerfectScrollbar)`
  height: calc(100% - 4.45rem);
  position: relative;
`
export const SidebarNavigation = styled.ul`
  padding: 0 0 0.75rem 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  font-size: 1.1rem;
  li:first-child {
    margin-top: 0.5rem;
  }
  transition: height 1s ease-in-out;
`
