import { APP_LOADING, ROUTES } from './actionTypes'
import { RoutePaths } from './types'

export const setRoutes = (payload: RoutePaths) => ({
  type: ROUTES,
  payload
})

export const setAppLoading = (payload: boolean) => ({
  type: APP_LOADING,
  payload
})
