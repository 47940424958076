import { useEffect } from 'react'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ChevronLeft } from 'react-feather'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import AccessContext from '../../../context/AccessContext'

import DynamicLayout from '../../components/dynamic/layout/Container'
import PageLoading from '../../components/common/PageLoading'
import { PageCenter } from '../../components/common/PageCenter'
import Title from '../../components/common/Title'
import Image from '../../components/common/Image'
import Maintenance from '../../../assets/images/misc/maintenance.png'

import { ROUTE_CHANGE } from '../../../constants/api'
import { useGetData } from '../../../hooks/reactQuery'
import {
  errorMessageFromError,
  pageDataFromPathName
} from '../../../utils/dynamicData'
import { finishPendingRequests } from '../../../api'

import { IdParams } from '../../../types/params'
import { AnyObject } from '../../../types/common'

const DynamicPage = () => {
  const { pathname } = useLocation()
  const { id } = useParams<IdParams>()
  const { goBack } = useHistory()

  // * Create details for get Data from API (useGetData)
  const uniqueKey = `${pathname}${id || ''}`
  const pageData = pageDataFromPathName(pathname, id)
  const path = pageData.url
  const body = id ? { Id: id } : {}

  // * Setting stale time of 5 minutes (300000 milliseconds) for this query
  // ? This query will fetch details again only after 5 minutes
  const {
    data: response,
    isLoading,
    error
  } = useGetData(uniqueKey, { path, body }, true, false, 300000)

  // * Cancel all queries if this component unmounts while URL changes
  useEffect(
    () => () => {
      finishPendingRequests(ROUTE_CHANGE)
    },
    [location?.pathname]
  )

  if (isLoading) {
    return <PageLoading />
  }

  if (error) {
    const errorText = errorMessageFromError(error as AnyObject)
    return <PageCenter>{errorText}</PageCenter>
  }

  if (response?.data) {
    const responseData = response.data
    if (responseData?.Layout) {
      const pageType = pageData.type
      // * Show back icons for Create, Edit and View pages only
      const showBackIcon =
        pageType === 'create' || pageType === 'edit' || pageType === 'view'
      return (
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Title>
                <div className="d-flex align-items-center ps-4">
                  {showBackIcon && (
                    <ChevronLeft
                      className="cursor-pointer"
                      onClick={() => goBack()}
                    />
                  )}
                  {/* // * Show title of the layout section */}
                  {responseData?.Title || ''}
                </div>
              </Title>
            </Col>
            <AccessContext.Provider value={responseData?.Access || ''}>
              {/* // * Render Layout */}
              <DynamicLayout layout={responseData?.Layout} />
            </AccessContext.Provider>
          </Row>
        </Container>
      )
    }

    // * Render Maintenance Image if Layout is not in response even if response is success
    return (
      <PageCenter>
        <Image src={Maintenance} alt="maintenance" />
      </PageCenter>
    )
  }

  return null
}

export default DynamicPage
