import ListGroup from 'react-bootstrap/ListGroup'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router-dom'
import Select, { SingleValue } from 'react-select'
import { postData } from '../../../../../api'
import { useGetData } from '../../../../../hooks/reactQuery'
import { AnyObject } from '../../../../../types/common'
import { Option } from '../../../../../types/formElement'
import { IdParams } from '../../../../../types/params'
import { errorToast, saveToast } from '../../../../../utils/toast'
import { PageCenter } from '../../../common/PageCenter'
import PageLoading from '../../../common/PageLoading'
import { TimelineModalBodyProps } from './types'
import { useEffect, useRef, useState } from 'react'
import Editor from '../../components/Editor'
import { defaultErrorMessage } from '../../../../../constants/api'
import RippleButton from '../../../common/RippleButton'

const TimelineModal = ({ selectedItem, hideModal }: TimelineModalBodyProps) => {
  const { id } = useParams<IdParams>()
  const [selectedValue, setSelectedValue] = useState<SingleValue<Option>>()
  const [particular, setParticular] = useState('')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const optionsPath =
    selectedItem?.Source?.replace(
      '{submodule}',
      selectedItem.Data?.Product || ''
    ) || ''
  const optionsUniqueKey = `${optionsPath}-${id}`
  const optionsBody = { Id: id }
  // * Options in modal/select
  const {
    data: optionResponse,
    error: optionsError,
    isLoading: optionsLoading
  } = useGetData(
    optionsUniqueKey,
    {
      path: optionsPath,
      body: optionsBody
    },
    !!selectedItem?.Source
  )

  const particularPath = selectedItem?.Particular || ''
  const particularUniqueKey = `${particularPath}-${id}`
  const particularBody = { Id: id }
  // * Get saved textarea content
  const {
    data: particularResponse,
    error: particularError,
    isLoading: particularLoading
  } = useGetData(
    particularUniqueKey,
    {
      path: particularPath,
      body: particularBody
    },
    !!selectedItem?.Particular
  )
  const isSubmitting = useRef(false)

  useEffect(() => {
    // * Update text area content got from API call to state
    particularResponse && setParticular(particularResponse.data?.Content || '')
  }, [particularResponse])

  const handleModalSubmit = async (data: any) => {
    if (!isSubmitting.current) {
      try {
        isSubmitting.current = true
        if (selectedItem?.Name && selectedItem?.Path) {
          setIsButtonLoading(true)
          if (selectedItem.Action === 'SendForApproval') {
            // * Save data
            await postData({
              path: selectedItem?.Path,
              body: {
                Id: id,
                [selectedItem.Name]: data?.value
              }
            })
          } else if (selectedItem.Action === 'ChangeDepartment') {
            // * Save data
            await postData({
              path: selectedItem?.Path,
              body: {
                Id: id,
                Department: selectedValue?.value,
                Particular: particular
              }
            })
          } else if (selectedItem.Action === 'Approve') {
            // * Save data
            await postData({
              path: selectedItem?.Path,
              body: {
                Id: id,
                ApprovedEstimate: data?.value,
                Action: 'Approve'
              }
            })
          }
          // * Show success toast
          saveToast(selectedItem?.Action, `${selectedItem?.Action}-${id}`)
          hideModal(true)
        }
      } catch (err: any) {
        errorToast(err, `err-${selectedItem?.Action}-${id}`)
      } finally {
        setIsButtonLoading(false)
        hideModal()
        isSubmitting.current = true
      }
    }
  }

  if (optionsLoading || particularLoading) {
    return <PageLoading height="15vh" />
  }

  if (optionsError || particularError) {
    return <PageCenter>{defaultErrorMessage}</PageCenter>
  }

  const options: Option[] = optionResponse?.data?.map((item: AnyObject) => ({
    label: item.Name,
    value: item.Id
  }))

  if (optionResponse) {
    // * Modal with options
    if (!selectedItem?.Particular) {
      return (
        <>
          <p>Select one of the following:</p>
          <ListGroup variant="flush" className="border">
            {options?.map(data => (
              <ListGroup.Item
                key={data?.value}
                action
                onClick={() => handleModalSubmit(data)}
              >
                {data?.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )
    }
    return null
  }
  // * Modal with select and textarea
  if (selectedItem?.Particular) {
    return (
      <Form>
        {optionResponse && (
          <>
            <Form.Label>{selectedItem.Action}</Form.Label>
            <Select
              className="react-select w-50 mb-3"
              aria-label="Select"
              options={options}
              isLoading={optionsLoading}
              isSearchable={!!options?.length}
              value={selectedValue}
              onChange={(value: SingleValue<Option>) => setSelectedValue(value)}
            />
          </>
        )}
        <Form.Group controlId="particular">
          <Form.Label>Particular</Form.Label>
          <Editor
            value={particular}
            onChange={(html: string) => {
              setParticular(html)
            }}
          />
        </Form.Group>
        <RippleButton
          className="my-3"
          onClick={handleModalSubmit}
          isLoading={isButtonLoading}
        >
          Submit
        </RippleButton>
      </Form>
    )
  }

  return null
}

export default TimelineModal
