import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Logo from '../../../../components/common/Logo'
import Image from '../../../../components/common/Image'

import { CardText, CardTitle } from '../../../../styles/common'
import { AuthCard, AuthInner, AuthLogo } from '../../../../styles/v2'

import LoginImage from '../../../../../assets/images/auth/login.svg'
import LoginForm from './LoginForm'

const Login = () => (
  <Container fluid>
    <AuthInner>
      {/* // * Logo Section - Top Left */}
      <AuthLogo>
        <Logo />
      </AuthLogo>
      {/* // * Image Section - Left */}
      <Col lg={8} className="d-none d-lg-flex align-items-center p-5">
        <div className="w-100 d-lg-flex justify-content-center align-items-center px-5">
          <Image src={LoginImage} alt="meeting" />
        </div>
      </Col>
      {/* // * Card with Form Section - Right */}
      <Col
        lg={4}
        className="d-flex justify-content-center align-items-center px-2 p-lg-5"
      >
        <AuthCard>
          <CardTitle>Welcome to Icorp!</CardTitle>
          <CardText>Please sign-in to your account to continue</CardText>
          <LoginForm />
        </AuthCard>
      </Col>
    </AuthInner>
  </Container>
)

export default Login
