import {
  Document,
  Page,
  StyleSheet,
  Text,
  // View,
  Image,
  View,
  Font
} from '@react-pdf/renderer'
import { dateFormatter, numberFormatter } from '../../utils/format'
import verdanaRegular from '../../assets/fonts/verdana/verdana.woff'
import verdanaBold from '../../assets/fonts/verdana/verdanab.woff'
import robotoRegular from '../../assets/fonts/roboto/Roboto-Regular.woff'
import robotoBold from '../../assets/fonts/roboto/Roboto-Bold.woff'
import weissach from '../../assets/fonts/weisch/Weissach.ttf'
import weissachBold from '../../assets/fonts/weisch/webswfte.ttf'
import swz721lc from '../../assets/fonts/swz721lc/swz721lc.ttf'
import {
  amountToWords,
  removeFalsyValues
  // getStyleObjectFromString
} from '../../utils/stringUtils'
// import { base64Formatter } from '../../utils/format'
import RichText from './richTextToPDF'
import { AnyObject } from '../../types/common'
import moment from 'moment'

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegular }, { src: robotoBold, fontWeight: 700 }]
})

Font.register({
  family: 'Verdana',
  fonts: [{ src: verdanaRegular }, { src: verdanaBold, fontWeight: 700 }]
})

Font.register({
  family: 'Weisch',
  fonts: [{ src: weissach }, { src: weissachBold, fontWeight: 700 }]
})

Font.register({
  family: 'Weisch-Bold',
  fonts: [{ src: weissachBold }]
})

Font.register({
  family: 'media-mate',
  fonts: [{ src: swz721lc }]
})

const NewPDF = ({
  layout,
  logo,
  bwLogo,
  businessInfo,
  data,
  particulars,
  buttonText,
  subTotal,
  setPrintLayout,
  rowTitles,
  description,
  particularElements,
  images
}: any) => {
  const isColor = buttonText.includes('Colour')
  const [themeRed, themeGreen, themeBlue] = isColor
    ? layout?.Layout?.Theme
    : [100, 100, 100]
  // const [footerRed, footerGreen, footerBlue] = layout?.Layout?.Footer?.[0]
  //   ?.FontColor || [0, 0, 0]
  // const footerColor = `rgb(${footerRed}, ${footerGreen}, ${footerBlue})`
  const themeColor = `rgb(${themeRed}, ${themeGreen}, ${themeBlue})`
  const isMedia = !layout?.Layout?.NoCategoryCheck && data.Category === 'Media'
  let signatories = [...layout?.Layout?.Content?.Signatories] || []
  if (isMedia) {
    signatories = [
      ...layout?.Layout?.Content?.Signatories,
      { Type: 'text', Value: 'ME' }
    ]
  }

  const styles = StyleSheet.create({
    logo: {
      height: `${logo.Height || 20.5}mm`,
      ...(layout.Layout?.Logo?.Style || {})
    },
    pageMargin: {
      marginLeft: '16mm',
      marginRight: '16mm'
    },
    pageContent: {
      marginLeft: '16mm',
      marginRight: '16mm',
      paddingTop: '20mm',
      fontSize: 8
    },
    headerRows: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '2.5mm'
    },
    headerColumns: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  })

  const getElementData = async (element: AnyObject, data: AnyObject) => {
    const body: AnyObject = {}
    if (element.PathRequestKey) {
      element.PathRequestKey.forEach((keyData: string[]) => {
        body[keyData[0]] = data[keyData[1]]
      })
    }
    particularElements.forEach((element: AnyObject, index: number) => {
      element?.Type === 'product' &&
        rowTitles.length &&
        particularElements.splice(index, 1, ...rowTitles)
    })
    const indices: number[] = []
    if (
      particularElements.filter((element: AnyObject, index: number) => {
        if (element.Key === 'Particular') {
          indices.push(index)
          return true
        }
        return false
      })?.length > 1
    ) {
      indices.pop()
      indices.forEach(index => {
        particularElements.splice(index, 1)
      })
    }
    setPrintLayout(layout)
  }

  const headerRowText = (child: any, index: number = 0) => {
    switch (child?.Type) {
      case 'text':
        return (
          <Text style={child.Style || {}} key={`${child.Value}-${index}`}>
            {removeFalsyValues(child.Value)}
          </Text>
        )
      case 'array':
        return (
          <>
            {child.Value?.map((element: any, childIndex: number) => (
              <View key={childIndex} style={child.Style || {}}>
                <Text>{removeFalsyValues(element)}</Text>
              </View>
            ))}
          </>
        )
      case 'richText':
        if (child.Value) {
          return (
            <View style={child.Style || {}} key={`${child.Key}-${index}`}>
              <RichText note={removeFalsyValues(child.Value)} />
            </View>
          )
        }
        if (data?.[child.Key]) {
          return (
            <View style={child.Style || {}} key={`${child.Key}-${index}`}>
              <RichText note={removeFalsyValues(data?.[child.Key])} />
            </View>
          )
        }
        return null
      case 'date':
        return (
          <Text style={child.Style || {}} key={`${child.Key}-${index}`}>
            {data?.[child.Key]
              ? dateFormatter(data?.[child.Key], 'DD/MM/YYYY')
              : null}
          </Text>
        )
      case 'data': {
        if (Array.isArray(child.Key)) {
          return (
            <View
              style={{ display: 'flex', flexDirection: 'column' }}
              key={`${child.Key}-${index}`}
            >
              {child.Key?.map((innerKey: any, index: any) => (
                <Text key={`${index}`} style={child.Style || {}}>
                  {removeFalsyValues(data?.[innerKey])}
                </Text>
              ))}
            </View>
          )
        } else {
          return (
            <Text style={child.Style || {}} key={`${child.Key}-${index}`}>
              {removeFalsyValues(data?.[child.Key])}
            </Text>
          )
        }
      }
      case 'numberInWords': {
        let children = ''
        if (child.Key === 'total') {
          const total = subTotal[subTotal.length - 1]?.Amount
          const totalInNumber = +total
          children =
            total && !isNaN(totalInNumber)
              ? amountToWords
                  .convert(totalInNumber)
                  .replace('Rupees', data?.Currency || 'Rupees')
              : ''
        }
        if (children) {
          return (
            <Text style={child.Style || {}} key={`${child.Key}-${index}`}>
              {child.Prefix}: {children}
            </Text>
          )
        }
        return null
      }
      default:
        return null
    }
  }

  const remarks = layout?.Layout?.Remarks && (
    <View
      style={{
        paddingTop: '5mm',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <View>
        {layout.Layout.Remarks?.map((item: any, itemIndex: any) => (
          <>
            {item.Label && <Text>{item.Label} </Text>}
            <View key={itemIndex}>{headerRowText(item, itemIndex)}</View>
          </>
        ))}
      </View>
    </View>
  )

  return (
    <Document>
      <Page
        orientation={isMedia ? 'landscape' : layout?.Layout?.Orientation}
        style={{
          ...layout?.Layout?.PageStyle,
          ...(isColor ? layout?.Layout?.ColorPageStyle : {}),
          position: 'relative',
          paddingBottom: '45px'
        }}
      >
        {buttonText.includes('WITHOUT LetterHead') ? (
          <View style={{ height: `${(logo?.Height || 20.5) + 13}mm` }}>
            <Text>{''}</Text>
          </View>
        ) : (
          <View fixed>
            {logo.format && bwLogo.format ? (
              isColor ? (
                <View>
                  <Image
                    src={`data:${logo.format};base64,${logo.data}`}
                    style={styles.logo}
                  />
                </View>
              ) : (
                <View>
                  <Image
                    src={`data:${bwLogo.format};base64,${bwLogo.data}`}
                    style={styles.logo}
                  />
                </View>
              )
            ) : null}
            <View fixed>
              {images?.filter(Boolean)?.map((image: AnyObject) => (
                <>
                  <Image
                    key={image.data}
                    src={`data:${image?.format};base64,${image?.data}`}
                    style={{
                      width: '4mm',
                      height: '80mm',
                      position: 'absolute',
                      top: '-30mm',
                      left: '5mm'
                    }}
                  />
                </>
              ))}
            </View>
            {layout?.Layout?.LHData?.map((element: any, index: number) => {
              return (
                <Text
                  style={[styles.pageMargin, element.Style || {}]}
                  key={`${element.Element}-${index}`}
                >
                  {businessInfo?.[element.Element]}
                </Text>
              )
            })}
          </View>
        )}
        <View style={[styles.pageContent, layout.Layout?.PageContent?.Style]}>
          <Text style={layout.Layout?.PageTitle?.Style || {}}>
            {layout.Title}
          </Text>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              paddingBottom: '1mm'
            }}
          >
            {layout?.Layout?.Header?.Columns?.map(
              (element: any[], index: any) => (
                <View style={styles.headerColumns} key={index}>
                  {element?.map(([text, data]: any, index: number) => (
                    <View
                      key={`${text.Key}-${index}`}
                      style={styles.headerRows}
                    >
                      {headerRowText(text, index)}
                      {headerRowText(data, index)}
                    </View>
                  ))}
                  {/* {child.Type === 'text' ? (
                        <Text
                          style={{ width: child.Width, fontSize: 9 }}
                          key={`${child.Value}-${index}`}
                        >
                          {child.Value}
                        </Text>
                      ) : child.Type === 'date' ? (
                        <Text
                          style={{ width: child.Width, fontSize: 9 }}
                          key={`${child.Key}-${index}`}
                        >
                          {new Date(data?.[child.Key]).toDateString()}
                        </Text>
                      ) : child.Type === 'data' && Array.isArray(child.Key) ? (
                        <View
                          style={{ display: 'flex', flexDirection: 'column' }}
                          key={`${child.Key}-${index}`}
                        >
                          {child.Key.map((innerKey: any, index: any) => (
                            <Text
                              key={`${index}`}
                              style={{ width: child.Width, fontSize: 9 }}
                            >
                              {data?.[innerKey]}
                            </Text>
                          ))}
                        </View>
                      ) : (
                        <Text
                          style={{ width: child.Width, fontSize: 9 }}
                          key={`${child.Key}-${index}`}
                        >
                          {data?.[child.Key]}
                        </Text> */}
                  {/* )} */}
                  {/* <Html style={{ float: 'left' }} key={`${index}`}>
                      {htmlElement(child)}
                    </Html> */}
                  {/* </View> */}
                  {/* ))} */}
                </View>
              )
            )}
          </View>
          <View style={styles.headerRows}>
            {layout?.Layout?.Header?.Subject?.map((sub: any, index: number) => (
              <View key={index}>{headerRowText(sub, index)}</View>
            ))}
          </View>
          <View
            style={{
              ...layout?.Layout?.Particulars?.HeadStyle,
              ...(isColor ? layout?.Layout?.Particulars?.ColorHeadStyle : {})
            }}
          >
            {particularElements?.map(
              (element: any, particularIndex: number) => {
                return (
                  <Text
                    style={{
                      ...(element.Style || {}),
                      ...(element.Width
                        ? {
                            width: element.Style?.width || element.Width
                          }
                        : {})
                    }}
                    key={`${element.Key}-${particularIndex}`}
                  >
                    {element?.Label}
                    {element?.AppendLabel?.Key &&
                      data?.[element?.AppendLabel?.Key] &&
                      element?.AppendLabel?.HasBrackets &&
                      `(${data?.[element?.AppendLabel.Key]})`}
                  </Text>
                )
              }
            )}
          </View>
          {layout?.Layout?.ParticularHeader &&
          data?.[layout.Layout.ParticularHeader.Key] &&
          data?.[layout.Layout.ParticularHeader.Key] !== 'null' ? (
            <View style={layout.Layout.ParticularHeader.Style || {}}>
              <RichText
                key={data[layout.Layout.ParticularHeader.Key]}
                note={data[layout.Layout.ParticularHeader.Key]}
              />
            </View>
          ) : null}
          <View
            style={{
              minHeight: isMedia ? '5mm' : '25mm',
              ...(layout?.Layout?.Particulars?.ContentStyle || {})
            }}
          >
            {particulars?.map((part: any, particularIndex: number) => (
              <View key={`${particularIndex}`}>
                {part?.map((particular: any, pIndex: number) => (
                  <View
                    key={`${particular.No}-${pIndex}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1mm'
                    }}
                  >
                    {particularElements?.map(
                      (element: any, particularElIndex: number) => {
                        if (element.Path) {
                          getElementData(element, particular)
                        }
                        return (
                          <Text
                            key={`${element.Key}-${particularElIndex}`}
                            style={{
                              ...(element.Style || {}),
                              ...(element.Width
                                ? {
                                    width: element.Style?.width || element.Width
                                  }
                                : {})
                            }}
                          >
                            {(element.Label === 'Description' ||
                              element.Label === 'Particular') &&
                            particular[element.Key] ? (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}
                              >
                                <RichText
                                  key={particular[element.Key]}
                                  note={particular[element.Key]}
                                />
                              </View>
                            ) : (
                              <Text>
                                {element.IsNumber
                                  ? `${numberFormatter(
                                      particular[element.Key]
                                    )}`
                                  : Array.isArray(element.Key)
                                  ? particular[element.Key[1]]
                                  : element.Key.toLowerCase().includes(
                                      'date'
                                    ) &&
                                    particular[element.Key] &&
                                    moment(
                                      particular[element.Key],
                                      'YYYY-MM-DD'
                                    ).isValid()
                                  ? moment(
                                      particular[element.Key],
                                      'YYYY-MM-DD'
                                    ).format('DD-MM-YYYY')
                                  : particular[element.Key]}
                              </Text>
                            )}
                          </Text>
                        )
                      }
                    )}
                  </View>
                ))}
                {description && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '21mm',
                      paddingRight: '31mm',
                      ...(part[0][description.Style] || {})
                    }}
                  >
                    <View>
                      <RichText note={part[0][description.Key]} />
                    </View>
                  </View>
                )}
              </View>
            ))}
          </View>
          {!layout?.Layout?.ShowRemarksAfterSubTotal && remarks}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTop: `2px solid ${themeColor}`,
              borderBottom: '2px solid #AAAAAA',
              marginTop: '15mm',
              paddingTop: '2.5mm',
              ...(layout?.Layout?.SubTotal?.WrapperStyle || {})
            }}
          >
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                paddingBottom: '2.5mm'
              }}
            >
              {layout?.Layout?.SubTotal?.Columns?.map(
                (column: any, columnIndex: any) => (
                  <View key={columnIndex}>
                    {column?.map((item: any, itemIndex: any) => (
                      <View key={itemIndex}>
                        {headerRowText(item, itemIndex)}
                      </View>
                    ))}
                  </View>
                )
              )}
            </View>
            <View>
              {subTotal?.map((sub: any, index: number) => (
                <View
                  key={sub.Name}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingBottom: '2.5mm'
                  }}
                >
                  <View style={{ width: '30mm' }}>
                    <Text>{sub.Name}:</Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        textAlign: 'right',
                        width: '30mm',
                        ...(subTotal.length - 1 === index
                          ? { fontWeight: 700 }
                          : {})
                      }}
                    >
                      {sub.Amount ? numberFormatter(sub.Amount) : sub.Amount}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          {layout?.Layout?.ShowRemarksAfterSubTotal && remarks}
          <View
            style={{
              ...(layout?.Layout?.Content?.Style || {}),
              ...(isColor ? layout?.Layout?.Content?.ColorStyle || {} : {})
            }}
          >
            <View style={layout?.Layout?.Content?.SignatoriesStyle || {}}>
              {signatories?.map((seal: any, index: number) => (
                <View key={`${seal.Value}-${index}`}>
                  {headerRowText(seal, index)}
                </View>
              ))}
            </View>
            <View style={layout?.Layout?.Content?.SignatureSectionStyle || {}}>
              {layout?.Layout?.Content?.SignatureSection?.map(
                (signature: any, index: any) => (
                  <View key={`${signature.Value}-${index}`}>
                    {headerRowText(signature, index)}
                  </View>
                )
              )}
              {/* <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end'
                }}
              >
                <Text>{'E & OE'}</Text>
              </View>
              <View style={{ paddingTop: '3mm' }}>
                <Text style={{ paddingTop: '2mm', width: '50mm' }}>
                  {'Signature: '}
                </Text>
                <Text style={{ paddingTop: '2mm', width: '50mm' }}>
                  {'Name: '}
                </Text>
                <Text style={{ paddingTop: '2mm', width: '50mm' }}>
                  {'Date: '}
                </Text>
              </View> */}
            </View>
            <View style={layout?.Layout?.Content?.FooterStyles || {}}>
              {(layout?.Layout?.Content?.showFooterFromLayout
                ? layout?.Layout?.Content?.Footer
                : data?.FooterNote
              )?.map((footer: any, footerIndex: number) => (
                <View key={`${footer.Value}-${footerIndex}`}>
                  {headerRowText(footer, footerIndex)}
                </View>
              ))}
              {/* <Text
                style={{
                  width: '100mm',
                  paddingRight: '2mm',
                  paddingVertical: '2mm'
                }}
              >
                asdfghjklkasdfghjklkasdfghjklkasdfghjklkasdfghjk lkasdfghjklk
                asdfghjklk asdfghjklk asdfghjklk asdfghjklk
                asdfghjklkasdfghjklkasdfghjklkasdfghjklkasdfghjk
                lkasdfghjklkasdfghjklkasdfghjklkasdfghjklkas dfghjklkasdfghjklk
              </Text>
              <Text
                style={{
                  width: '85mm',
                  paddingLeft: '2mm',
                  paddingVertical: '2mm'
                }}
              >
                asdfghjklkasdfghjklkasdfghjklkasdfghjklkasdfghjk lkasdfghjklk
                asdfghjklk asdfghjklk asdfghjklk asdfghjklk
                asdfghjklkasdfghjklkasdfghjklkasdfghjklkasdfghjk
                lkasdfghjklkasdfghjklkasdfghjklkasdfghjklkas dfghjklkasdfghjklk
              </Text> */}
            </View>
            {/* {layout?.Layout?.Content?.map((content: any, index: any) => (
              <View
                key={index}
                style={{
                  padding: `${content?.Padding || 0}px`,
                  borderBottom: `2px solid ${themeColor}`
                }}
              >
                {content.Rows?.map((row: any, rowIndex: number) => (
                  <View
                    key={rowIndex}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {row.Elements?.map((element: any) =>
                      renderContent(element)
                    )}
                  </View>
                ))}
              </View>
            ))} */}
          </View>
        </View>
        <View style={{ height: '10mm', width: '100vw' }} fixed>
          <Text>
            <RichText note="<br /><br />" />
          </Text>
        </View>
        {/* <View style={{ height: '25mm', width: '100vw' }} fixed>
          <Text>
            <RichText note="<br /><br /><br />" />
          </Text>
        </View> */}
        <View
          style={{
            ...layout?.Layout?.Footer[0]?.Style
          }}
          fixed
        >
          <Text
            style={{
              position: 'absolute',
              fontSize: 9,
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: 'center'
            }}
            render={({ pageNumber, totalPages }) =>
              totalPages > 1 ? `${pageNumber} / ${totalPages}` : null
            }
            fixed
          />
          {/* <Text>{layout?.Layout?.Footer?.[0]?.Label}</Text> */}
        </View>
        <View style={{ position: 'relative' }}></View>
      </Page>
    </Document>
  )
}

export default NewPDF
