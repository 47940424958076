import { useRef } from 'react'

import SidebarNav from './SidebarNav'
import {
  MainMenu,
  Scrollbar,
  SidebarHeader,
  SidebarHeaderList,
  SidebarHeaderShadow,
  SidebarNavigation
} from './styles'

import 'react-perfect-scrollbar/dist/css/styles.css'
import { SidebarProps } from './types'
import { getJSONDataFromWebStorage } from '../../../../utils/storage'
import Logo from '../../common/Logo'

const SCROLLBAR_OPTIONS = {
  wheelPropagation: false
}

const Sidebar = ({ className }: SidebarProps) => {
  const shadowRef = useRef<HTMLDivElement>(null!)
  const items = getJSONDataFromWebStorage('sidebarData') || []

  const scrollMenu = (container: HTMLElement) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  return (
    <MainMenu className={className}>
      {/* // * Logo Section in Sidebar */}
      <SidebarHeader>
        <SidebarHeaderList>
          <li className="mr-auto">
            {/* // * Exceptional use case of a tag for reloading to home page */}
            <a href="/">
              <Logo />
            </a>
          </li>
        </SidebarHeaderList>
      </SidebarHeader>

      {/* // * Add shadow on scrolling to top to differentiate header section */}
      <SidebarHeaderShadow ref={shadowRef} />

      {/*
        /*
          * Using Perfect Scrollbar
          //*TODO Use webkit-scrollbar and scrollbar styles instead of Perfect Scrollbar
          ? https://firefox-source-docs.mozilla.org/performance/scroll-linked_effects.html
      */}
      <Scrollbar
        options={SCROLLBAR_OPTIONS}
        onScrollY={container => scrollMenu(container)}
      >
        <SidebarNavigation>
          {/* // * Sidebar Navigation Items */}
          <SidebarNav items={items} />
        </SidebarNavigation>
      </Scrollbar>
    </MainMenu>
  )
}
export default Sidebar
