import Image from '../../../common/Image'
import Loader from '../../../common/Loader'

import { useGetData } from '../../../../../hooks/reactQuery'
import { base64Formatter } from '../../../../../utils/format'

import { ImageProps } from './types'

const ImageItem = ({
  content: { Source: source, Height: height, Alt: alt }
}: ImageProps) => {
  const path = source
  const uniqueKey = source
  const apiParams = { path, body: {} }
  const {
    isLoading,
    isError,
    data: response
  } = useGetData(uniqueKey, apiParams)

  if (isLoading) {
    return (
      <div>
        <Loader size="sm" />
      </div>
    )
  }

  if (isError) {
    return <div>Image</div>
  }

  if (response) {
    const { format, data } = response.data
    const image = base64Formatter(format, data)
    return (
      <div>
        <Image src={image} alt={alt} height={height} />
      </div>
    )
  }

  return null
}

export default ImageItem
