import Card from 'react-bootstrap/Card'
import { PageCenter } from '../../../common/PageCenter'

// * Default component of layout item to render
const Default = () => {
  return (
    <Card>
      <Card.Body>
        <PageCenter height="20vh">Work In Progress</PageCenter>
      </Card.Body>
    </Card>
  )
}

export default Default
