import { createSelector } from 'reselect'

import { AppState } from '../types'
import { RoutePaths } from './types'

export const selectRoutes = createSelector(
  ({ global: { routes } }: AppState) => routes,
  (routesList: RoutePaths) => routesList
)

export const selectLoading = createSelector(
  ({ global: { loading } }: AppState) => loading,
  (appLoading: boolean) => appLoading
)
