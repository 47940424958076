import axios, { AxiosResponse } from 'axios'

import { jsonToFormDataConverter } from '../utils/conversions'

import { APIParams } from '../types/api'
import {
  clearStorageData,
  getToken,
  removeData,
  setData
} from '../utils/storage'
import store from '../store'
import { logoutUser } from '../store/actions'
import { AnyObject } from '../types/common'

let CANCEL_TOKEN_SOURCE = axios.CancelToken.source()

const clearData = () => {
  store.dispatch(logoutUser())
  clearStorageData()
  window.location.href = '/login'
}

// * Request Interceptor
axios.interceptors.request.use(
  async config => {
    // * Get corresponding token according to URL
    const tokenName = config.url?.includes('token_refresh')
      ? 'refreshToken'
      : 'accessToken'
    const token = await getToken(tokenName)
    tokenName === 'refreshToken' &&
      (!token || token === 'undefined') &&
      clearData()
    if (typeof config?.headers === 'object') {
      // * Add Authorization Token
      if (token && token !== 'undefined') {
        config.headers.Authorization = `Bearer ${token}`
      }
      // * Add API Key
      config.headers['x-api-key'] = process.env
        .REACT_APP_BACKEND_API_KEY as string
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// * Response Interceptor
axios.interceptors.response.use(
  response => {
    if (
      response.request?.responseURL?.includes('logout') &&
      response.status === 200
    ) {
      clearData()
    }
    return response
  },
  error => {
    const originalRequest = error?.config
    const originalUrl = originalRequest?.url

    const getAccessToken = async () => {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/token_refresh/`
      try {
        // * Send Access Token request using Refresh token
        // eslint-disable-next-line camelcase
        const response: AxiosResponse<{ AccessToken: string }> =
          await axios.post(url, {})
        const newAccessToken = response.data?.AccessToken
        // * Store Access Token in Web Storage
        newAccessToken
          ? setData('accessToken', newAccessToken)
          : removeData('accessToken')
        // * Send original Request
        return axios(originalRequest)
      } catch (err) {
        clearData()
        Promise.reject(err)
      }
    }
    if (error?.response?.status === 401 || error?.response?.status === 422) {
      if (originalUrl.includes('token_refresh') || originalRequest?._retry) {
        // * Redirect if error happens during retry and token refresh
        logout()
        error.redirect = '/login'
        throw error
      } else if (!originalUrl?.includes('login') && !originalRequest?._retry) {
        // * Retry again with new access token
        originalRequest._retry = true
        return getAccessToken()
      } else throw error
    } else {
      throw error
    }
  }
)

// * Default headers
const defaultHeaders = {
  'Content-Type': 'multipart/form-data;'
}

// * Requests to default Backend Server
export const postData = ({
  path,
  body,
  method = 'post',
  headers = defaultHeaders,
  responseType = null
}: APIParams) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`.replace(
    /([^:])\/\//g,
    '$1/'
  )
  const data = jsonToFormDataConverter(body)
  const cancelToken = CANCEL_TOKEN_SOURCE.token
  const config: AnyObject = { method, url, data, headers, cancelToken }
  if (responseType) {
    config.responseType = responseType
  }
  if (config.url?.includes('logout')) {
    delete config.cancelToken
  }

  return axios(config)
}

export const logout = async () => {
  if (getToken('refreshToken')) {
    const url = 'logout/'
    postData({ path: url, body: {}, method: 'delete' })
  }
}

// * Generate token to cancel requets
const generateNewCancelTokenSource = () => {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source()
}

// * Function to cancel ongoing API calls
export const finishPendingRequests = async (cancellationReason?: string) => {
  CANCEL_TOKEN_SOURCE.cancel(cancellationReason)
  generateNewCancelTokenSource()
}
