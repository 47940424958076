import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from '@redux-saga/core'

import rootReducer from './rootReducer'
// import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

// *TODO Add this when saga is ready to use
// import { rootSaga } from "./rootSaga";

// * Create Saga Middleware
const sagaMiddleware = createSagaMiddleware()

let middleware: any[] = []
if (process.env.NODE_ENV === 'development') {
  // middleware = [...middleware, sagaMiddleware, logger]
  middleware = [...middleware, sagaMiddleware]
}

// * Mount Store with rootReducer, redux-saga and logger
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

// * Run the saga
// sagaMiddleware.run(rootSaga)

export default store
