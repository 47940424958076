import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'

import { responsiveCol } from '../../../../../utils/conversions'

import { FormItemProps } from '../../layout/FormItem/types'
import { useEffect } from 'react'
import { useGetData } from '../../../../../hooks/reactQuery'
import { numberFormatter } from '../../../../../utils/format'
import { useLocation } from 'react-router-dom'

const Input = ({
  element,
  onChange,
  split = [12, 12],
  value,
  style
}: FormItemProps) => {
  const sourcePath = element.Source
  const uniqueKey = `${element.Source}`
  const body = {}
  const location = useLocation()

  const { data: response, refetch: sourceRefetch } = useGetData(
    uniqueKey,
    { path: sourcePath || '', body },
    false
  )
  useEffect(() => {
    element.Type !== 'Tax' && sourcePath && sourceRefetch()
    'Default' in element &&
      typeof element.Default === 'string' &&
      !location?.pathname?.includes('Edit') &&
      onChange &&
      onChange(element.Default)
  }, [])

  useEffect(() => {
    const data = response?.data
    data && onChange && onChange(data)
  }, [response])

  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  return (
    <div style={style}>
      <FormWrapper>
        {element.Label && <Label {...labelProps} />}
        <Col xs={12} md={responsiveCol(split[1])}>
          <Form.Control
            key={element.Key}
            name={element.Name || element.Type}
            placeholder={element.Placeholder || ''}
            type={element.Type || 'text'}
            value={element.IsNumber ? numberFormatter(value) : value || ''}
            disabled={element.Disabled || !!element.Source}
            onChange={event => {
              onChange && onChange(event.target.value)
            }}
          />
        </Col>
      </FormWrapper>
    </div>
  )
}

export default Input
