import moment from 'moment'
import { useCallback, useEffect, useState, useRef } from 'react'
import { Col } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { responsiveCol } from '../../../../../utils/conversions'
import { dateInputFormatter } from '../../../../../utils/format'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import { FormItemProps } from '../../layout/FormItem/types'

const DatePicker = ({
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }

  const [date, setDate] = useState(new Date())
  const prevDate = useRef<Date>()

  const handleDateChange = useCallback(
    (newDate: Date | [Date | null, Date | null] | null) => {
      if (newDate instanceof Date) {
        setDate(newDate)
        prevDate.current = newDate
        onChange && onChange(newDate)
      }
    },
    []
  )

  const defaultDateValue = useCallback(
    () => dateInputFormatter(element?.Default || 'Today'),
    []
  )

  useEffect(() => {
    handleDateChange(defaultDateValue())
  }, [])

  useEffect(() => {
    const defaultDate = dateInputFormatter(element?.Default || 'Today')
    /* Condition is added to avoid re-rendring due to state change */
    if (value && value !== prevDate.current) {
      const momentDate = moment(value, 'YYYY-MM-DD')
      const newDate = momentDate.isValid() ? momentDate.toDate() : defaultDate
      handleDateChange(newDate)
    } else if (!value) {
      handleDateChange(defaultDate)
    }
  }, [value])

  return (
    <FormWrapper>
      {element.Label && <Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        <ReactDatePicker
          className="form-control"
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
        />
      </Col>
    </FormWrapper>
  )
}
export default DatePicker
