import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement
} from 'chart.js'
import { Pie, Bar, Line, Doughnut } from 'react-chartjs-2'
import Card from 'react-bootstrap/Card'

import { GraphProps } from './types'
import { useGetData } from '../../../../../hooks/reactQuery'
import PageLoading from '../../../common/PageLoading'
import { PageCenter } from '../../../common/PageCenter'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
)

const Graph = ({ content }: GraphProps) => {
  const chartComponents = {
    Bar,
    Doughnut,
    Line,
    Pie
  }
  const ChartComponent = chartComponents[content.Type]
  const {
    data: response,
    isLoading: chartLoading,
    isFetching: chartFetching,
    error: chartError
  } = useGetData(content.Title, { path: content.Source, body: {} })

  const renderData = () => {
    if (chartError && !chartFetching) {
      return (
        <PageCenter height="22rem">
          <p>Oops! Unexpected Error occured</p>
        </PageCenter>
      )
    }

    if (chartLoading || (!chartError && chartFetching)) {
      return <PageLoading height="22rem" />
    }

    return (
      ChartComponent && <ChartComponent data={response?.data} options={{}} />
    )
  }

  return (
    <Card className="pb-5">
      <Card.Title className="my-3">{content.Title}</Card.Title>
      {renderData()}
    </Card>
  )
}

export default Graph
