import { View, Text, Link, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  headingOne: {
    marginBottom: 4,
    color: '#3a4b56',
    fontWeight: 700,
    lineHeight: 1.35,
    fontSize: 12
  },
  HeadingTwo: {
    marginBottom: 2,
    color: '#3a4b56',
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: 10
  },
  text: {
    display: 'flex',
    lineHeight: 1.1
  },
  list: {
    marginBottom: 8,
    marginLeft: 6
  },
  listItem: {
    marginBottom: 4
  },
  listItemText: {
    marginBottom: 8,
    display: 'flex',
    lineHeight: 1.1,
    marginLeft: -10
  }
})

const HeadingOne = ({ children }: any) => {
  return (
    <View>
      <Text style={styles.headingOne}>{children}</Text>
    </View>
  )
}
const HeadingTwo = ({ children }: any) => {
  return (
    <View>
      <Text style={styles.headingOne}>{children}</Text>
    </View>
  )
}

const UnorderedList = ({ children, depth }: any) => {
  return <View style={styles.list}>{children}</View>
}

const UnorderedListItem = ({ children }: any) => {
  return (
    <View style={styles.listItem}>
      <Text style={styles.listItemText}>
        • &nbsp;<Text>{children}</Text>
      </Text>
    </View>
  )
}

const OrderedList = ({ children, depth }: any) => {
  return <View style={styles.list}>{children}</View>
}

const OrderedListItem = ({ children, index }: any) => {
  return (
    <View style={styles.listItem}>
      <Text style={styles.listItemText}>
        {index + 1}. &nbsp;<Text>{children}</Text>
      </Text>
    </View>
  )
}

const renderers = {
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children: any, { key }: any) => {
      return (
        <Text key={`bold-${key}`} style={{ fontWeight: 700 }}>
          {children}
        </Text>
      )
    },
    ITALIC: (children: any, { key }: any) => {
      return (
        <Text key={`italic-${key}`} style={{ fontStyle: 'italic' }}>
          {children}
        </Text>
      )
    },
    UNDERLINE: (children: any, { key }: any) => {
      return (
        <Text key={`underline-${key}`} style={{ textDecoration: 'underline' }}>
          {children}
        </Text>
      )
    }
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: (children: any, { keys }: any) => {
      return (
        <View>
          {children.map((child: any, index: any) => {
            return (
              <View key={keys[index]}>
                <Text style={styles.text}>{child}</Text>
                <Text>{'\n'}</Text>
              </View>
            )
          })}
        </View>
      )
    },
    'header-one': (children: any, { keys }: any) => {
      return children.map((child: any, index: any) => {
        return (
          <HeadingOne key={keys[index]}>
            {child}
            {'\n'}
          </HeadingOne>
        )
      })
    },
    'header-two': (children: any, { keys }: any) => {
      return children.map((child: any, index: any) => {
        return (
          <HeadingTwo key={keys[index]}>
            {child}
            {'\n'}
          </HeadingTwo>
        )
      })
    },
    'unordered-list-item': (children: any, { depth, keys }: any) => {
      return (
        <UnorderedList key={keys[keys.length - 1]} depth={depth}>
          {children.map((child: any, index: any) => (
            <UnorderedListItem key={keys[index]}>
              {child}
              {'\n'}
            </UnorderedListItem>
          ))}
        </UnorderedList>
      )
    },
    'ordered-list-item': (children: any, { depth, keys }: any) => {
      return (
        <OrderedList key={keys.join('|')} depth={depth}>
          {children.map((child: any, index: any) => (
            <OrderedListItem key={keys[index]} index={index}>
              {child}
              {'\n'}
            </OrderedListItem>
          ))}
        </OrderedList>
      )
    }
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children: any, data: any, { key }: any) => (
      <Link key={key} src={data.url}>
        {children}
      </Link>
    )
  }
}

export default renderers
