export const SET_PRINT_LAYOUT = 'SET_PRINT_LAYOUT'
export const SET_PRINT_LOGO = 'SET_PRINT_LOGO'
export const SET_PRINT_BW_LOGO = 'SET_PRINT_BW_LOGO'
export const SET_PRINT_FONT = 'SET_PRINT_FONT'
export const SET_PRINT_BUSINESS_INFO = 'SET_PRINT_BUSINESS_INFO'
export const SET_PRINT_DATA = 'SET_PRINT_DATA'
export const SET_PRINT_PARTICULARS = 'SET_PRINT_PARTICULARS'
export const SET_PRINT_SUBTOTAL = 'SET_PRINT_SUBTOTAL'
export const SET_PRINT_FILE_NAME = 'SET_PRINT_FILE_NAME'
export const SET_ROW_TITLES = 'SET_ROW_TITLES'
export const SET_PRINT_START = 'SET_PRINT_START'
export const SET_PRINT_IMAGES = 'SET_PRINT_IMAGES'
