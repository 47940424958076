import {
  SET_PRINT_BUSINESS_INFO,
  SET_PRINT_BW_LOGO,
  SET_PRINT_DATA,
  SET_PRINT_FILE_NAME,
  SET_PRINT_FONT,
  SET_PRINT_IMAGES,
  SET_PRINT_LAYOUT,
  SET_PRINT_LOGO,
  SET_PRINT_PARTICULARS,
  SET_PRINT_START,
  SET_PRINT_SUBTOTAL,
  SET_ROW_TITLES
} from './actionTypes'
import { PrintAction, PrintState } from './types'

const initialState = {
  layout: {},
  data: {},
  font: {},
  logo: {},
  particulars: [],
  businessInfo: {},
  subTotal: [],
  bwLogo: {},
  fileName: {
    prefix: '',
    code: ''
  },
  rowTitles: [],
  printStart: false,
  printImages: []
}

export const printReducer = (
  state: PrintState = initialState,
  action: PrintAction = {} as PrintAction
) => {
  const { type, payload } = action
  switch (type) {
    case SET_PRINT_LAYOUT:
      return { ...state, layout: payload }
    case SET_PRINT_DATA:
      return { ...state, data: payload }
    case SET_PRINT_LOGO:
      return { ...state, logo: payload }
    case SET_PRINT_BW_LOGO:
      return { ...state, bwLogo: payload }
    case SET_PRINT_FONT:
      return { ...state, font: payload }
    case SET_PRINT_PARTICULARS:
      return {
        ...state,
        particulars:
          Array.isArray(payload) && payload.map((part: any) => [part])
      }
    case SET_PRINT_BUSINESS_INFO:
      return { ...state, businessInfo: payload }
    case SET_PRINT_SUBTOTAL:
      return { ...state, subTotal: payload }
    case SET_PRINT_FILE_NAME:
      return { ...state, fileName: payload }
    case SET_ROW_TITLES:
      return { ...state, rowTitles: payload }
    case SET_PRINT_START: {
      return { ...state, printStart: payload }
    }
    case SET_PRINT_IMAGES: {
      return { ...state, printImages: payload }
    }
    default:
      return state
  }
}
