import { TableCenterData } from './styles'
import { TableBodyCenterProps } from './types'

const TableBodyCenter = ({ children, headList }: TableBodyCenterProps) => (
  <tr>
    {/* // * To make column width equal to toal width of table */}
    <td colSpan={headList.length}>
      <TableCenterData>{children}</TableCenterData>
    </td>
  </tr>
)

export default TableBodyCenter
