import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import { device } from '../../styles/styled/device'

export const AuthInner = styled(Row)`
  margin: 0;
  overflow-y: auto;
  width: 100%;
  position: relative;
  height: 100vh;
`
export const AuthLogo = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
`

export const AuthCard = styled(Card)`
  padding: 3rem;
  min-width: 15rem;
  @media ${device.tablet} {
    min-width: 25rem;
  }
`
