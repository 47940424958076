import { MouseEvent, useEffect, useState } from 'react'
import { Circle } from 'react-feather'
import { SidebarNavigation } from '../styles'
import { SidebarSubItemsProp } from '../SidebarNav/types'
import { SidebarNavigationStyle } from './types'
import { SidebarLink, SidebarListItem } from './styles'
import { match as Match } from 'react-router-dom'

// *TODO Expand Sidebar Nav if URL path matches

const SidebarItem = ({ items, text, showIcon, link }: SidebarSubItemsProp) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [isOpening, setIsOpening] = useState(false)

  useEffect(() => {
    // * Expand sidebar items
    if (isOpening) {
      setIsOpen(true)
    } else {
      // * setTimeout to complete all animations
      setTimeout(() => setIsOpen(false), 600)
    }
  }, [isOpening])

  const sidebarNavigationStyle: SidebarNavigationStyle = {
    height: items && isOpening ? `${0.75 + 3.7738 * items.length}rem` : 0
  }

  const toggleOpen = (event: MouseEvent<HTMLAnchorElement>) => {
    if (items?.length) {
      event.preventDefault()
      if (!isAnimating) {
        setIsAnimating(true)
        // * setTimeout to complete all animations
        setTimeout(() => setIsAnimating(false), 600)
        setIsOpening(!isOpening)
      }
    }
  }

  // * Check whether the nav item is active
  const setActive = (match: Match | null) => {
    return !!match && !items?.length
  }

  return (
    <SidebarListItem>
      {/* // * Navigation links */}
      <SidebarLink
        to={items?.length ? '#' : link.split(' ').join('')}
        exact
        isActive={setActive}
        activeClassName="sidebar_selected"
        onClick={toggleOpen}
        $showIcon={!!items?.length}
        $isOpen={isOpening}
      >
        {/* // * Show Icon for nested navigations */}
        {showIcon && <Circle size={12} className="me-3" />}

        {/* // * Navigation Text */}
        {text}
      </SidebarLink>

      {/* // * Navigation for sub modules */}
      <SidebarNavigation style={sidebarNavigationStyle}>
        {isOpen &&
          items?.map(item => (
            <SidebarItem
              key={item}
              text={item}
              showIcon={true}
              link={`/${text}/${item}`}
            />
          ))}
      </SidebarNavigation>
    </SidebarListItem>
  )
}

export default SidebarItem
