import {
  BUTTON_LOADING,
  RESET_FORM,
  SET_SUCCESS,
  SET_URLS
} from './actionTypes'
import { FormModalReducer } from './types'

export const initialState: FormModalReducer = {
  urls: {
    path: '',
    source: '',
    requestBody: {}
  },
  access: '',
  isSuccess: false,
  buttonLoading: false
}

export const formModalReducer = (
  state: FormModalReducer = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_URLS:
      return { ...state, urls: action.payload }
    case SET_SUCCESS:
      return { ...state, isSuccess: action.payload }
    case BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: action.payload
      }
    case RESET_FORM:
      return { ...initialState }
    default:
      return state
  }
}
