import { StorageType, TokenType } from '../types/storage'

let storage: StorageType

const getStorageType = () => {
  if (window?.sessionStorage?.getItem('refreshToken')) {
    storage = 'sessionStorage'
  } else if (window?.localStorage?.getItem('refreshToken')) {
    storage = 'localStorage'
  }
}

export const setStorageType = (storageType: StorageType) => {
  storage = storageType
}

export const getJSONDataFromWebStorage = (data: string) => {
  storage || getStorageType()
  if (storage) {
    const storageData = window[storage].getItem(data)
    return storageData ? JSON.parse(storageData) : null
  } else return null
}

export const getToken = (token: TokenType) => {
  storage || getStorageType()
  return storage ? window[storage].getItem(token) : null
}

export const setData = (
  key: string,
  value: string,
  webStorage: StorageType = storage || getStorageType() || 'sessionStorage'
) => {
  if (window?.[webStorage]) {
    storage = webStorage
    window[storage].setItem(key, value)
  }
}

export const removeData = (key: string) => {
  storage || getStorageType()
  return storage && window[storage].removeItem(key)
}

export const clearStorageData = () => {
  storage || getStorageType()
  storage && window[storage].clear()
}
