import { Link, useParams } from 'react-router-dom'

import RippleButton from '../../../common/RippleButton'

import { ButtonElementProps } from './types'
import { IdParams } from '../../../../../types/params'
import PDFLink from '../../../../PDF/PDFLink'
import { useSelector } from 'react-redux'
import { selectPrint } from '../../../../../store/print/selector'

const Button = ({ element, className, data }: ButtonElementProps) => {
  const { id } = useParams<IdParams>()
  const { printStart } = useSelector(selectPrint)
  const queryParams = new URLSearchParams()
  element.QueryParams &&
    element.QueryParams?.forEach(([key, value]) => {
      queryParams.set(key, value)
    })
  element.QueryParamsVar &&
    element.QueryParamsVar?.forEach(([key, value]) => {
      let queryParamVal = ''
      if (value === 'Id') {
        queryParamVal = id
      }
      queryParams.set(key, queryParamVal)
    })
  const link = element.Link.replaceAll('{Id}', id)
  const href = link + queryParams.toString()

  /*
   * If target is present, it would be external link.
   * So button would act as `a` tag with href attribute
   */
  if (element.Target) {
    if (printStart) {
      return (
        <PDFLink
          buttonText={element.Label}
          otherData={data}
          key={element.Label}
          className="me-1"
          fileNameSuffix={element.PrintFileSuffix}
          isDownloadButton={element.IsDownloadButton}
        />
      )
    }
    return (
      <RippleButton isLoading={true} className={className}>
        {element.Label}
      </RippleButton>
    )
  }
  /*
   * If target is not present, it would be internal link.
   * It should be redirected using react-router-dom `Link`
   */
  return (
    <Link to={href}>
      <RippleButton variant={element.Class} className={`${className} mt-3`}>
        {element.Label}
      </RippleButton>
    </Link>
  )
}

export default Button
