export const zIndexMap = {
  mainSidebar: 70,
  mainHeader: 60,
  mainHeaderShadow: 50,
  mainSidebarShadow: 40,
  reactSelectBox: 30,
  reactQuillTooltip: 30,
  inputGroupText: 20,
  customControl: 10,
  pagination: 10,
  timelinePoint: 10
}
