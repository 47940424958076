import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { LabelProps } from './types'

const Label = ({ text, required, split }: LabelProps) => {
  if (text) {
    return (
      <Col xs={12} md={split || 12}>
        <Form.Label>
          {text}
          {required ? <span className="text-danger">*</span> : ''}
        </Form.Label>
      </Col>
    )
  }
  return null
}

export default Label
