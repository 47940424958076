import { createSelector } from 'reselect'
import { AnyObject } from '../../types/common'
import { AppState } from '../types'

export const selectMainData = createSelector(
  ({ viewDetails: { mainData } }: AppState) => mainData,
  (mainData: AnyObject) => mainData
)

export const selectRefetch = createSelector(
  ({ viewDetails: { refetch } }: AppState) => refetch,
  (refetch: boolean) => refetch
)
