import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { size } from '../../../../styles/styled/device'
import { getJSONDataFromWebStorage } from '../../../../utils/storage'
import Sidebar from '../Sidebar'
import {
  BrandLink,
  NavbarContainer,
  NavbarContent,
  NavbarItems,
  NavMenu,
  StyledOffcanvas
} from './styles'
import UserDropdown from './UserDropdown'

const Navbar = ({ showSidebar }: { showSidebar: boolean }) => {
  const [brandName, setBrandName] = useState('')
  const [showCanvas, setShowCanvas] = useState(false)

  // * Show Sidebar animation for small devices using Bootstrap OffCanvas
  const handleResize = useCallback(() => {
    if (window.innerWidth > size.md) {
      setShowCanvas(false)
    }
  }, [])

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const headerDetails = getJSONDataFromWebStorage('headerData')
    setBrandName(headerDetails?.legalName || 'Brand Name')
  }, [])

  return (
    <>
      <NavbarContainer showSidebar={showSidebar}>
        <NavbarContent>
          {/* // * Navbar Left Side */}
          <div className="d-flex">
            {/* // * Conditionally render hamburger menu(sidebar toggler) */}
            <NavMenu onClick={() => setShowCanvas(true)} />
            <big>
              {/* // * Show brand Name which links to home page with reload */}
              <BrandLink href="/">{brandName}</BrandLink>
            </big>
          </div>

          {/* // * Navbar Right Side */}
          <NavbarItems className="main__header">
            {/* // * User Details Section */}
            <UserDropdown />
          </NavbarItems>
        </NavbarContent>
      </NavbarContainer>

      {/* // * Bootstrap Off Canvas => Sidebar coming from side in smaller devices */}
      <StyledOffcanvas show={showCanvas} onHide={() => setShowCanvas(false)}>
        <Sidebar />
      </StyledOffcanvas>
    </>
  )
}

export default Navbar
