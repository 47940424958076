import Access from '../../items/AccessCard'
import Chat from '../../items/Chat'
import Data from '../../items/Data'
import Default from '../../items/Default'
import Folder from '../../items/Folder'
import Form from '../../items/Form'
import Graph from '../../items/Graph'
import Invoice from '../Invoice'
import InvoiceView from '../InvoiceView'
import List from '../../items/List'
import Plugin from '../../items/Plugin'
import Timeline from '../../items/Timeline'

import DynamicLayout from '../Container'

import { DynamicItemProps } from './types'
import Tabs from '../../items/Tabs'

// * Renders each type of item in the layout
const DynamicItem = (props: DynamicItemProps) => {
  const forms =
    props.contentType === 'edit_form' ||
    props.contentType === 'upload_form' ||
    props.contentType === 'form'
      ? props.contentType
      : null
  switch (props.contentType) {
    case 'chat':
      return <Chat content={props.content} />
    case 'component':
      return <Access content={props.content} />
    case 'data':
      return <Data content={props.content} />
    case 'folder':
      return <Folder content={props.content} />
    case forms:
      return <Form content={props.content} />
    case 'graph':
      return <Graph content={props.content} />
    case 'invoice':
      return <Invoice content={props.content} />
    case 'invoice-view':
      return <InvoiceView content={props.content} />
    case 'list':
      return <List content={props.content} />
    case 'plugin':
      return <Plugin content={props.content} />
    case 'rows':
      return <DynamicLayout layout={props.content} />
    case 'tabs':
      return <Tabs content={props.content} />
    case 'timeline':
      return <Timeline content={props.content} />
    default:
      return <Default />
  }
}

export default DynamicItem
