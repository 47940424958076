// src/store/dynamic/reducer.ts
import { AnyObject } from '../../types/common'
import { FormElement } from '../../types/formElement'
import {
  BUTTON_LOADING,
  CLEAR_ROW_ITEM_DATA,
  FORM,
  MAIN_DATA,
  RESET_FORM,
  SET_ACCESS,
  SET_DATA,
  SET_DATA_ITEM,
  SET_DATUM,
  SET_ELEMENTS,
  SET_ELEMENT_ITEM,
  SET_ELEMENT_ITEM_DATA,
  SET_FILL_ROWS_ITEM_DATA,
  SET_FILL_ROW_ITEM_DATA,
  SET_PARAMETER_ELEMENT,
  SET_ROW_ITEM_DATA,
  SET_ROW_ITEM_ELEMENT,
  SET_SUCCESS,
  SET_URLS
} from './actionTypes'
import { DynamicReducer } from './types'

export const initialState: DynamicReducer = {
  mainData: {},
  form: {
    data: {},
    elements: {},
    urls: {
      path: '',
      source: '',
      requestBody: {}
    },
    access: '',
    isSuccess: false,
    buttonLoading: false
  }
}

// *TODO : Add type to action parameter
export const dynamicReducer = (state: DynamicReducer, action: any) => {
  switch (action.type) {
    case MAIN_DATA:
      return { ...state, mainData: action.payload }
    case FORM:
      return { ...state, form: action.payload }
    case SET_ACCESS:
      return { ...state, form: { ...state.form, access: action.payload } }
    case SET_DATA:
      return { ...state, form: { ...state.form, data: action.payload } }
    case SET_DATUM:
      return {
        ...state,
        form: { ...state.form, data: { ...state.form.data, ...action.payload } }
      }
    case SET_ELEMENTS:
      return { ...state, form: { ...state.form, elements: action.payload } }
    case SET_ELEMENT_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          elements: { ...state.form.elements, ...action.payload }
        }
      }
    case SET_DATA_ITEM: {
      const { name: key, data } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [key]: data
          }
        }
      }
    }
    case SET_ELEMENT_ITEM_DATA: {
      const { name: key, data } = action.payload
      const value = { ...state.form.elements[key], ...data }
      if (key) {
        return {
          ...state,
          form: {
            ...state.form,
            elements: {
              ...state.form.elements,
              [key]: value
            }
          }
        }
      }
      return state
    }
    case SET_ROW_ITEM_DATA: {
      const {
        elementName,
        rowIndex,
        rowElementName,
        parameterIndex,
        parameterElementName,
        value
      } = action.payload
      const rows = [...state.form.data[elementName]]
      if (!rows[rowIndex][rowElementName]) {
        rows[rowIndex][rowElementName] = []
        rows[rowIndex][rowElementName][parameterIndex] = {}
      }
      rows[rowIndex][rowElementName][parameterIndex][parameterElementName] =
        value
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [elementName]: rows
          }
        }
      }
    }
    case SET_FILL_ROW_ITEM_DATA: {
      const data = { ...state.form.data }
      const { response, onChangeResponseRowFill } = action.payload
      onChangeResponseRowFill.forEach((row: AnyObject) => {
        if ('Default' in row) {
          if (row.key.length === 3) {
            data[row.key[0]][row.key[1]][row.key[2]] = row.Default
          }
        } else if (row.value.length === 5) {
          if (row.key.length === 2) {
            const fillData =
              response[row.value[0]][row.value[1]][row.value[2]][
                row.value[3]
              ][0][row.value[4]]
            if (typeof fillData === 'string') {
              data[row.key[0]][0][row.key[1]] = fillData
            } else if (typeof fillData === 'object') {
              Object.keys(fillData).forEach(key => {
                data[row.key[0]][0][row.key[1]][key] = fillData[key]
              })
            }
          } else if (row.key.length === 1) {
            const fillData =
              response[row.value[0]][row.value[1]][row.value[2]][row.value[3]][
                row.value[4]
              ]
            data[row.key[0]] = fillData
          }
        } else if (row.key.length === 3 && row.value.length === 2) {
          data[row.key[0]][row.key[1]][row.key[2]] =
            response[row.value[0]][row.value[1]]
        } else if (row.key.length === 5 && row.value.length === 2) {
          if (!data[row.key[0]]) {
            data[row.key[0]] = []
          }
          if (!data[row.key[0]][row.key[1]]) {
            data[row.key[0]][row.key[1]] = {}
          }
          if (!data[row.key[0]][row.key[1]][row.key[2]]) {
            data[row.key[0]][row.key[1]][row.key[2]] = []
          }
          if (!data[row.key[0]][row.key[1]][row.key[2]][row.key[3]]) {
            data[row.key[0]][row.key[1]][row.key[2]][row.key[3]] = {}
          }
          data[row.key[0]][row.key[1]][row.key[2]][row.key[3]][row.key[4]] =
            response[row.value[0]][row.value[1]]
        }
      })
      return {
        ...state,
        form: {
          ...state.form,
          data
        }
      }
    }
    case SET_FILL_ROWS_ITEM_DATA: {
      const data = { ...state.form.data }
      const { response, onChangeResponseRowsFill } = action.payload
      onChangeResponseRowsFill.forEach((row: AnyObject) => {
        if (row.key.length === 3) {
          data[row.key[0]][row.key[1]][row.key[2]] =
            response[row.value[0]][row.value[1]]
        } else if (row.key.length === 5) {
          data[row.key[0]][row.key[1]][row.key[2]][row.key[3]][row.key[4]] =
            response[row.value[0]][row.value[1]]
        }
      })
      return {
        ...state,
        form: {
          ...state.form,
          data
        }
      }
    }
    case CLEAR_ROW_ITEM_DATA: {
      const data = { ...state.form.data }
      const elements = { ...state.form.elements }
      const { rowData } = action.payload
      rowData.forEach((row: AnyObject) => {
        data[row.key[0]].length = 1
        elements[row.key[0]].length = 1
        if (row.key.length === 3 && data[row.key[0]]?.[row.key[1]]) {
          data[row.key[0]][row.key[1]][row.key[2]] = row.value
        } else if (
          row.key.length === 5 &&
          data[row.key[0]]?.[row.key[1]]?.[row.key[2]]?.[row.key[3]]
        ) {
          data[row.key[0]][row.key[1]][row.key[2]][row.key[3]][row.key[4]] =
            row.value
        }
      })
      return {
        ...state,
        form: {
          ...state.form,
          data,
          elements
        }
      }
    }
    case SET_ROW_ITEM_ELEMENT: {
      const { responseData, element, index, rowElementIndex } = action.payload
      const elementItem = { ...state.form.elements[element.Name] }
      if (responseData?.Parameters?.length) {
        elementItem.MultipleRows[index].elements[rowElementIndex].Elements = [
          elementItem.MultipleRows[index].elements[rowElementIndex].Elements[0],
          ...responseData?.Parameters
        ]
      } else {
        elementItem.MultipleRows[index].elements[rowElementIndex].Elements = [
          elementItem.MultipleRows[index].elements[rowElementIndex].Elements[0]
        ]
      }
      if (responseData?.Measurement) {
        let measurementObject = {}
        if (
          Array.isArray(responseData.Measurement) &&
          responseData.Measurement?.length
        ) {
          measurementObject = {
            ...responseData.Measurement[0],
            IsMeasurement: true
          }
        } else {
          measurementObject = {
            Default: 0,
            Label: 'Qty',
            Name: responseData.Measurement || 'Qty',
            Type: 'text',
            IsMeasurement: true
          }
        }

        const measurementIndex = elementItem.MultipleRows[
          index
        ].elements.findIndex(
          (elem: FormElement) =>
            elem.Type === 'measurements' || elem.IsMeasurement
        )
        elementItem.MultipleRows[index].elements[measurementIndex] = {
          ...measurementObject
        }
      }
      return {
        ...state,
        form: {
          ...state.form,
          elements: {
            ...state.form.elements,
            [element.Name]: elementItem
          }
        }
      }
    }
    case SET_PARAMETER_ELEMENT: {
      const { elementName, rowIndex, rowElementIndex, parameterName, element } =
        action.payload
      const elementItem = { ...state.form.elements[elementName] }
      let parameterElement = elementItem.MultipleRows[rowIndex].elements[
        rowElementIndex
      ].Elements.find((parameter: any) => parameter.Name === parameterName)
      if (parameterElement) {
        parameterElement = element
      }
      return {
        ...state,
        form: {
          ...state.form,
          elements: {
            ...state.form.elements,
            [elementName]: elementItem
          }
        }
      }
    }
    case SET_URLS:
      return { ...state, form: { ...state.form, urls: action.payload } }
    case SET_SUCCESS:
      return { ...state, form: { ...state.form, isSuccess: action.payload } }
    case BUTTON_LOADING:
      return {
        ...state,
        form: { ...state.form, buttonLoading: action.payload }
      }
    case RESET_FORM:
      return { ...initialState }
    default:
      return state
  }
}
