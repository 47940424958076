import { components as CustomOption } from 'react-select'

const DefaultOptions = ({ children, ...props }: any) => (
  <CustomOption.Option {...props}>
    <div>
      <strong>{children}</strong>
    </div>
    <div>
      {Object.entries(props.data).map(([key, optionValue], index) =>
        key !== 'label' &&
        key !== 'value' &&
        key !== 'Id' &&
        key !== '_id' &&
        key !== '__isNew__' &&
        key !== 'isDisabled' &&
        optionValue &&
        typeof optionValue !== 'object' ? (
          <div key={`${key}${index}`}>
            <small>
              {key}: {optionValue}
            </small>
          </div>
        ) : null
      )}
    </div>
  </CustomOption.Option>
)

const CustomOptions = ({ children, ...props }: any) => (
  <CustomOption.Option {...props}>
    <div>
      <strong>{children}</strong>
    </div>
    <div>
      {Object.entries(props.data).map(([key, optionValue], index) =>
        key !== 'label' &&
        key !== 'value' &&
        key !== 'Id' &&
        key !== '_id' &&
        key !== '__isNew__' &&
        key !== 'isDisabled' &&
        optionValue &&
        typeof optionValue !== 'object' ? (
          <div key={`${key}${index}`}>
            <small>
              {key}: {optionValue}
            </small>
          </div>
        ) : null
      )}
    </div>
  </CustomOption.Option>
)

export default CustomOptions
export { DefaultOptions }
