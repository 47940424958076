import { ToWords } from 'to-words'
import { AnyObject } from '../types/common'

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-')
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word: any) => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

export const getStyleObjectFromString = (str: string) => {
  const style: AnyObject = {}
  if (typeof str === 'string' && str.length) {
    str.split(';').forEach(el => {
      const [property, value] = el.split(':')
      if (!property) return

      const formattedProperty = formatStringToCamelCase(property.trim())
      if (formattedProperty) {
        style[formattedProperty] = value.trim()
      }
    })
  }
  return style
}

export const amountToWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false
  }
})

export const getParameterPercentFieldName = (parameterName: string) =>
  parameterName?.includes('[]')
    ? `${parameterName.split('[]')[0]}Percentage[]`
    : `${parameterName}Percentage`

export const removeFalsyValues = (string: string) => {
  if (
    string === 'null' ||
    string === 'undefined' ||
    string === '<p>null</p>' ||
    string === '<p>undefined</p>'
  ) {
    return ''
  }
  return string
}
