import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { responsiveCol } from '../../../../../utils/conversions'
import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'
import { FormItemProps } from '../../layout/FormItem/types'

const Checkbox = ({
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }
  return (
    <FormWrapper>
      {element.Label && <Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        <Form.Check
          type={element.Type === 'checkbox' ? 'checkbox' : 'radio'}
          key={element.Key}
          name={element.Name || element.Type}
          placeholder={element.Placeholder || ''}
          className="custom-control custom-checkbox checkbox-small ps-0"
          checked={!!value}
          disabled={element.Disabled}
          onChange={event => onChange && onChange(event.target.checked)}
        />
      </Col>
    </FormWrapper>
  )
}
export default Checkbox
