// src/views/components/dynamic/items/List/index.tsx
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { useHistory, useParams } from 'react-router-dom'

import { PAGE_LIMIT } from '../../../../../constants/list'
import { ListContext } from '../../../../../context/ListContext'

import ActionButton from '../../components/ActionButton'
import ListPagination from '../../components/Pagination'
import TableBody from '../../components/TableBody'
import TableHead from '../../components/TableHead'

import { useGetData } from '../../../../../hooks/reactQuery'
import useDebounce from '../../../../../hooks/useDebounce'
import useQuery from '../../../../../hooks/useQuery'

import { initialState, listReducer } from './store/reducer'
import {
  setCount,
  setCurrentPage,
  setError,
  setLoading,
  setSearch,
  setTableData,
  setTableHead
} from './store/actions'

import { errorMessageFromError } from '../../../../../utils/dynamicData'

import { FilterType, ListProps } from './types'
import { AnyObject } from '../../../../../types/common'
import { IdParams } from '../../../../../types/params'
import { Col, Modal, Row } from 'react-bootstrap'
import FormModal from '../../components/FormModal'
import RippleButton from '../../../common/RippleButton'
import { OnChangeValueType } from '../../../../../types/formElement'
import FormItem from '../../layout/FormItem'
import { postData } from '../../../../../api'

const List = ({ content }: ListProps) => {
  const [state, dispatch] = useReducer(listReducer, initialState)
  // * SearchText is for debouncing. state.search is for API call
  const [searchText, setSearchText] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<AnyObject>()
  const { id } = useParams<IdParams>()
  const debouncedSearch = useDebounce(searchText)
  const { replace: replaceHistory, push: pushHistory } = useHistory()
  const query = useQuery()
  const [filters, setFilters] = useState<AnyObject>({})
  const [filteredValues, setFilteredValues] = useState<AnyObject>({})
  const [filterElements, setFilterElements] = useState<FilterType>()
  const [isReportLoading, setIsReportLoading] = useState(false)
  // * Create parameters for useGetData to get Data from API
  const uniqueKey = `${content.Path}-${state.currentPage}-${
    state.search
  }-${JSON.stringify(filteredValues)}`
  const body: AnyObject = {
    PageNum: state.currentPage,
    SearchQuery: state.search,
    ...filteredValues
  }

  const requestBody = content.RequestBody
  if (requestBody) {
    Object.keys(requestBody).forEach(key => {
      if (key === 'Id' && id) {
        body[requestBody[key]] = id
      }
    })
  }
  const path = query.get('path') || content.Path
  const requestData = { path, body }
  const enabled = !content.DisableRequest
  const { data, isLoading, isFetching, error, refetch } = useGetData(
    uniqueKey,
    requestData,
    enabled,
    enabled,
    1000
  )

  useEffect(() => {
    dispatch(setTableHead(content.THead))
    // * Get search text and page number from query params
    const search = query.get('search')
    const page = Number(query.get('page'))
    if (search) {
      setSearchText(search)
    }
    page && page !== 1 && dispatch(setCurrentPage(page))
  }, [])

  useEffect(() => {
    if (data) {
      const { data: tableData }: any = data
      // *Uncomment the below lines after consulting Backend.
      // const items: AnyObject[] = content.ResponseData
      //   ? tableData[content.ResponseData]
      //   : tableData
      const items: AnyObject[] = tableData
      // * Copy the array of data to another reference to not to mutate original response
      // * Original response is used for caching
      if (items) {
        const tableItems = [...items]
        if (!('HasMeta' in content && content.HasMeta === false)) {
          // * The last item in response in page details. Removed it from tableItems and store it to metaData
          const metaData = tableItems.splice(tableData.length - 1, 1)
          dispatch(setCount(metaData[0]?.count || 0))
        }
        dispatch(setTableData(tableItems))
      }
    }
  }, [data])

  useEffect(() => {
    if (error && !isFetching) {
      const errorMessage = errorMessageFromError(error as AnyObject)
      dispatch(setError(errorMessage))
    } else {
      dispatch(setError(''))
    }
  }, [error])

  useEffect(() => {
    dispatch(setLoading(!!isLoading || (!!error && !!isFetching)))
  }, [isLoading, isFetching])

  useEffect(() => {
    // * Update state.search only if there is a change
    if (searchText !== state.search) {
      dispatch(setSearch(searchText))
      dispatch(setCurrentPage(1))
    }
  }, [debouncedSearch])

  useEffect(() => {
    // * Set search text and page number to query params
    state.search
      ? query.set('search', state.search)
      : query.has('search') && query.delete('search')
    query.set('page', `${state.currentPage}`)
    const search = query.toString()
    search && replaceHistory({ search })
  }, [state.search, state.currentPage])

  useEffect(
    () => content.Filters && setFilterElements(content.Filters),
    [content.Filters]
  )

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchText(target.value)
  }
  const handleAction = useCallback(
    (
      item: AnyObject,
      event: MouseEvent<HTMLSpanElement | HTMLButtonElement>
    ) => {
      event.stopPropagation()
      if (item.Modal || item.Button === 'Delete') {
        setShowModal(true)
        setSelectedItem(item)
        item.RequestBody?.Id && query.set('id', item.RequestBody.Id)
        const search = query.toString()
        search && replaceHistory({ search })
        if (item.Button === 'Delete' || item.Modal === 'delete') {
          setIsDeleteModal(true)
        }
      } else {
        item.RedirectTo && pushHistory(`/${item.RedirectTo}`)
      }
    },
    []
  )

  // * Create value for listContext value prop
  const listContextValue = useMemo(
    () => ({ state, dispatch, handleAction }),
    [state]
  )

  const removeModalDetails = () => {
    showModal && setShowModal(false)
    query.delete('id')
    const search = query.toString()
    search && replaceHistory({ search })
  }

  const hideModal = (isSuccess?: boolean) => {
    removeModalDetails()
    if (isSuccess) {
      if (isDeleteModal) {
        setIsDeleteModal(false)
        state.tableData &&
          dispatch(
            setTableData(
              state.tableData?.filter(
                table => table._id !== selectedItem?.RequestBody?.Id
              )
            )
          )
      }
      refetch()
    }
  }

  const handleFilterChange = (changedValue: any, filterName: string) => {
    setFilters(filterData => ({ ...filterData, [filterName]: changedValue }))
  }

  const filterValues = (filterVal: any) => {
    setFilteredValues(filterVal)
    refetch()
  }

  const handleReport = async (
    path: string,
    filterVal: any,
    filePrefix: string,
    onClick?: string
  ) => {
    if (onClick === 'Print') {
      window.print()
    } else {
      const headers = {
        'Content-Type': 'blob'
      }
      setIsReportLoading(true)
      try {
        const response = await postData({
          path,
          body: { ...filterVal, SearchQuery: state.search },
          method: 'post',
          headers,
          responseType: 'blob'
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const startDate = filterVal.StartDate
        const endDate = filterVal.EndDate
        const filePostfix =
          startDate && endDate ? `${startDate}-${endDate}` : `${Date.now()}`
        const fileName =
          response.headers['content-disposition']
            ?.split?.('filename=')?.[1]
            ?.replaceAll(`"`, '') || `${filePrefix}-${filePostfix}.xlsx`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      } finally {
        setIsReportLoading(false)
      }
    }
  }

  const handleElementOnchange = async (
    sourceElement: any,
    changedValue: any
  ) => {
    const { Name: sourceName, OnChange } = sourceElement
    const { Source: url, Name: targetElementName } = OnChange
    // To delete existing filter data if any on the target element.
    if (filters[targetElementName]) {
      const filtersData = { ...filters }
      delete filtersData[targetElementName]
      setFilters(filtersData)
    }

    const body = { [sourceName?.toLowerCase()]: changedValue }
    const { data } = await postData({ path: url, body })
    const onChangeOptions = data.map((item: AnyObject) => ({
      label: item.Name,
      value: item._id
    }))
    // Updates the target element.
    const filterElementsData = Object.assign({}, filterElements)
    filterElementsData?.Content.Elements.forEach(
      element =>
        'Elements' in element.Content &&
        element.Content.Elements.forEach(formElement => {
          if (formElement.Name === targetElementName) {
            formElement.Disabled = false
            formElement.Options = onChangeOptions
          }
        })
    )
    setFilterElements({ ...filterElementsData })
  }

  return (
    <Card>
      <div className="d-flex justify-content-between align-items-center">
        {/* // * List Title */}

        <Card.Title className="mt-3">{content.Title}</Card.Title>
        {/* // * Buttons (Normally Create Module/SubModule item) */}
        <div className="d-flex justify-content-end">
          <ActionButton
            buttons={content.Buttons || []}
            handleClick={handleAction}
          />
        </div>
      </div>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-end">
            {content.Reports?.map(
              ({ Label, Path, Class, Key, OnClick, FilePrefix = '' }) => (
                <RippleButton
                  key={Key}
                  className={`${Class} mb-3 me-3`}
                  onClick={() =>
                    handleReport(Path, filters, FilePrefix, OnClick)
                  }
                  isLoading={
                    (OnClick === 'Print' && state.loading) ||
                    (!!Path && isReportLoading)
                  }
                >
                  {Label}
                </RippleButton>
              )
            )}
          </div>
        </Col>
      </Row>
      {/* // * Filter & Search Section */}
      <Form>
        {filterElements && (
          <Row>
            {filterElements.Content?.Elements?.map(element => (
              <Col xs={12} md={element.Size || 12} key={element.Key}>
                {'Elements' in element.Content &&
                  element.Content.Elements.map(formElement => (
                    <FormItem
                      key={formElement.Key || formElement.Name}
                      element={formElement}
                      value={filters[formElement.Name]}
                      options={formElement?.Options}
                      onChange={(value: OnChangeValueType) => {
                        handleFilterChange(value, formElement.Name)
                        if (formElement.OnChange) {
                          handleElementOnchange(formElement, value)
                        }
                      }}
                    />
                  ))}
              </Col>
            ))}
            <Col xs={12}>
              <RippleButton
                className="mb-3 me-3"
                onClick={() => filterValues(filters)}
              >
                Filter
              </RippleButton>
            </Col>
          </Row>
        )}
        <Form.Control
          className="w-25 mb-3"
          value={searchText}
          onChange={handleSearch}
          placeholder={`Search ${content.Title}`}
        />
      </Form>
      {content.THead?.length && (
        <ListContext.Provider value={listContextValue}>
          <Table responsive className="table-hover-animation mb-3 print">
            <thead>
              <TableHead tableHeadList={state.tableHeadList} />
            </thead>
            <tbody>
              <TableBody />
            </tbody>
          </Table>
          {/* // * Render pagination if total count is less than limit */}
          {state.count > PAGE_LIMIT && (
            <div className="d-flex justify-content-end">
              <ListPagination />
            </div>
          )}
        </ListContext.Provider>
      )}
      <Modal show={showModal} onHide={() => removeModalDetails()} centered>
        <FormModal item={selectedItem} hideModal={hideModal} />
      </Modal>
    </Card>
  )
}

export default List
