import { useContext } from 'react'
import { Pagination } from 'react-bootstrap'
import { PAGE_LIMIT } from '../../../../../constants/list'
import { ListContext } from '../../../../../context/ListContext'
import { setCurrentPage } from '../../items/List/store/actions'

// * Pagination Section in tables
const ListPagination = () => {
  const {
    state: { currentPage, count },
    dispatch
  } = useContext(ListContext)
  const totalPages = Math.ceil(count / PAGE_LIMIT)
  // * Basic Pagination Button
  const paginationItem = (pageNumber: number, showInLg = false) => (
    <Pagination.Item
      className={showInLg ? 'd-inline-block' : 'd-none d-md-inline-block'}
      active={currentPage === pageNumber}
      onClick={() => dispatch(setCurrentPage(pageNumber))}
    >
      {pageNumber}
    </Pagination.Item>
  )

  // * Page Number 1 if current page is not 1
  const firstPage = () => {
    if (currentPage - 1 > 1) {
      return paginationItem(1)
    }
    return null
  }

  // * Page Number 2 or ... if current page is greater than 3
  const secondPage = () => {
    if (currentPage === 4) {
      return paginationItem(2)
    } else if (currentPage > 4) {
      return (
        <Pagination.Ellipsis className="d-none d-md-inline-block pagination_cursor_default" />
      )
    }
    return null
  }

  // * Previous Page of current Page if current page is not 1
  const previousPage = () => {
    if (currentPage === 1) {
      return null
    }
    return paginationItem(currentPage - 1)
  }

  // * Current Page
  const thisPage = () => paginationItem(currentPage, true)

  // * Next page if current page is not last page
  const nextPage = () => {
    if (currentPage === totalPages) {
      return null
    }
    return paginationItem(currentPage + 1)
  }

  // * Second last page or ... if next page is not second last page
  const secondLastPage = () => {
    if (currentPage + 3 === totalPages) {
      return paginationItem(totalPages - 1)
    } else if (currentPage + 3 < totalPages) {
      return (
        <Pagination.Ellipsis className="d-none d-md-inline-block pagination_cursor_default" />
      )
    }
    return null
  }

  // * Last page if last page is greater than current page
  const lastPage = () => {
    if (currentPage + 1 < totalPages) {
      return paginationItem(totalPages)
    }
    return null
  }

  return (
    <Pagination className="d-flex">
      {/* // * Go to First Page */}
      <Pagination.First onClick={() => dispatch(setCurrentPage(1))} />
      {/* // * Go to Previous Page */}
      <Pagination.Prev
        onClick={() => dispatch(setCurrentPage(currentPage - 1))}
        disabled={currentPage === 1}
      />
      {/* // * Go to First Page */}
      {firstPage()}
      {/* // * Go to Second Page */}
      {secondPage()}
      {/* // * Go to Previous Page */}
      {previousPage()}
      {/* // * Go to Current Page */}
      {thisPage()}
      {/* // * Go to Next Page */}
      {nextPage()}
      {/* // * Go to Second last Page */}
      {secondLastPage()}
      {/* // * Go to Last Page */}
      {lastPage()}
      {/* // * Go to Next Page */}
      <Pagination.Next
        onClick={() => dispatch(setCurrentPage(currentPage + 1))}
        disabled={currentPage === totalPages}
      />
      {/* // * Go to Last Page */}
      <Pagination.Last onClick={() => dispatch(setCurrentPage(totalPages))} />
    </Pagination>
  )
}

export default ListPagination
