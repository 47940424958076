import styled from 'styled-components'

import Image from '../../Image'

export const LogoImage = styled(Image)`
  height: 2.5rem;
`
export const LogoName = styled.h1`
  margin-left: 1rem;
  margin-bottom: 0;
  color: var(--bs-primary);
  font-size: 1.714rem;
  font-weight: 600;
  line-height: 1.2;
`
