import { AnyObject } from '../../types/common'
import { MAIN_DATA, REFETCH_DATA } from './actionTypes'

export const setMainData = (payload: AnyObject) => ({
  type: MAIN_DATA,
  payload
})
export const setRefetch = (payload: boolean) => {
  return {
    type: REFETCH_DATA,
    payload
  }
}
