import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'

import Form from 'react-bootstrap/Form'
import { ChevronsLeft } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'

import RippleButton from '../../../../components/common/RippleButton'

// import { defaultFallbackPath } from '../../../../../constants/routes'
import { errorToast } from '../../../../../utils/toast'

import { FormText } from '../../../../styles/common'
import { usePostData } from '../../../../../hooks/reactQuery'
import { toast } from 'react-toastify'

const ForgotPsswordForm = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(true)
  const { push: pushHistory } = useHistory()
  const {
    data: resetPasswordResponse,
    error,
    isLoading,
    isError,
    mutate
  } = usePostData()

  useEffect(() => {
    if (resetPasswordResponse) {
      toast.success('Password reset successful', { toastId: 'ResetPassword' })
      pushHistory('/')
    }
  }, [resetPasswordResponse])

  useEffect(() => {
    if (isError) {
      errorToast(error, 'resetPasswordError')
    }
  }, [isError])

  const validatePassword = (value = password) => {
    setIsValidPassword(!!value)
  }

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPassword(target.value)
  }

  const handleConfirmChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(target.value)
  }

  const handlePasswordChange = async (event: SyntheticEvent) => {
    event.preventDefault()
    validatePassword()
    if (isValidPassword) {
      try {
        const body = { new_password: password }
        const path = 'reset_password/'
        mutate({ path, body })
      } catch (error: any) {
        errorToast(error, 'forgot-password')
      }
    }
  }

  return (
    <Form onSubmit={handlePasswordChange}>
      <Form.Group controlId="reset-password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter password"
          autoFocus
          value={password}
          onChange={handleChange}
          onBlur={() => validatePassword(password)}
        />
        <FormText isInvalid={!isValidPassword}>
          Please enter a valid password
        </FormText>
      </Form.Group>
      <Form.Group controlId="reset-password-confirm">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Re-enter password"
          value={confirmPassword}
          onChange={handleConfirmChange}
          onBlur={() => validatePassword(confirmPassword)}
        />
        {/* <FormText isInvalid={}>Passwords not matching!</FormText> */}
      </Form.Group>
      <div className="d-grid my-3">
        <RippleButton
          type="submit"
          variant="primary"
          className="waves-effect mb-3"
          isLoading={isLoading}
          disabled={!!(confirmPassword && password !== confirmPassword)}
        >
          Reset Password
        </RippleButton>
        <p className="text-center mt-2">
          <Link to="/">
            <ChevronsLeft className="mr-25" size={14} />
            <span className="align-middle">Home Page</span>
          </Link>
        </p>
      </div>
    </Form>
  )
}

export default ForgotPsswordForm
