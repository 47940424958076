import { NavbarShadow, Page, StyledSidebar } from './styles'
import { ChildrenProps } from '../../../types/common'
import Navbar from '../../components/layout/Navbar'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const VerticalMenu = ({ children }: ChildrenProps) => {
  const [showSidebar, setShowSidebar] = useState(true)
  const location = useLocation()

  useEffect(() => {
    setShowSidebar(
      !(
        location.pathname.includes('Create') ||
        location.pathname.includes('Edit')
      )
    )
  }, [location])

  return (
    <div className="wrapper">
      {/* // * Sidebar Section */}
      {showSidebar && <StyledSidebar />}
      {/* // * Header Section */}
      <Navbar showSidebar={showSidebar} />
      <NavbarShadow />
      {/* // * Main Page Section */}
      <Page showSidebar={showSidebar}>{children}</Page>
    </div>
  )
}

export default VerticalMenu
