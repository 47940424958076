import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'

import { responsiveCol } from '../../../../../utils/conversions'

import { FormItemProps } from '../../layout/FormItem/types'

const TextArea = ({
  element,
  onChange,
  split = [12, 12],
  value
}: FormItemProps) => {
  const labelProps: LabelProps = {
    text: element.Label,
    split: responsiveCol(split[0]),
    required: element.Required
  }
  return (
    <FormWrapper>
      {element.Label && <Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        <Form.Control
          as="textarea"
          key={element.Key}
          name={element.Name || element.Type}
          placeholder={element.Placeholder || ''}
          value={value}
          disabled={element.Disabled || !!element.Source}
          rows={element.Rows || 3}
          style={{ height: 'auto' }}
          onChange={event => onChange && onChange(event.target.value)}
        />
      </Col>
    </FormWrapper>
  )
}

export default TextArea
