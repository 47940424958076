import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import DynamicItem from '../Item'
import { DynamicLayoutProps } from './types'

// Render layout of page dynamically. Width determined by bootstrap grid sizings come from API
const DynamicLayout = ({ layout }: DynamicLayoutProps) => (
  <>
    {layout?.map(layoutItem => {
      const {
        Size: size,
        Content_Type: contentType,
        Content: content,
        Key: key,
        Style: style
      } = layoutItem
      return (
        <Col key={key} xs={12} md={size}>
          <Row className="mx-1" style={style || {}}>
            <DynamicItem content={content} contentType={contentType} />
          </Row>
        </Col>
      )
    })}
  </>
)

export default DynamicLayout
