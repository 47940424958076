import { createContext } from 'react'
import { AnyObject } from '../types/common'
// import { DynamicReducer } from '../store/dynamic/types'

// *TODO : Add type to dispatch
export interface FormContextType {
  state: AnyObject
  dispatch: any
}

export const FormContext = createContext({} as FormContextType)
