import BootstrapTabs from 'react-bootstrap/Tabs'
import DynamicLayout from '../../layout/Container'
import Tab from 'react-bootstrap/Tab'

import { TabProps } from './types'
// import { WhiteBackground } from './styles'
import { Card } from 'react-bootstrap'

const Tabs = ({ content }: TabProps) => {
  return (
    <Card>
      <BootstrapTabs
        defaultActiveKey={content[0]?.DefaultActiveKey}
        className="mb-3"
      >
        {content.map(tabItem => (
          <Tab key={tabItem.Id} eventKey={tabItem.Id} title={tabItem.Title}>
            <DynamicLayout layout={tabItem.Content} />
          </Tab>
        ))}
      </BootstrapTabs>
    </Card>
  )
}

export default Tabs
