import Button from 'react-bootstrap/Button'
import styled, { keyframes } from 'styled-components'
import { RippleEffectProps } from './types'

export const WaveButton = styled(Button)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

const rippleEffect = keyframes`
  0% { opacity: 0; }
  25% { opacity: 1; }
  100% { width: 200%; padding-bottom: 200%; opacity: 0; }
`

export const RippleWave = styled.span<RippleEffectProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  animation: ${rippleEffect} 0.7s ease;
  left: ${({ coords: { x } }) => x};
  right: ${({ coords: { y } }) => y};
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
`
