import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import { useParams } from 'react-router-dom'

// import DynamicLayout from '../../layout/Container'
// import { PageCenter } from '../../../common/PageCenter'
// import PageLoading from '../../../common/PageLoading'
import CardTitle from '../../../common/Title/CardTitle'

// import AccessContext from '../../../../../context/AccessContext'
// import { useGetData } from '../../../../../hooks/reactQuery'
// import { errorMessageFromError } from '../../../../../utils/dynamicData'

import { AccessProps } from './types'
import { useSelector } from 'react-redux'
import { selectMainData } from '../../../../../store/viewDetails/selector'
import Button from '../Button'
import DynamicLayout from '../../layout/Container'
// import { AnyObject } from '../../../../../types/common'
// import { IdParams } from '../../../../../types/params'

const AccessCard = ({ content }: AccessProps) => {
  const mainData = useSelector(selectMainData)
  if (
    !('Access' in content) ||
    (content.Access && mainData?.data?.[content.Access]?.includes('rwea'))
  ) {
    return (
      <Card>
        <Container fluid className="p-0">
          <Row>
            <Col xs={12} className="mt-3 ps-4">
              <CardTitle title={content?.Title || ''} />
            </Col>
            {/* <AccessContext.Provider value={data?.Access || ''}> */}
            {content?.LayoutContent && (
              <DynamicLayout layout={content.LayoutContent} />
            )}
            {/* </AccessContext.Provider> */}
            {content.Buttons?.map((button: any) => (
              <Button key={button.Key} element={button} className="me-3 mb-3" />
            ))}
          </Row>
        </Container>
      </Card>
    )
  }
  // const { id } = useParams<IdParams>()

  // const path = `Components/${content.Type}/${content.Name}/`
  // const uniqueKey = `${path}-${id}`
  // const body = { Id: id }
  // const {
  //   data: response,
  //   error,
  //   isLoading
  // } = useGetData(uniqueKey, { path, body }, true, false, 300000)

  // if (isLoading) {
  //   return (
  //     <Card>
  //       <PageLoading height="17vh" />
  //     </Card>
  //   )
  // }

  // if (error) {
  //   const errorMessage = errorMessageFromError(error as AnyObject)
  //   return (
  //     <Card>
  //       <PageCenter height="18vh">{errorMessage}</PageCenter>
  //     </Card>
  //   )
  // }

  // if (response) {
  //   const data = response.data
  return null
}

//   return null
// }

export default AccessCard
