// src/views/components/dynamic/components/FormModal/index.tsx
import { useCallback, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import PageLoading from '../../../common/PageLoading'
import RippleButton from '../../../common/RippleButton'

import { useGetData, usePostData } from '../../../../../hooks/reactQuery'
import { errorToast } from '../../../../../utils/toast'

import { FormModalProps } from './types'
import { useParams } from 'react-router-dom'
import { IdParams } from '../../../../../types/params'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAccess,
  setFormData,
  setUrls
} from '../../../../../store/dynamic/actions'
import DynamicLayout from '../../layout/Container'
import { selectFormModalSuccess } from '../../../../../store/formModal/selector'

const FormModal = ({ item, hideModal }: FormModalProps) => {
  const deleteSuccess = useCallback(() => {
    hideModal(true)
  }, [])
  const { isLoading, error, mutate } = usePostData(deleteSuccess)
  const { id } = useParams<IdParams>()
  const saveSuccess = useSelector(selectFormModalSuccess)
  const dispatch = useDispatch()
  const url = item?.Layout || ''
  const uniqueKey = `${url}-${id || ''}`
  const body = item?.RequestBody || {}
  const enabled = item?.Modal === 'create' || item?.Modal === 'edit'
  const {
    data: response,
    isLoading: layoutLoading,
    error: layoutError
  } = useGetData(uniqueKey, { path: url, body }, enabled, false, 300000)

  useEffect(() => {
    if (item?.Modal === 'create' || item?.Modal === 'edit') {
      dispatch(
        setUrls({
          path: item?.Path || '',
          source: item?.Source || '',
          requestBody: item?.RequestBody
        })
      )
    }
  }, [])

  useEffect(() => {
    const errorObject = error || layoutError
    errorObject &&
      errorToast(
        errorObject,
        `error-${item?.Path}${item?.RequestBody}-${item?.Modal}`
      )
  }, [error, layoutError])

  useEffect(() => {
    saveSuccess && hideModal(true)
    dispatch(setFormData({}))
  }, [saveSuccess])

  useEffect(() => {
    if (response?.data?.Access) {
      dispatch(setAccess(response.data.Access))
    }
  }, [response])

  const handleDelete = () => {
    const path = `/${item?.Path}` || ''
    const deleteBody = item?.RequestBody || {}
    mutate({ path, body: deleteBody })
  }

  if (layoutLoading) {
    return (
      <Modal.Body>
        <PageLoading height="30vh" />
      </Modal.Body>
    )
  }

  if (response) {
    const layoutData = response.data
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{layoutData?.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* // * Render Layout */}
          <DynamicLayout layout={layoutData?.Layout} />
        </Modal.Body>
      </>
    )
  }
  // * Delete Modal Body
  if (item?.Button === 'Delete') {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <PageLoading height="15vh" />
          ) : (
            <>
              <p>Do you really want to delete this?</p>
              <div className="d-flex justify-content-end">
                <RippleButton
                  variant="danger"
                  className="me-3"
                  onClick={handleDelete}
                >
                  Delete
                </RippleButton>
                <RippleButton variant="success" onClick={() => hideModal()}>
                  Cancel
                </RippleButton>
              </div>
            </>
          )}
        </Modal.Body>
      </>
    )
  }
  return null
}

export default FormModal
