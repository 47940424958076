import styled from 'styled-components'

export const NavbarUserDropdown = styled.li`
  position: relative;
  line-height: 1;
`

export const NavbarUserLink = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: var(--body-color);
  &:hover {
    color: var(--body-color-dark);
  }
`
export const NavbarUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.8rem;
`
export const Username = styled.span`
  display: inline-block;
  font-weight: 500;
  margin-left: 0.2rem;
  margin-bottom: 0.435rem;
`
export const Avatar = styled.div`
  white-space: nowrap;
  position: relative;
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  vertical-align: middle;
  font-weight: 600;
  background-color: #c3c3c3;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
`
export const AvatarImage = styled.img`
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12),
    0 2px 4px 0 rgba(34, 41, 47, 0.08);
  border-radius: 50%;
`
