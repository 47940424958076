import Card from 'react-bootstrap/Card'

import PageLoading from '../../../common/PageLoading'

import { FormElementItem, InvoiceProps } from './types'
import { Col, Form, Row } from 'react-bootstrap'
import FormItem from '../FormItem'
import { OnChangeValueType } from '../../../../../types/formElement'
import RippleButton from '../../../common/RippleButton'
import useForm from '../../../../../hooks/useForm'
import { useEffect, useRef, useState } from 'react'
import {
  resetForm,
  setFormDatum,
  setFormElements
} from '../../../../../store/dynamic/actions'
import { AnyObject } from '../../../../../types/common'
import { useHistory } from 'react-router-dom'
import { FormContext } from '../../../../../context/FormContext'
import HeaderForm from '../../items/HeaderForm'
import { HeaderFormItem } from '../../items/HeaderForm/types'

const Invoice = ({ content }: InvoiceProps) => {
  const ref = useRef()
  const {
    Button: button,
    Elements: elements,
    Path: path,
    Source: source,
    OnSuccess: redirectPath,
    SourceResponseRemove: sourceResponseRemove,
    SourceResponseFill: sourceResponseFill,
    PathRequestDefault: pathRequestDefualt,
    SourceResponseChange: sourceResponseChange
  } = content

  const {
    buttonLoading,
    error,
    isLoading,
    handleOnChange,
    handleSubmit,
    form,
    dispatch,
    mutatedData
  } = useForm(
    path,
    source,
    undefined,
    undefined,
    sourceResponseRemove,
    sourceResponseFill,
    pathRequestDefualt,
    sourceResponseChange
  )

  const [previousForm, setPreviousForm] = useState<AnyObject>(
    JSON.parse(JSON.stringify(form))
  )

  const { push: pushHistory } = useHistory()
  useEffect(() => {
    const formElements: AnyObject = {}
    elements.forEach(elementLayout => {
      if (elementLayout.Content_Type === 'form-elements') {
        elementLayout.Content.Elements?.forEach(element => {
          if (element.Type === 'multiple') {
            formElements[element.Name] = {
              ...element,
              MultipleRows: [{ elements: element.Elements, index: 0 }]
            }
            const formData = { ...form.data }
            const defaultElementData: AnyObject = {}
            element?.Elements?.forEach((multipleElem: any) => {
              if (multipleElem.Type === 'parameters') {
                multipleElem.Elements.forEach((parameterEl: AnyObject) => {
                  defaultElementData[multipleElem.Name] = [
                    { [parameterEl.Name]: parameterEl.Default || '' }
                  ]
                })
              } else if (Array.isArray(multipleElem.Elements)) {
                // multipleElem.Elements.forEach((parameterEl: AnyObject) => {
                //   defaultElementData[multipleElem.Name] = [
                //     { [parameterEl.Name]: parameterEl.Default || '', }
                //   ]
                // })
              } else if (multipleElem.Type !== 'measurements') {
                defaultElementData[multipleElem.Name] =
                  form?.data?.['multipleForm-ignore']?.[0]?.[
                    multipleElem.Name
                  ] ||
                  multipleElem.Default ||
                  ''
              }
            })
            formData[element.Name] = [defaultElementData]
            dispatch(setFormDatum(formData))
          } else {
            formElements[element.Name] = element
          }
        })
      } else if (elementLayout.Content_Type === 'header-form') {
        elementLayout.Content?.forEach(headerContent => {
          if (headerContent.Content_Type === 'small-form-elements') {
            headerContent.Content?.Elements?.forEach(element => {
              if (element.Type === 'multiple') {
                formElements[element.Name] = { ...element, MultipleRows: [] }
              } else {
                formElements[element.Name] = element
              }
            })
          }
        })
      }
    })
    dispatch(setFormElements(formElements))
  }, [])

  useEffect(() => {
    if (
      form &&
      Object.keys(form)?.length &&
      JSON.stringify(form) !== JSON.stringify(previousForm)
    ) {
      ref.current = JSON.parse(JSON.stringify(form))
      setPreviousForm(JSON.parse(JSON.stringify(form)))
    }
  }, [form])

  useEffect(() => {
    if (form.isSuccess && mutatedData) {
      dispatch(resetForm())
      const responseId = mutatedData.data?.URL?.split('requested=')?.[1]
      const newPath = `${redirectPath}/${responseId}`
      ;(newPath || redirectPath) && pushHistory(newPath || redirectPath || '')
    }
  }, [form.isSuccess, mutatedData])

  const getValue = (element: AnyObject) => {
    if (element.BaseValues && element.Operators) {
      return element.BaseValues?.reduce(
        (acc: number, value: string, index: number) => {
          const formData = { ...form.data }
          let formValue = formData[value] || 0
          if (value === 'this') {
            formValue = elements.reduce((taxAcc: number, taxEl: AnyObject) => {
              if (taxEl?.Name && formData?.[taxEl.Name]) {
                taxAcc += +(formData[taxEl.Name] || 0)
              }
              return taxAcc
            }, 0)
          }
          if (element.Operators?.[index] === 'add') {
            acc += +formValue
          } else if (element.Operators?.[index] === 'subtract') {
            acc -= +formValue
          }
          return acc
        },
        0
      )
    }
    return ''
  }

  const renderElements = (elementLayout: FormElementItem | HeaderFormItem) => {
    if (elementLayout.Content_Type === 'form-elements') {
      return (
        <>
          {elementLayout.Content.Elements?.map(element => {
            return (
              <FormItem
                element={{
                  ...(form.elements[element.Name] || element),
                  data: form.data
                }}
                key={element.Key || element.Name}
                value={
                  form.data?.[element.Name] ||
                  getValue(element) ||
                  element.Default
                }
                baseValue={
                  element.BaseValue
                    ? +(form.data[element.BaseValue] || 0)
                    : getValue(element) || 0
                }
                rate={form.data?.[`${element.Name}Percentage`]}
                onChange={(value: OnChangeValueType, data: any) => {
                  handleOnChange(value, element, data, ref.current)
                }}
                defaultPercent={element.Default}
              />
            )
          })}
        </>
      )
    }

    if (elementLayout.Content_Type === 'header-form') {
      return (
        <HeaderForm
          content={elementLayout.Content}
          form={form}
          handleOnChange={handleOnChange}
        />
      )
    }
    return null
  }

  if (isLoading) {
    return (
      <Card>
        <PageLoading height="50vh" />
      </Card>
    )
  }

  if (error) {
    return null
  }

  return (
    <Card>
      <FormContext.Provider value={{ state: form, dispatch }}>
        <Form onSubmit={handleSubmit}>
          <Row className="my-3">
            {elements.map(elementLayout => (
              <Col
                xs={12}
                md={elementLayout.Size || 12}
                key={elementLayout.Key}
              >
                {renderElements(elementLayout)}
              </Col>
            ))}
          </Row>
          {button && (
            <RippleButton
              className="my-3 position-relative"
              type="submit"
              isLoading={buttonLoading}
            >
              {button}
            </RippleButton>
          )}
        </Form>
      </FormContext.Provider>
    </Card>
  )
}

export default Invoice
