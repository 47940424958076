import { AccessType } from '../../types/button'
import { AnyObject } from '../../types/common'
import {
  BUTTON_LOADING,
  CLEAR_ROW_ITEM_DATA,
  FORM,
  MAIN_DATA,
  RESET_FORM,
  SET_ACCESS,
  SET_DATA,
  SET_DATA_ITEM,
  SET_DATUM,
  SET_ELEMENTS,
  SET_ELEMENT_ITEM,
  SET_ELEMENT_ITEM_DATA,
  SET_FILL_ROWS_ITEM_DATA,
  SET_FILL_ROW_ITEM_DATA,
  SET_PARAMETER_ELEMENT,
  SET_ROW_ITEM_DATA,
  SET_ROW_ITEM_ELEMENT,
  SET_SUCCESS,
  SET_URLS
} from './actionTypes'

export const setMainData = (payload: AnyObject) => ({
  type: MAIN_DATA,
  payload
})

export const setForm = (payload: AnyObject) => ({
  type: FORM,
  payload
})

export const setAccess = (payload: AccessType) => ({
  type: SET_ACCESS,
  payload
})

export const setFormData = (payload: AnyObject) => ({
  type: SET_DATA,
  payload
})
export const setFormDataItem = (payload: AnyObject) => ({
  type: SET_DATA_ITEM,
  payload
})

export const setFormDatum = (payload: AnyObject) => ({
  type: SET_DATUM,
  payload
})

export const setFormElements = (payload: AnyObject) => ({
  type: SET_ELEMENTS,
  payload
})

export const setFormElementItem = (payload: AnyObject) => ({
  type: SET_ELEMENT_ITEM,
  payload
})

export const setFormElementItemData = (payload: AnyObject) => ({
  type: SET_ELEMENT_ITEM_DATA,
  payload
})
export const setRowItemElement = (payload: AnyObject) => ({
  type: SET_ROW_ITEM_ELEMENT,
  payload
})

export const setParameterElement = (payload: AnyObject) => ({
  type: SET_PARAMETER_ELEMENT,
  payload
})

export const setFillRowItemData = (payload: AnyObject) => ({
  type: SET_FILL_ROW_ITEM_DATA,
  payload
})
export const setFillRowsItemData = (payload: AnyObject) => ({
  type: SET_FILL_ROWS_ITEM_DATA,
  payload
})
export const clearRowItemData = (payload: AnyObject) => ({
  type: CLEAR_ROW_ITEM_DATA,
  payload
})

export const setRowItemData = (payload: AnyObject) => ({
  type: SET_ROW_ITEM_DATA,
  payload
})

export const setUrls = (payload: AnyObject) => ({
  type: SET_URLS,
  payload
})

export const setSuccess = (payload: boolean) => ({
  type: SET_SUCCESS,
  payload
})

export const setButtonLoading = (payload: boolean) => ({
  type: BUTTON_LOADING,
  payload
})
export const resetForm = () => ({ type: RESET_FORM, payload: null })
