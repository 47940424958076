import Table from 'react-bootstrap/Table'
import { useParams } from 'react-router-dom'

import PageLoading from '../../../common/PageLoading'
import DOMPurifier from '../../components/DOMPurifier'

import { useGetData } from '../../../../../hooks/reactQuery'
import { createRequestBody } from '../../../../../utils/dynamicData'
import { errorToast } from '../../../../../utils/toast'

import { InvoiceRowElementContentProps, InvoiceRowElementData } from './types'
import { AnyObject } from '../../../../../types/common'
import { IdParams } from '../../../../../types/params'
import { numberFormatter } from '../../../../../utils/format'
import { useEffect, useState } from 'react'
import { APIParams } from '../../../../../types/api'
import { useDispatch } from 'react-redux'
import { setPrintStart, setRowTitles } from '../../../../../store/print/actions'
import moment from 'moment'

const InvoiceRow = ({ content }: InvoiceRowElementContentProps) => {
  const { id } = useParams<IdParams>()
  const path = content.Source
  const uniqueKey = `${path}-${id}`
  const headerParams = {
    path: '',
    body: {}
  }
  const [headerRequestDetails, setHeaderRequestDetails] =
    useState<APIParams>(headerParams)
  const body = createRequestBody({ id }, content.RequestBody)
  const [currentElementIndex, setCurrentElementIndex] = useState<number>(0)
  const [fetchedElementIndex, setFetchedElementIndex] = useState<number[]>([])
  const [rowData, setRowData] = useState<AnyObject>([])
  const [rowElements, setRowElements] = useState<InvoiceRowElementData[]>(
    content.Elements
  )
  const {
    data: response,
    error,
    isLoading
  } = useGetData(uniqueKey, { path, body })
  const {
    data: headerResponse,
    error: headerError,
    isLoading: headerLoading,
    refetch: refetchHeaderDetails
  } = useGetData(
    `${uniqueKey}-${fetchedElementIndex}`,
    headerRequestDetails,
    false
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const hasSource = rowElements.some((element: AnyObject) => !!element.Source)
    dispatch(setPrintStart(!hasSource))
    return () => {
      dispatch(setPrintStart(!hasSource))
    }
  }, [])

  useEffect(() => {
    response?.data && setRowData(response.data)
  }, [response])

  useEffect(() => {
    headerRequestDetails.path &&
      fetchedElementIndex.length &&
      refetchHeaderDetails()
  }, [headerRequestDetails])

  useEffect(() => {
    if (headerResponse?.data) {
      const elements = [...rowElements]
      elements.splice(currentElementIndex, 1, ...(headerResponse.data || []))
      const indices: number[] = []
      if (
        elements.filter((element: AnyObject, index: number) => {
          if (element.Key === 'Particular') {
            indices.push(index)
            return true
          }
          return false
        })?.length > 1
      ) {
        indices.pop()
        indices.forEach(index => {
          elements.splice(index, 1)
        })
      }
      setRowElements(elements)
      dispatch(setRowTitles([...headerResponse?.data]))
      dispatch(setPrintStart(true))
    }
  }, [headerResponse])

  if (isLoading || headerLoading) {
    return <PageLoading height="10vh" />
  }

  if (error || headerError) {
    errorToast(error, `invoice-row-${id}`)
    return null
  }

  if (rowData.length) {
    return (
      <Table responsive className="table-hover-animation mb-3">
        <thead>
          <tr>
            {/* // * Create table head columns */}
            {rowElements.map((element, elementIndex) => {
              if (
                element.Source &&
                !fetchedElementIndex.includes(elementIndex)
              ) {
                setCurrentElementIndex(elementIndex)
                setFetchedElementIndex([...fetchedElementIndex, elementIndex])
                const headerReq = {
                  path: element.Source,
                  body: element.SourceRequestBody
                    ? Object.fromEntries(
                        element.SourceRequestBody.map(([key, value]) => [
                          key,
                          rowData[0][value]
                        ])
                      )
                    : {}
                }
                setHeaderRequestDetails(headerReq)
              }
              return (
                <th
                  key={element.Key}
                  style={element.Style || {}}
                  className={element.Class}
                >
                  {element.Label}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {/* // * Create table body rows according to response data */}
          {rowData?.map((row: AnyObject) => (
            <tr key={row.No}>
              {/* // * Create table body columns according to response data and table head data */}
              {rowElements.map(element => (
                <td
                  key={element.Key}
                  style={element.Style || {}}
                  className={element.Class}
                >
                  {/* // * DOMPurifier is used because data can be HTML element */}
                  <DOMPurifier
                    html={
                      element.IsNumber && row[element.Key]
                        ? numberFormatter(
                            row[element.Key],
                            element.FractionDigits
                          )
                        : element.Key.toLowerCase().includes('date') &&
                          moment(row[element.Key], 'YYYY-MM-DD').isValid()
                        ? moment(row[element.Key], 'YYYY-MM-DD').format(
                            'DD-MM-YYYY'
                          )
                        : row[element.Key]
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
  return <>Rows not found</>
}

export default InvoiceRow
