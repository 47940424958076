import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

export const CardTitle = styled(Card.Title)`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`

export const CardText = styled(Card.Text)`
  margin-bottom: 1.5rem;
`

// * Since isInvalid is not a prop of Form.Text, it is destructured and used seperately
export const FormText = styled(({ isInvalid, ...rest }) => (
  <Form.Text {...rest} />
))`
  color: var(--bs-danger);
  opacity: ${({ isInvalid }) => (isInvalid ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`

export const ForgotPasswordWrapper = styled(Form.Text)`
  display: flex;
  justify-content: flex-end;
`
export const FormFooter = styled.footer`
  position: absolute;
  bottom: 1rem;
  right: 3rem;
`
