import { ImageProps } from './types'

const Image = ({ imageSources, src, alt, ...rest }: ImageProps) => (
  <picture>
    {imageSources?.map(({ media, srcSet }) => (
      <source key={media} media={media} srcSet={srcSet} />
    ))}
    <img src={src} alt={alt} {...rest} />
  </picture>
)

export default Image
