import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import {
  Avatar,
  AvatarImage,
  NavbarUserDetails,
  NavbarUserDropdown,
  NavbarUserLink,
  Username
} from './styles'
import AvatarBlank from '../../../../../assets/images/avatar/blank.png'
import { getJSONDataFromWebStorage } from '../../../../../utils/storage'
import { DropdownToggleRefComponentProps } from './types'

const Toggle = (
  props: DropdownToggleRefComponentProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { children, onClick } = props
  const [headerData, setHeaderData] = useState<any>({})

  useEffect(() => {
    // Get Header Data from Web Storage on mount
    const sessionData = getJSONDataFromWebStorage('headerData')
    setHeaderData(sessionData)
  }, [])

  return (
    <NavbarUserDropdown>
      <NavbarUserLink ref={ref} onClick={onClick}>
        {/* // * User Details in Header */}
        <NavbarUserDetails className="d-none d-sm-flex">
          <Username>{headerData?.userName || 'User'}</Username>
          <small>{headerData?.subscription || 'Trial'}</small>
        </NavbarUserDetails>

        {/* // * User Image */}
        <Avatar>
          <AvatarImage
            src={AvatarBlank}
            alt="profile Image"
            width="40"
            height="40"
          />
        </Avatar>
        {/* // * Dropdown Box with items */}
        {children}
      </NavbarUserLink>
    </NavbarUserDropdown>
  )
}

export default forwardRef(Toggle)
