import classNames from 'classnames'
import { TitleProps } from './types'

// * Component to render Titles in Pages
const Title = ({ children, className }: TitleProps) => {
  const titleClass = classNames('page__title mb-3', className)
  return (
    <>
      <h2 className={titleClass}>{children}</h2>
    </>
  )
}

export default Title
