import { memo } from 'react'
import { TableHeadListProps } from './types'

// * Render Table Heads
const TableHead = ({ tableHeadList }: TableHeadListProps) => (
  <tr>
    {tableHeadList.map(tableHead => (
      <th key={tableHead}>{tableHead}</th>
    ))}
  </tr>
)

export default memo(TableHead)
