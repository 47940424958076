import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Label from '../../components/FormLabel'
import { LabelProps } from '../../components/FormLabel/types'
import FormWrapper from '../../components/FormWrapper'

import { responsiveCol } from '../../../../../utils/conversions'

import { FormItemProps } from '../../layout/FormItem/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FormContext } from '../../../../../context/FormContext'
import { AnyObject } from '../../../../../types/common'
import { postData } from '../../../../../api'
import {
  FormElement,
  OnChangeValueType
} from '../../../../../types/formElement'
import { errorToast } from '../../../../../utils/toast'
import FormItem from '../../layout/FormItem'
import { setFormDataItem } from '../../../../../store/dynamic/actions'

const Tax = ({ element, onChange, split = [12, 12], value }: FormItemProps) => {
  const { state, dispatch } = useContext(FormContext)
  const [elements, setElements] = useState<FormElement[]>([])

  const getTaxData = useCallback(async () => {
    const requestBody = element.SourceRequestBody
    const body: AnyObject = {}
    requestBody?.forEach((item: string[]) => {
      body[item[0]] = state.data[item[1]]
    })
    try {
      const response = await postData({ path: element.Source || '', body })
      setElements(response.data.reverse())
    } catch (err) {
      errorToast(err, `tax-error-${Object.values(body).join('')}`)
    }
  }, [state.data.Id])

  useEffect(() => {
    if (element.Source && state.data.Id) {
      getTaxData()
    }
  }, [state.data.Id])

  const calculateBaseValue = useCallback(
    (baseValues: string[], operators: string[]) =>
      baseValues?.reduce((acc: number, value: string, index: number) => {
        const formData = { ...state.data }
        let formValue = formData[value] || 0
        if (value === 'this') {
          formValue = elements.reduce((taxAcc: number, taxEl: AnyObject) => {
            if (taxEl?.Name && formData?.[taxEl.Name]) {
              taxAcc += +(formData[taxEl.Name] || 0)
            }
            return taxAcc
          }, 0)
        }
        if (operators?.[index] === 'add') {
          acc += +formValue
        } else if (operators?.[index] === 'subtract') {
          acc -= +formValue
        }
        return acc
      }, 0),
    [state.data, elements]
  )

  useEffect(() => {
    if (element.OnChange?.Calculate) {
      element.OnChange.Calculate.forEach((item: AnyObject) => {
        const { BaseValues, Operators, Fill, RoundAndFill } = item
        if (BaseValues?.length && Operators?.length) {
          const fillBaseValue = calculateBaseValue(BaseValues, Operators)
          const decimal = fillBaseValue % 1
          if (decimal && decimal > 0.5) {
            RoundAndFill &&
              dispatch(
                setFormDataItem({
                  name: RoundAndFill,
                  data: (1 - decimal).toFixed(2)
                })
              )
            Fill &&
              dispatch(
                setFormDataItem({
                  name: Fill,
                  data: (+parseInt(String(fillBaseValue + 1))).toFixed(2)
                })
              )
          } else {
            RoundAndFill &&
              dispatch(
                setFormDataItem({
                  name: RoundAndFill,
                  data: (0 - decimal).toFixed(2)
                })
              )
            Fill &&
              dispatch(
                setFormDataItem({
                  name: Fill,
                  data: (+parseInt(String(fillBaseValue))).toFixed(2)
                })
              )
          }
        }
      })
    }
  }, [
    state.data.SGST,
    state.data.CGST,
    state.data.IGST,
    state.data.RoundOff,
    elements,
    state.data?.Total,
    state.data?.Discount
  ])

  const handleTaxChange = (
    value: OnChangeValueType,
    taxElement: FormElement,
    data: any
  ) => {
    if (data?.type === 'percentage') {
      dispatch(
        setFormDataItem({
          name: `${taxElement.Name}Percentage`,
          data: String(data.percentage)
        })
      )
    }
    let otherTaxElement = ''
    if (taxElement.Name === 'CGST') {
      otherTaxElement = 'SGST'
    } else if (taxElement.Name === 'SGST') {
      otherTaxElement = 'CGST'
    }

    onChange &&
      onChange(
        String(
          +value +
            (state.data[otherTaxElement] ? +state.data[otherTaxElement] : 0)
        )
      )
    dispatch(setFormDataItem({ name: taxElement.Name, data: value }))
  }

  const labelProps: LabelProps = {
    text: element.Label || 'Tax',
    split: responsiveCol(split[0]),
    required: element.Required
  }

  return (
    <FormWrapper>
      {<Label {...labelProps} />}
      <Col xs={12} md={responsiveCol(split[1])}>
        {elements.length ? (
          elements.map(taxElement => (
            <FormItem
              key={taxElement.Name}
              element={{
                ...taxElement,
                Type: 'percentage',
                Disabled: 'enabled' in taxElement ? !taxElement.enabled : true
              }}
              perValue={
                state.data[`${taxElement.Name}Percentage`] || taxElement.Rate
              }
              baseValue={calculateBaseValue(
                element.BaseValues || [],
                element.Operators || []
              )}
              onChange={(value: OnChangeValueType, data: any) =>
                handleTaxChange(value, taxElement, data)
              }
            />
          ))
        ) : (
          <Form.Control
            key={element.Key}
            name={element.Name || element.Type}
            placeholder={element.Placeholder || ''}
            value={value || ''}
            disabled={element.Disabled || !!element.Source}
            onChange={event => onChange && onChange(event.target.value)}
          />
        )}
      </Col>
    </FormWrapper>
  )
}

export default Tax
