import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext } from '../../../../../context/FormContext'
import useForm from '../../../../../hooks/useForm'
import useQuery from '../../../../../hooks/useQuery'
import {
  setFormDatum,
  setFormElements
} from '../../../../../store/dynamic/actions'
import { AnyObject } from '../../../../../types/common'
import { OnChangeValueType } from '../../../../../types/formElement'
import { IdParams } from '../../../../../types/params'
import RippleButton from '../../../common/RippleButton'
import FormItem from '../../layout/FormItem'
import { FormProps } from './types'

const BasicForm = ({ content }: FormProps) => {
  const {
    Path: path,
    Source: source,
    RequestBodyMap: requestBodyMap,
    SourceResponseRemove: sourceResponseRemove,
    SourceResponseFill: sourceResponseFill,
    PathRequestDefualt: pathRequestDefualt,
    SourceResponseChange: sourceResponseChange,
    BodyFill: bodyFill
  } = content
  let sourceRequestBody: AnyObject = {}
  const params = useParams<IdParams>()
  const query = useQuery()

  const { handleOnChange, handleSubmit, form, dispatch } = useForm(
    path,
    source,
    sourceRequestBody,
    requestBodyMap,
    sourceResponseRemove,
    sourceResponseFill,
    pathRequestDefualt,
    sourceResponseChange,
    bodyFill
  )

  if (Array.isArray(content.SourceRequestBody)) {
    content.SourceRequestBody.forEach(([key, value]: [string, string]) => {
      if (value === 'id') {
        const id = params.id || query.get('id')
        if (id) {
          sourceRequestBody[key] = id
        }
      } else {
        sourceRequestBody[key] = value
      }
    })
  } else {
    sourceRequestBody = content.SourceRequestBody || {}
  }

  useEffect(() => {
    const formElements: AnyObject = {}
    content.Elements.forEach(element => {
      if (element.Type === 'multiple') {
        formElements[element.Name] = {
          ...element,
          MultipleRows: [{ elements: element.Elements, index: 1 }]
        }
        const formData = { ...form.data }
        const defaultElementData: AnyObject = {}
        element?.Elements?.forEach((multipleElem: any) => {
          defaultElementData[multipleElem.Name] = multipleElem.Default
        })
        formData[element.Name] = [defaultElementData]
        dispatch(setFormDatum(formData))
      } else {
        formElements[element.Name] = element
      }
    })
    dispatch(setFormElements(formElements))
  }, [])

  return (
    <FormContext.Provider value={{ state: form, dispatch }}>
      {content.Elements.map(formElement => (
        <FormItem
          key={formElement.Key || formElement.Name}
          element={form.elements[formElement.Name] || formElement}
          split={content.Split}
          value={form.data[formElement.Name]}
          options={form.elements[formElement.Name]?.options || null}
          onChange={(value: OnChangeValueType, data: any) =>
            handleOnChange(value, formElement, data, form.data)
          }
        />
      ))}
      {content.Button && (
        <div className="d-flex justify-content-end">
          <RippleButton
            onClick={handleSubmit}
            isLoading={form.buttonLoading}
            className="mb-3"
          >
            {content.Button}
          </RippleButton>
        </div>
      )}
    </FormContext.Provider>
  )
}

export default BasicForm
