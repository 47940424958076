import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'

import { AnyObject } from '../../../../../types/common'
import PageLoading from '../../../common/PageLoading'

import { useGetData } from '../../../../../hooks/reactQuery'
import { createRequestBody } from '../../../../../utils/dynamicData'
import { errorToast } from '../../../../../utils/toast'

import { SubTotalProps, SubTotalResponse } from './types'
import { IdParams } from '../../../../../types/params'
import { numberFormatter } from '../../../../../utils/format'

const SubTotal = ({ source, requestBody }: SubTotalProps) => {
  const { id } = useParams<IdParams>()
  const path = source
  const uniqueKey = `${source}-${id}`
  const body: AnyObject = createRequestBody({ id }, requestBody)
  const {
    data: response,
    error,
    isLoading
  } = useGetData(uniqueKey, { path, body })

  if (isLoading) {
    return <PageLoading height="15vh" loaderProps={{ size: 'sm' }} />
  }

  if (error) {
    errorToast(error, `subtotal-${id}`)
    return null
  }

  if (response) {
    const data: SubTotalResponse[] = response.data
    return (
      <div className="mb-5 me-3">
        {/* // * Show each Subtotal in rows */}
        {data.map(item => (
          <Row key={item.Name}>
            <Col xs={8}>{item.Name}:</Col>
            <Col xs={4}>
              <div className="d-flex justify-content-end">
                {item.Amount ? numberFormatter(item.Amount) : item.Amount}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    )
  }

  return null
}

export default SubTotal
