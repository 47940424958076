import { setRoutePaths } from '../../Router/routes'
import { getJSONDataFromWebStorage } from '../../utils/storage'
import { APP_LOADING, ROUTES } from './actionTypes'
import { GlobalAction } from './types'

const storageSidebarData = getJSONDataFromWebStorage('sidebarData')

const storageSidebarMainLength = storageSidebarData?.Main?.length

// * Data from storage if present in web Storage, else default data as initial data
const initialRoutes = storageSidebarMainLength
  ? setRoutePaths(storageSidebarData)
  : setRoutePaths()

const initialState = {
  routes: initialRoutes,
  loading: false
}

export const globalReducer = (
  state = initialState,
  { type, payload } = {} as GlobalAction
) => {
  switch (type) {
    case ROUTES:
      return { ...state, routes: payload }
    case APP_LOADING:
      return { ...state, loading: payload }
    default:
      return state
  }
}
