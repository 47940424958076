import { StrictMode } from 'react'

import ReactDOM from 'react-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query/devtools'

import App from './App'

import store from './store'

import reportWebVitals from './reportWebVitals'

import './styles/scss/main.scss'

const queryClient = new QueryClient()

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
        <ToastContainer
          position="top-left"
          autoClose={3000}
          newestOnTop
          closeOnClick
          pauseOnHover
        />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
